import apiAxios from '@/lib/axios';

export const downloadCsv = async (path: string, params: any) => {
    const response = await apiAxios.get(path, {
      params,
      responseType: 'blob',
    });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  let fileName = response.headers['content-disposition'].split('filename=')[1];
  if (fileName === undefined) {
    fileName = 'downloaded.csv';
  }
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}


export const downloadCsvFromData = async (headers: string[], data: string[][], fileName: string) => {
  const csvContent = [headers.join(','), ...data.map((row) => row.join(','))].join('\r\n');
  const blob = new Blob(['\uFEFF' + csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
