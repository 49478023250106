import axios from 'axios';
import { FileUpload } from './file.type';
import apiAxios from '@/lib/axios';
import { FileAnalysisStatus, FileType, MB, MimeTypeToName, UploadableAudioMaxMb, UploadableAudioMimeTypes, UploadableDocumentMaxMb, UploadableDocumentMimeTypes, UploadableImageMaxMb, UploadableImageMimeTypes, UploadableTxtMaxMb, UploadableTxtMimeTypes, UploadableVideoMimeTypes } from './file.constant';
import { LoginUserMembership } from '../auth/auth.type';
import { AIModelConst, DEFAULT_AI_MODEL_ID } from '../aiModel/aiModel.constant';
import { getAIModelConsts } from '../aiModel/aiModel.utils';

export const upload = async (file: File, onProgress: (progress: number) => void): Promise<FileUpload> => {
  if (isGcsUploadType(file)) {
    return uploadResumable(file, onProgress);
  }
  return uploadInstantly(file, onProgress);
}

export const uploadInstantly = async (file: File, onProgress: (progress: number) => void): Promise<FileUpload> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await apiAxios.post<FileUpload>('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    },
  });
  return res.data;
}

export const uploadResumable = async (file: File, onProgress: (progress: number) => void): Promise<FileUpload> => {
  // アップロードURLを準備
  const res1 = await apiAxios.post<FileUpload>(
    '/files/upload/initiate',
    {
      filename: file.name,
      mimetype: file.type,
      filesize: file.size,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const uploadUrl = res1.data.uploadUrl;
  if (!uploadUrl) {
    throw new Error('Failed to get upload URL');
  }

  // アップロード
  const res2 = await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    },
    validateStatus: (status) => {
      return status < 400; // 400未満のステータスを許容する
    },
  });

  if (!res2.status || (res2.status !== 200 && res2.status !== 201)) {
    const errorDetails = await res2.data;
    console.debug(`Failed to upload file: ${errorDetails}`);
    throw new Error('Failed to upload file');
  }

  // 解析開始
  await apiAxios.post<FileUpload>(`/files/${res1.data.id}/analysis`);

  // 状態のポーリング
  const interval = 1000;
  const maxAttempts = 60 * 3;  // 3分まで
  let attempts = 0;
  let res4;
  do {
    res4 = await apiAxios.get<FileUpload>(`/files/${res1.data.id}`);
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, interval));
  } while (
    (
      res4.data.analysisStatus != FileAnalysisStatus.DONE &&
      res4.data.analysisStatus != FileAnalysisStatus.FAILED
    ) && attempts < maxAttempts
  );

  if (res4.data.analysisStatus === FileAnalysisStatus.FAILED) {
    throw new Error('Failed to analyze file');
  }

  return res4.data;
}

export const isGcsUploadType = (file: File): boolean => {
  return [
    ...UploadableVideoMimeTypes,
    ...UploadableAudioMimeTypes,
    ...UploadableDocumentMimeTypes,
  ].includes(file.type);
}

export const convertMimeTypeToName = (mimeType: string) => {
  if (Object.prototype.hasOwnProperty.call(MimeTypeToName, mimeType)) {
    return MimeTypeToName[mimeType as keyof typeof MimeTypeToName];
  } else {
    return "その他";
  }
}

export const convertMimeTypeToFileType = (mimeType: string) : FileType | null => {
  if (UploadableImageMimeTypes.includes(mimeType)) {
    return FileType.IMAGE;
  } else if (UploadableAudioMimeTypes.includes(mimeType)) {
    return FileType.AUDIO;
  } else if (UploadableDocumentMimeTypes.includes(mimeType)) {
    return FileType.DOCUMENT;
  } else if (UploadableTxtMimeTypes.includes(mimeType)) {
    return FileType.TEXT;
  } else {
    return null;
  }
}

export const getLabelForFileType = (fileType: FileType) : string => {
  switch (fileType) {
    case FileType.IMAGE:
      return "画像";
    case FileType.AUDIO:
      return "音声";
    case FileType.DOCUMENT:
      return "PDF";
    case FileType.TEXT:
      return "テキスト";
  }
  return "その他";
}

export const checkUploadFile = (file: File | undefined) : string | undefined => {
  if (file) {
    const fileType = file.type;
    const fileSizeMb = file.size / MB;

    const isValidType = [
      ...UploadableImageMimeTypes,
      // ...UploadableVideoMimeTypes,
      ...UploadableAudioMimeTypes,
      ...UploadableDocumentMimeTypes,
      ...UploadableTxtMimeTypes
    ].includes(fileType);

    const maxFileSize = {
      ...Object.fromEntries(UploadableImageMimeTypes.map(type => [type, UploadableImageMaxMb])),
      // ...Object.fromEntries(UploadableVideoMimeTypes.map(type => [type, UploadableVideoMaxMb])),
      ...Object.fromEntries(UploadableAudioMimeTypes.map(type => [type, UploadableAudioMaxMb])),
      ...Object.fromEntries(UploadableDocumentMimeTypes.map(type => [type, UploadableDocumentMaxMb])),
      ...Object.fromEntries(UploadableTxtMimeTypes.map(type => [type, UploadableTxtMaxMb])),
    }[fileType];

    if (!isValidType) {
      return (
        "無効なファイルタイプです。\n" +
        `アップロード可能なファイルタイプは以下の通りです。\n\n` +
        `画像: ${UploadableImageMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
        // `ビデオ: ${UploadableVideoMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
        `オーディオ: ${UploadableAudioMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
        `ドキュメント: ${UploadableDocumentMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
        `テキスト: ${UploadableTxtMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}`
      );
    }

    if (fileSizeMb > maxFileSize) {
      return (
        "ファイルサイズが大きすぎます。\n" +
        `アップロード可能な最大ファイルサイズは以下の通りです。\n\n` +
        `画像: ${UploadableImageMaxMb}MB\n` +
        // `ビデオ: ${UploadableVideoMaxMb}MB\n` +
        `オーディオ: ${UploadableAudioMaxMb}MB\n` +
        `ドキュメント: ${UploadableDocumentMaxMb}MB\n` +
        `テキスト: ${UploadableTxtMaxMb}MB\n\n` +
        `選択されたファイルの最大サイズ: ${maxFileSize}MB`
      );
    }
  }

  return;
}

export const checkUploadFileAndModel = (file: File | FileUpload, modelIds: string[], currentMembership?: LoginUserMembership) : string | undefined => {
  // ファイルタイプを特定
  let fileType: FileType;
  if (file instanceof File) {
    const fileTypeTemp = convertMimeTypeToFileType(file.type);
    if (fileTypeTemp === null) {
      throw new Error('Invalid file type');
    }
    fileType = fileTypeTemp;
  } else {
    fileType = file.type;
  }

  // 送信対象となるモデルを取得
  if (modelIds.length === 0) {
    modelIds = [DEFAULT_AI_MODEL_ID];
  }
  const models = getAIModelConsts(modelIds);

  // 使っては行けないモデルを抽出
  const invalidModels: AIModelConst[] = []
  for (const model of models) {
    if (!model.uploadableFileTypes.includes(fileType)) {
      invalidModels.push(model);
    }
  }

  // 使っては行けないモデルがある場合、エラーメッセージを返す
  if (invalidModels.length > 0) {
    // どうサポートしてないかを表示
    const fileTypeLabel = getLabelForFileType(fileType);
    const mainMsg = (
      `下記のAIモデルは「${fileTypeLabel}」の添付をサポートしていません。\n` +
      "メンションまたは添付ファイルを見直してください。\n" +
      invalidModels.map(model => `・${model.name}`).join('\n')
    );

    // 送信可能なファイル情報メッセージを作成
    let additionalMsg = "";
    if (currentMembership) {
      console.log(currentMembership);
      const usableAiModelIds = currentMembership.team.quota.usableAiCodes || []
      console.log(currentMembership.team.quota);
      if (usableAiModelIds.length > 0) {
        additionalMsg = `\n\n[添付可能なファイル]`;

        const usableAiModels = getAIModelConsts(usableAiModelIds);
        for (const model of usableAiModels) {
          if (model.deprecated) {
            continue;
          }
          additionalMsg += `\n・${model.name} → ${
            model.uploadableFileTypes.map(type => getLabelForFileType(type)).join(', ')
          }`;
        }
      }
    }

    return mainMsg + additionalMsg;
  }

  return undefined;
}



export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};
