import React from 'react';
import { Typography, Button } from '@mui/material';
import { MessageType } from '../thread.constant';
import CommonPreModal from '@/components/ui/CommonPreModal';

interface ThreadPostWordReplacedInfoProps {
  body: string;
  type: MessageType;
  minimize?: boolean;
}

const ThreadPostWordReplacedInfo: React.FC<ThreadPostWordReplacedInfoProps> = ({ body, type, minimize = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // 中括弧で囲まれたテキストを解析し、太文字色付きで表示するための関数
  const renderContent = (text: string) => {
    const regex = /{{(.*?)}}/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) { // 中括弧で囲まれた部分
        return <span key={index} style={{ fontWeight: 'bold', color: 'red' }}>{'{{' + part + '}}'}</span>;
      } else {
        return part; // 通常のテキスト
      }
    });
  };

  const button = (
    <Button
      sx={minimize ? {lineHeight:1} : {}}
      variant="text"
      color="info"
      size="small"
      onClick={() => setIsOpen(true)}
    >
      { minimize ?
        "置換あり" :
        type === MessageType.USER ?
        "生成AIに送信した内容" :
        "生成AIから送信された内容"
      }
    </Button>
  );

  return (
    <>
      <Typography variant="caption">
        { minimize ? button : <>(ワード置換あり: {button} )</> }
      </Typography>
      <CommonPreModal
        open={isOpen}
        title={type === MessageType.USER ? "生成AIに送信した内容" : "生成AIから送信された内容"}
        onClose={() => setIsOpen(false)}>
        {renderContent(body)}
      </CommonPreModal>
    </>
  );
};

export default ThreadPostWordReplacedInfo;
