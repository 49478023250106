import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import SvQpListPresenter from '../presenters/SvQpListPresenter';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import { QuickPromptSetWithQpList } from '@/components/features/quickPrompt/quickPrompt.type';

const SvQpListContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [ quickPromptSets, setQuickPromptSets ] = useState<QuickPromptSetWithQpList[]>([]);
  const [ bookmarkedQpIds, setBookmarkedQpIds ] = useState<string[]>([]);
  const { setUnknownErrorModal } = useErrorModal();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);

  const teamId = team?.id;
  const baseApi = `/supervisor/teams/${teamId}`

  useEffect(() => {
    if (!teamId) return;
    (async () => {
      try {
        const [quickPromptSetsResponse, bookmarkedIdsResponse] = await Promise.all([
          apiAxios.get<QuickPromptSetWithQpList[]>(baseApi + '/quick-prompt-sets'),
          apiAxios.get<string[]>(baseApi + '/bookmarked-quick-prompt-ids')
        ]);

        setQuickPromptSets(quickPromptSetsResponse.data);
        setBookmarkedQpIds(bookmarkedIdsResponse.data);
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch quick prompt sets:', error);
      }
    })();
  }, [dispatch, teamId]);

  const handleAddBookmark = async (quickPromptId: string) => {
    (async () => {
      try {
        apiAxios.post(baseApi + `/quick-prompts/${quickPromptId}/bookmark`);
        setBookmarkedQpIds([...bookmarkedQpIds, quickPromptId]);
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to save data:', error);
      }
    })();
  };

  const handleRemoveBookmark = async (quickPromptId: string) => {
    (async () => {
      try {
        apiAxios.delete(baseApi + `/quick-prompts/${quickPromptId}/bookmark`);
        setBookmarkedQpIds(bookmarkedQpIds.filter(id => id !== quickPromptId));
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to save data:', error);
      }
    })();
  };

  return (
    <SvQpListPresenter
      sortedQuickPromptSets={quickPromptSets}
      bookmarkedQuickPromptIds={bookmarkedQpIds}
      handleAddBookmark={handleAddBookmark}
      handleRemoveBookmark={handleRemoveBookmark}
    />
  );
};

export default SvQpListContainer;
