import React, { useMemo } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import { ResReports } from '../report.api';
import { downloadCsvFromData } from '@/lib/csv';
import { TabType } from '../report.constant';
import { UnitType } from '../report.type';
import { getCsvTermFrom } from '../report.utils';

interface Props {
  report: ResReports;
  selectedTab: TabType;
  unitType: UnitType;
}

const ReportDetailTable: React.FC<Props> = ({ report, selectedTab, unitType}) => {
  const { csvHeaders, createCsvData } = useMemo(() => {
    switch (selectedTab) {
      case 'usage':
        return {
          csvHeaders: ['対象日From', '対象日To', 'スレッド作成回数', '投稿回数', 'AIの回答回数', '総クレジット', '入力クレジット', '出力クレジット', '合計文字数', '入力文字数', '出力文字数', '画像ファイル数', '音声ファイル数', 'PDFファイル数'],
          createCsvData: () => report.teamUsageReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            r.targetOn.replace(/-/g, '/'),
            r.threadNum.toString(),
            r.postCount.toString(),
            r.aiGenerateCount.toString(),
            r.totalCredit,
            r.inputCredit,
            r.outputCredit,
            (r.inputCharsInView + r.outputChars).toString(),
            r.inputCharsInView.toString(),
            r.outputChars.toString(),
            r.inputImageCountInView.toString(),
            r.inputAudioCountInView.toString(),
            r.inputDocumentCountInView.toString(),
          ]),
        };
      case 'usageDetail':
        return {
          csvHeaders: ['対象日From', '対象日To', '用途', 'AIモデル', 'AIの回答回数', '総クレジット', '入力クレジット', '出力クレジット', '合計文字数', '入力文字数', '出力文字数', '画像ファイル数', '音声ファイル数', 'PDFファイル数'],
          createCsvData: () => report.teamUsageDetailReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            r.targetOn.replace(/-/g, '/'),
            r.type,
            r.aiModel.code,
            r.aiGenerateCount.toString(),
            r.totalCredit,
            r.inputCredit,
            r.outputCredit,
            (r.inputCharsInView + r.outputChars).toString(),
            r.inputCharsInView.toString(),
            r.outputChars.toString(),
            r.inputImageCountInView.toString(),
            r.inputAudioCountInView.toString(),
            r.inputDocumentCountInView.toString(),
          ]),
        };
      case 'memberNum':
        return {
          csvHeaders: ['対象日From', '対象日To', '利用メンバー数'],
          createCsvData: () => report.teamMembershipNumReports.map(r => [
            getCsvTermFrom(r.targetOn, unitType),
            r.targetOn.replace(/-/g, '/'),
            r.memberNum.toString()
          ]),
        };
      default:
        return { csvHeaders: [], createCsvData: () => [] };
    }
  }, [report, selectedTab]);

  const handleDownloadCsv = async () => {
    const csvData = createCsvData();
    await downloadCsvFromData(csvHeaders, csvData, selectedTab+'Report.csv');
  };

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {
            selectedTab === 'usage' ?
            "チーム全体の利用量の集計データです。" :
            selectedTab === 'usageDetail' ?
            "各AIモデル、タイプごとの利用量の集計データです。利用があった日/週/月のみ表示されます。" :
            selectedTab === 'memberNum' ?
            "チームの利用メンバー数の集計データです。" :
            ""
          }
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleDownloadCsv}>
          CSVダウンロード
        </Button>
      </Box>
      <TableContainer component={Paper} style={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {csvHeaders.map(header => (
                <TableCell key={header} padding="normal" style={{ whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {createCsvData().map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell key={`${i}-${j}`} padding="normal"
                     style={{ whiteSpace: 'nowrap' }}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportDetailTable;
