import InfoPanel from '@/components/ui/InfoPanel';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const TeamsJoinSuccessPage = () => {
  return (
    <InfoPanel title="チーム参加完了">
      <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '30px' }}>
        おめでとうございます！ チームのメンバーになりました。
      </Typography>
      <Button component={Link} to="/" variant="contained" color="primary" style={{ padding: '10px 20px' }}>
        さっそくWorkAIzerを使ってみる
      </Button>
    </InfoPanel>
  );
};

export default TeamsJoinSuccessPage;
