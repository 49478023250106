import React from 'react';
import {
  Divider,
  Typography} from '@mui/material';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { TeamWithSubscription } from '../../team/team.type';
import SharedLatestUsageStatisticsPresenter from './SharedLatestUsageStatisticsPresenter';
import { PlanType } from '../../subscription/subscription.constant';

const LatestUsageStatisticsPresenter: React.FC<{
  team: TeamWithSubscription,
}> = ({
  team
}) => {
  const isStarter = team.currentSubscription.plan.type == PlanType.STARTER;
  return (
    <>
      <AdminPageTitle title="最新の利用状況" />
      <Divider/>
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
        { isStarter ?
          <>
            Starterプランを開始してからの利用状況を確認いただけます。<br/>
            (有料プランにアップグレード後は、最新1ヶ月分の利用状況が表示されるページとなります)
          </> :
          <>
            最新の1ヶ月分の利用状況を確認いただけます。1ヶ月ごとにリセットされるため、ご注意ください。<br/>
            (対象期間は契約開始日を基準に算出しています)
          </>
        }
        </Typography>
        <SharedLatestUsageStatisticsPresenter
            team={team} hideCreditInfo={isStarter}
          />
      </AdminContentsWrapper>
    </>
  );
};

export default LatestUsageStatisticsPresenter;
