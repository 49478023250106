import React from 'react';
import { Box, Typography } from '@mui/material';
import CommonPanel from './CommonPanel';

interface InfoPanelProps {
  title: string;
  children: React.ReactNode;
}

const InfoPanel: React.FC<InfoPanelProps> = ({ title, children }) => (
  <CommonPanel>
    <Box mb={4}>
      <Typography variant="h5" align="center" gutterBottom sx={{"marginBottom": 5}}>
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {children}
      </Typography>
    </Box>
  </CommonPanel>
);

export default InfoPanel;
