import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileUploadWithMembership } from './file.type';

interface FileState {
  files: FileUploadWithMembership[];
}

const initialState: FileState = {
  files: [],
};

const fileSlice = createSlice({
  name: 'svTeam',
  initialState,
  reducers: {
    setFiles: (state, action: PayloadAction<FileUploadWithMembership[]>) => {
      state.files = action.payload;
    },
    appendFiles: (state, action: PayloadAction<FileUploadWithMembership[]>) => {
      // idが同じものは追加しない
      action.payload.forEach((team) => {
        if (!state.files.some((t) => t.id === team.id)) {
          state.files.push(team);
        }
      });
    },
  },
});

export const {
  setFiles,
  appendFiles,
} = fileSlice.actions;

export default fileSlice.reducer;
