import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Tooltip,
} from '@mui/material';
import { DlpExpressionConfig } from '../dlp.type';
import { createExpressionConfig, updateExpressionConfig } from '../dlp.slice';
import { AppDispatch } from '@/store';
import { DlpPreventType } from '../dlp.constant';
import theme from '@/theme';
import { WaTextField } from '@/components/ui/WaTextField';

interface ExpressionConfigFormModalProps {
  open: boolean;
  handleClose: () => void;
  isEdit: boolean;
  defaultValues: DlpExpressionConfig;
}

const ExpressionConfigFormModal: React.FC<ExpressionConfigFormModalProps> = ({
  open,
  handleClose,
  isEdit,
  defaultValues,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, watch, reset, control, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const preventType = watch('preventType');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = async (data: DlpExpressionConfig) => {
    if (isEdit) {
      await dispatch(updateExpressionConfig(data));
    } else {
      await dispatch(createExpressionConfig(data));
    }
    handleClose();
  };

  const replaceWordRules = {
    required: preventType === DlpPreventType.REPLACE ? '置換ワードは必須です' : false,
    maxLength: { value: 50, message: '置換ワードは50文字以内で入力してください' },
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEdit ? 'ブロッカー設定更新' : 'ブロッカー設定追加'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
            <WaTextField
              label="設定名"
              variant="outlined"
              fullWidth
              {...register('name', {
                required: '設定名は必須です',
                maxLength: { value: 50, message: '設定名は50文字以内で入力してください' },

              })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </FormControl>

          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.expression)}>
            <WaTextField
              label="対象ワード"
              variant="outlined"
              fullWidth
              {...register('expression', {
                required: '対象ワードは必須です',
                maxLength: { value: 50, message: '対象ワードは50文字以内で入力してください' },
              })}
              error={!!errors.expression}
              helperText={errors.expression?.message}
            />
          </FormControl>

          <FormControl component="fieldset" error={Boolean(errors.preventType)} style={{ marginTop: '20px' }}>
            <FormLabel component="legend">動作</FormLabel>
            <Controller
              name="preventType"
              control={control}
              rules={{ required: '動作の選択は必須です' }}
              render={({ field }) => (
                <RadioGroup row {...field} >
                  <Tooltip title="対象ワードを送信できなくなります。">
                    <FormControlLabel value={DlpPreventType.PROHIBIT} control={<Radio />} label="禁止" />
                  </Tooltip>
                  <Tooltip title="対象ワードを送信する際に警告を表示します。">
                    <FormControlLabel value={DlpPreventType.WARN} control={<Radio />} label="警告" />
                  </Tooltip>
                  <Tooltip title="対象ワードを別のワードに差し替えて生成AIモデルに送信します。">
                    <FormControlLabel value={DlpPreventType.REPLACE} control={<Radio />} label="置換" />
                  </Tooltip>

                </RadioGroup>
              )}
            />
            {errors.preventType && <FormHelperText>{errors.preventType.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" style={{ marginTop: '20px' }} error={Boolean(errors.replaceWord)}>
            <WaTextField
              label="置換ワード"
              variant="outlined"
              fullWidth
              disabled={preventType !== DlpPreventType.REPLACE}
              {...register('replaceWord', replaceWordRules)}
              error={!!errors.replaceWord}
              helperText={errors.replaceWord?.message}
            />
            <FormHelperText style={{ color: preventType === DlpPreventType.REPLACE ? "inherit" : theme.palette.grey[500] }}>
              どのようなワードに置換するかを入力してください。禁止しているワードがどのような種類のものなのかを指定すると、置換後も生成AIが適切に動作しやすくなります。(例: ワード「WorkAIze」 → 置換文字「サービス名」)
            </FormHelperText>
          </FormControl>

          <DialogActions style={{ marginTop: '20px' }}>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" color="primary">保存</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExpressionConfigFormModal;
