
import React from 'react';
import { useTheme, Alert, AlertTitle, Collapse, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateUiMembershipFlag } from '../../auth/auth.slice';
import { UI_FLAG_QP_TUTORIAL_STATE_EKY, UI_FLAG_QP_TUTORIAL_STATE_VAL_DONE } from '../quickPrompt.constant';
import { RootState, useAppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { getUiFlagValue } from '../../auth/auth.type';

const QpTutorialContainer: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  const qpTutorial = getUiFlagValue(loginUser, UI_FLAG_QP_TUTORIAL_STATE_EKY);
  const qpTutorialDone = qpTutorial === UI_FLAG_QP_TUTORIAL_STATE_VAL_DONE;


  const handleAlertClose = () => {
    dispatch(updateUiMembershipFlag({
      key: UI_FLAG_QP_TUTORIAL_STATE_EKY,
      value: UI_FLAG_QP_TUTORIAL_STATE_VAL_DONE,
    }));
  };

  return (<>
    <Collapse in={!qpTutorialDone}>
      <Alert
        severity="info"
        sx={{
          borderRadius: 2,
          boxShadow: theme.shadows[3],
          margin: "auto",
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleAlertClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle sx={{ fontSize: "18px", fontWeight: 'bold', color: 'primary.main'}}>
          クイックプロンプトを使ってみましょう
        </AlertTitle>
        <span style={{ fontSize: "15px", lineHeight: 1.8 }}>
          クイックプロンプトは、簡単な入力で生成AIにタスクを依頼できる機能です。<br/>
          まずは下の「おすすめクイックプロンプト」から試してみましょう。
        </span>
      </Alert>
    </Collapse>
  </>);
};

export default QpTutorialContainer;
