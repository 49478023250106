import React, { useState } from 'react';
import apiAxios from '@/lib/axios';
import TeamFormFields from './TeamFormFields';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { LoginUser } from '../../auth/auth.type';
import { setUserInfo } from '../../auth/auth.slice';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Box, Divider, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { useToast } from '../../generic/hooks/useToast';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { displayTimestamp } from '@/lib/utils';

const TeamUpdateForm: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const { setUnknownErrorModal } = useErrorModal();

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return null;
  }

  const currentMembership = loginUser.memberships.find((membership) => membership.isCurrent);
  if (!currentMembership) {
    return null;
  }
  const team = currentMembership.team

  const onSubmit = async (data: { name: string }) => {
    setIsLoading(true);
    try {
      await apiAxios.put(`/teams/${team.id}`, data);
      const response = await apiAxios.get<LoginUser>('/auth/profile');
      dispatch(setUserInfo(response.data))
      showToast('チーム情報を更新しました。', 'success')
    } catch (err) {
      captureException(err);
      setUnknownErrorModal();
      console.error("put /teams/:id error", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AdminPageTitle title="チーム基本情報" />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
            チームの基本的な情報を確認・編集できます。
        </Typography>
        <Box mb={4} ml={1.5}>
          <Typography variant="caption" gutterBottom>
            チームID
          </Typography>
          <Typography variant="body1">{team.id}</Typography>
          <br/>
          <Typography variant="caption" gutterBottom>
            登録日時
          </Typography>
          <Typography variant="body1">{displayTimestamp(team.createdAt)}</Typography>
        </Box>


        <TeamFormFields
          defaultValues={team}
          onSubmit={onSubmit}
          isLoading={isLoading}
          submitButtonText="更新"
        />
      </AdminContentsWrapper>
    </>
  );
};

export default TeamUpdateForm;
