import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { QuickPromptSetWithQpList, QuickPromptWithVersion } from './quickPrompt.type';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { showUnknownErrorModal } from '../generic/errorModal.slice';
import { AxiosError } from 'axios';

interface QuickPromptState {
  quickPromptSets: QuickPromptSetWithQpList[];
  bookmarkedQuickPromptIds: string[];
}

const initialState: QuickPromptState = {
  quickPromptSets: [],
  bookmarkedQuickPromptIds: [],
};

export const addBookmark = createAsyncThunk(
  'quickPrompt/addBookmark',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      setTimeout(() => apiAxios.post(`/quick-prompts/${id}/bookmark`), 1);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const removeBookmark = createAsyncThunk(
  'quickPrompt/removeBookmark',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      setTimeout(() => apiAxios.delete(`/quick-prompts/${id}/bookmark`), 1);
      return id;
    } catch (err) {
      captureException(err);
      if (err instanceof AxiosError) {
        dispatch(showUnknownErrorModal());
        return rejectWithValue(err.message);
      }
      dispatch(showUnknownErrorModal());
      return rejectWithValue('不明なエラーが発生しました');
    }
  }
);

export const quickPromptSlice = createSlice({
  name: 'quickPrompt',
  initialState,
  reducers: {
    setQuickPromptSets: (state, action: PayloadAction<QuickPromptSetWithQpList[]>) => {
      state.quickPromptSets = action.payload;
    },
    updateQuickPrompt: (state, action: PayloadAction<QuickPromptWithVersion>) => {
      const quickPrompt = action.payload;
      state.quickPromptSets.forEach((set) => {
        if (set.id === quickPrompt.quickPromptSetId) {
          set.quickPrompts.forEach((qp, index) => {
            if (qp.id === quickPrompt.id) {
              set.quickPrompts[index] = quickPrompt;
            }
          });
        }
      });
    },
    setBookmarkedQuickPromptIds: (state, action: PayloadAction<string[]>) => {
      state.bookmarkedQuickPromptIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addBookmark.fulfilled, (state, action) => {
      state.bookmarkedQuickPromptIds.unshift(action.payload);
    });
    builder.addCase(removeBookmark.fulfilled, (state, action) => {
      state.bookmarkedQuickPromptIds = state.bookmarkedQuickPromptIds.filter((id) => id !== action.payload);
    });
  },
});

export const {
  setQuickPromptSets,
  updateQuickPrompt,
  setBookmarkedQuickPromptIds,
} = quickPromptSlice.actions;

export default quickPromptSlice.reducer;
