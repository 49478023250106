import React from 'react';
import { Thread } from '../thread.type';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import ThreadPost from './ThreadPost';

interface ThreadPostListContainerProps {
  currentThread: Thread;
}

const ThreadPostListContainer: React.FC<ThreadPostListContainerProps> = ({
  currentThread,
}) => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) return <></>;

  // CurrentNodeのIDを取得
  const currentNodeId = currentThread.currentMessageNode.messageNodeId;

  // 該当のNodeを取得
  const currentNode = currentThread.messageNodes.find((node) => node.id === currentNodeId);
  if (!currentNode) return <></>;

  // 該当ノードに連なるノードを取得
  const targetNodeIds = currentNode.path;
  const targetNodes = currentThread.messageNodes.filter((node) => targetNodeIds.includes(node.id));
  targetNodes.push(currentNode);

  return targetNodes.map((node) => <ThreadPost key={node.id} node={node} />)
}

export default ThreadPostListContainer;
