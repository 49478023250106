export enum MembershipRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  NORMAL = 'normal'
}

export const getMembershipRoleLabel = (role: MembershipRole): string => {
  switch (role) {
    case MembershipRole.SUPER_ADMIN:
      return '特権管理者';
    case MembershipRole.ADMIN:
      return '管理者';
    case MembershipRole.NORMAL:
      return '一般';
    default:
      return '';
  }
};

export const getMembershipRoleDescription = (role: MembershipRole): string => {
  switch (role) {
    case MembershipRole.SUPER_ADMIN:
      return '通常機能と管理機能を使え、また他の管理者を追加することができます。';
    case MembershipRole.ADMIN:
      return '通常機能と管理機能を使えます。ただし他の管理者は追加できません。';
    case MembershipRole.NORMAL:
      return '基本機能のみを使えます。管理機能を使うことはできません。';
    default:
      return '';
  }
};
