import { ResReports } from "./report.api";


export enum UnitType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface ReportState {
  report: ResReports | null;
  loading: boolean;
  error: string | null;
}
