import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl
} from '@mui/material';
import { MembershipGroupCreate, MembershipGroupUpdate } from '../membership-group.type';
import { createMembershipGroup, updateMembershipGroup } from '../membership-group.slice';
import { AppDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';


type MembershipGroupSave = MembershipGroupCreate | MembershipGroupUpdate;

interface MembershipGroupFormModalProps {
  open: boolean;
  handleClose: () => void;
  defaultValues: MembershipGroupSave;
}

const MembershipGroupFormModal: React.FC<MembershipGroupFormModalProps> = ({
  open,
  handleClose,
  defaultValues,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if(!loginUser) {
    return null;
  }

  // 編集可否か
  const isEdit = 'id' in defaultValues;

  const onSubmit = async (data: MembershipGroupSave) => {
    if (isEdit) {
      await dispatch(updateMembershipGroup(data as MembershipGroupUpdate));
    } else {
      await dispatch(createMembershipGroup(data as MembershipGroupCreate));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEdit ? 'グループ更新' : 'グループ追加'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {(
            <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.name)}>
              <WaTextField
                label="グループ名"
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('name', {
                  required: 'グループ名は必須です',
                  maxLength: { value: 100, message: 'グループ名は100文字以内で入力してください' },
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <WaTextField
                style={{marginTop: '20px'}}
                multiline
                label="説明"
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('description', {
                  maxLength: { value: 500, message: 'グループの説明は500文字以内で入力してください' },
                })}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </FormControl>
          )}

          <DialogActions style={{ marginTop: '20px' }}>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" color="primary">保存</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipGroupFormModal;
