import React, { useMemo } from 'react';
import { Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { ResReports, ResTeamUsageDetailReport } from '../report.api';

interface Props {
  report: ResReports;
}

const TITLE_GENERATION = "タイトル生成";
const COLORS = [
  '#FF6347',
  '#4682B4',
  '#3CB371',
  '#FF4500',
  '#32CD32',
];

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  .recharts-tooltip-wrapper {
    z-index: 1000;
  }
`;

const ReportModelStatistics: React.FC<Props> = ({ report }) => {
  const data = useMemo(() => {
    const modelData: { [key: string]: any } = {};

    report.teamUsageDetailReports.forEach((r: ResTeamUsageDetailReport) => {
      if (r.type === 'MESSAGE_GENERATION' || r.type === 'MESSAGE_FAILURE') {
        if (!modelData[r.aiModel.name]) {
          modelData[r.aiModel.name] = { aiGenerateCount: 0, totalCredit: 0, totalChars: 0 };
        }
        modelData[r.aiModel.name].aiGenerateCount += r.aiGenerateCount;
        modelData[r.aiModel.name].totalCredit += parseFloat(r.totalCredit);
        modelData[r.aiModel.name].totalChars += r.inputCharsToAi + r.outputChars;
      }
    });
    report.teamUsageDetailReports.forEach((r: ResTeamUsageDetailReport) => {
      if (r.type === 'TITLE_GENERATION') {
        if (!modelData[TITLE_GENERATION]) {
          modelData[TITLE_GENERATION] = { aiGenerateCount: 0, totalCredit: 0, totalChars: 0 };
        }
        modelData[TITLE_GENERATION].aiGenerateCount += r.aiGenerateCount;
        modelData[TITLE_GENERATION].totalCredit += parseFloat(r.totalCredit);
        modelData[TITLE_GENERATION].totalChars += r.inputCharsToAi + r.outputChars;
      }
    });

    return { modelData };
  }, [report]);

  const pieData = useMemo(() => {
    const { modelData } = data;
    const totalValues = Object.values(modelData).reduce((acc, curr) => {
      acc.aiGenerateCount += curr.aiGenerateCount;
      acc.totalCredit += curr.totalCredit;
      acc.totalChars += curr.totalChars;
      return acc;
    }, { aiGenerateCount: 0, totalCredit: 0, totalChars: 0 });

    return Object.keys(modelData).map((key, index) => ({
      name: key,
      aiGenerateCount: modelData[key].aiGenerateCount,
      totalCredit: parseFloat(modelData[key].totalCredit.toFixed(2)),
      totalChars: modelData[key].totalChars,
      color: COLORS[index % COLORS.length],
      aiGenerateCountPercentage: ((modelData[key].aiGenerateCount / totalValues.aiGenerateCount) * 100).toFixed(2),
      totalCreditPercentage: ((modelData[key].totalCredit / totalValues.totalCredit) * 100).toFixed(2),
      totalCharsPercentage: ((modelData[key].totalChars / totalValues.totalChars) * 100).toFixed(2),
    }));
  }, [data]);

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' }, gap: 2 }}>
      <Paper sx={{ flex: 1, padding: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>モデル</TableCell>
                <TableCell>生成回数</TableCell>
                <TableCell>クレジット</TableCell>
                <TableCell>文字数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data.modelData).map((model, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: COLORS[index % COLORS.length], fontWeight: 'bold' }}>{model}</TableCell>
                  <TableCell>{data.modelData[model].aiGenerateCount}</TableCell>
                  <TableCell>{data.modelData[model].totalCredit.toFixed(2)}</TableCell>
                  <TableCell>{data.modelData[model].totalChars}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <caption style={{ textAlign: 'right', padding: '8px', fontStyle: 'italic', color: '#888' }}>
              クレジットと文字数の値は、入力と出力の合計値です
            </caption>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ flex: 1, padding: 2 }}>
        <Grid container spacing={2}>
          {['aiGenerateCount', 'totalCredit', 'totalChars'].map((key, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Typography variant="h6" align="center">
                {key === 'aiGenerateCount' ? '生成回数' : key === 'totalCredit' ? 'クレジット' : '文字数'}
              </Typography>
              <StyledResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey={key}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value, name, props) => [`${value} (${props.payload[`${key}Percentage`]}%)`, name]}
                  />
                </PieChart>
              </StyledResponsiveContainer>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ReportModelStatistics;
