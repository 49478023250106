import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

const ResponsiveTableCellBase = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const ResponsiveTableCell = styled(ResponsiveTableCellBase)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '&:before': {
      content: 'attr(data-label)',
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
  },
}));

export const ResponsiveTableRowHeader = styled(ResponsiveTableCellBase)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    justifyContent: 'flex-start',
  },
}));

export const ResponsiveTableActionFooter = styled(ResponsiveTableCellBase)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-end',
    borderBottom: 'none',
  },
}));

export const ResponsiveTableRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    border: `3px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '18px',
    marginBottom: theme.spacing(2),
  },
}));
