import React from 'react';
import { Avatar, Box, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/system';
import WorkAizerAvatar from '@/assets/images/logo/logo.png'
import { AIModelConst } from '../aiModel.constant';

interface Props {
  aiModelConsts: AIModelConst[];
}

const StyledListItemText = styled(ListItemText)(() => ({
  flex: 1,
  fontSize: '14px',
  '& .MuiTypography-root': {
    fontWeight: 500,
  },
}));


const LastMentionedAIModelsListItem: React.FC<Props> = ({ aiModelConsts }) => {
  return (<>
    <ListItemAvatar>
      <Avatar src={WorkAizerAvatar} alt="WorkAIzerロゴ" />
    </ListItemAvatar>
    <StyledListItemText
      primary={
        <Box component="span" display="flex" alignItems="center">
          <Typography variant="subtitle1" component="span">
            前回のスレッド作成で使ったAIモデル
          </Typography>
        </Box>
      }
      secondary={
        <Box component="span" display="flex" alignItems="center">
          <Box component="span" color="text.secondary">
            {
              // aiModelsの各nameをスラッシュ区切りで
              // (ただし最初の３つまでで、３つを超えた場合は４つ目をetcとする)
              aiModelConsts.slice(0, 3).
                map(model => model.name).
                join(' / ') +
                (aiModelConsts.length > 3 ? ' / etc' : '')
            }
          </Box>
        </Box>
      }
    />
  </>);
};

export default LastMentionedAIModelsListItem;