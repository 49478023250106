import * as Sentry from "@sentry/react";

const environment = import.meta.env.VITE_ENVIRONMENT;
const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const tracesSampleRate = parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || '1.0');
const replaysSessionSampleRate = parseFloat(import.meta.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '1.0');
const replaysOnErrorSampleRate = parseFloat(import.meta.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0');

Sentry.init({
    environment,
    dsn: sentryDSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // パフォーマンスモニタリング
     //トランザクションの100%をキャプチャする
    tracesSampleRate,
    // 分散トレースを有効にするURLを制御するには、'tracePropagationTargets'を設定します
    // tracePropagationTargets: [
    //     "localhost", /^https:\/\/work\.io\/api/
    // ],
    // セッションリプレイ
    // 開発中は100%に変更し、本番環境ではより低いレートでサンプリングすることをお勧めします。
    replaysSessionSampleRate,
    // まだセッション全体をサンプリングしていない場合は、
    // エラーが発生したセッションをサンプリングするときにサンプルレートを100%に変更してください。
    replaysOnErrorSampleRate,
});



