import React from 'react';
import { Avatar, Menu, MenuItem, Button, Box, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { LoginUser, getCurrentMembership } from '../../auth/auth.type';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface HeaderUserMenuPresenterProps {
  loginUser: LoginUser;
  isMenuOpen: boolean;
  anchorEl: null | HTMLElement;
  menuItems: { label: string; onClick?: () => void; url?: string }[];
  onMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
}

const HeaderUserMenuPresenter: React.FC<HeaderUserMenuPresenterProps> = ({
  loginUser,
  isMenuOpen,
  anchorEl,
  menuItems,
  onMenuOpen,
  onMenuClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 現在のメンバーシップ
  const currentMembership = getCurrentMembership(loginUser);

  const avatarIcon = <Avatar alt="User" src={loginUser.picture}/>;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      ...(isMobile ? {} : {
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      }),
    }}>

      {
        isMobile ?
        <IconButton aria-label="user" size="small" onClick={onMenuOpen}>
          {avatarIcon}
        </IconButton> :
        <Button
          color="inherit"
          onClick={onMenuOpen}
          startIcon={avatarIcon}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            padding: "6px 14px",
            marginLeft: "8px",
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            ...(currentMembership ? {width: "126px"} : {}),
          }}>
            <Typography variant="body2" color="text.secondary">{loginUser.name}</Typography>
            {
              currentMembership &&
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '126px'
                }}>
                {currentMembership.team.name}
              </Typography>
            }
          </Box>
        </Button>
      }
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={onMenuClose}
      >
        {menuItems.map((item, index) => {
          const menuItemProps = {
            key: index,
            onClick: item.onClick,
            sx: {
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                cursor: item.url ? 'pointer' : 'default',
              },
              userSelect: 'none',
            }
          };

          return <Box key={index}>
            { item.url ?
              <MenuItem
                {...menuItemProps}
                component="a"
                href={item.url}
                target="_blank"
                rel="noopener"
                title={item.url}
              >
                {item.label}
              </MenuItem> :
              <MenuItem {...menuItemProps}>
                {item.label}
              </MenuItem>
            }
          </Box>
        })}
      </Menu>
    </Box>
  );
};

export default HeaderUserMenuPresenter;
