import { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import useLoading from '@/components/features/generic/hooks/useLoading';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';

export function DebugFileUploadPage() {
  const { isLoading, setIsLoading } = useLoading();
  const { setUnknownErrorModal } = useErrorModal();
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await apiAxios.post('/files/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        },
      });
      setIsLoading(false);
      setUploadProgress(0);
      setFile(null);
      alert('ファイルが正常にアップロードされました');
    } catch (err) {
      setUnknownErrorModal();
      captureException(err);
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <Box sx={{ paddingTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Typography variant="h4">ファイルアップロードテスト</Typography>
      <hr />
      <input
        type="file"
        onChange={handleFileChange}
        disabled={isLoading}
        style={{ marginBottom: 20 }}
      />
      {file && (
        <Typography variant="body1">{file.name}</Typography>
      )}
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={isLoading || !file}
      >
        アップロード
      </Button>
      {isLoading && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2 }}>
          <CircularProgress />
          <Typography variant="body2">{uploadProgress}%</Typography>
        </Box>
      )}
    </Box>
  );
}
