import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import { FileUploadWithMembership } from '../file.type';
import { appendFiles, setFiles } from '../file.slice';
import { getCurrentMembership } from '../../auth/auth.type';
import FileListPresenter from '../presenters/FileListPresenter';

const LIMIT = 20;

const FileListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const files = useSelector((state: RootState) => state.file.files);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [hasMore, setHasMore] = useState(true);
  const { setUnknownErrorModal } = useErrorModal();
  const team = getCurrentMembership(loginUser)?.team;

  useEffect(() => {
    handleScroll(true);
  }, []);

  if (!team) {
    return null;
  }

  const handleScroll = async (init: boolean = false) => {
    if (!team) {
      return
    }
    try {
      const response = await apiAxios.get<FileUploadWithMembership[]>(`/teams/${team.id}/files`, {
        params: {
          offset: init ? 0 : files.length,
          limit: LIMIT,
        },
      });
      if (init) {
        dispatch(setFiles(response.data));
      } else {
        dispatch(appendFiles(response.data));
      }
      if (response.data.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('Failed to fetch more files:', error);
    }
  };

  return (
    <FileListPresenter
      files={files}
      onScroll={handleScroll}
      hasMore={hasMore}
    />
  );
};

export default FileListContainer;
