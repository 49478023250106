import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { fetchNewsById } from '../news.slice';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, Chip, styled } from '@mui/material';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import CommonPanel from '@/components/ui/CommonPanel';
import CustomMarkdown from '@/components/ui/CustomMarkdown';

const NewsDetailWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '1000px',
  },
}));

const NewsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const currentNews = useSelector((state: RootState) => state.news.currentNews);

  useEffect(() => {
    if (id) {
      dispatch(fetchNewsById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (currentNews && currentNews.type === 'external') {
      window.location.href = currentNews.externalUrl || '/';
    }
  }, [currentNews]);

  if (!currentNews || currentNews.type === 'external') {
    return null;
  }

  const canGoBack = location.key !== 'default';

  return (
    <CommonPanel>
      <NewsDetailWrapper>
        {canGoBack && (
          <Box mb={3}>
            <Button onClick={() => navigate(-1)} variant="text" color="primary">
              ← 戻る
            </Button>
          </Box>
        )}
        <Box display="flex" alignItems="center" mb={1}>
          <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
            {format(new Date(currentNews.publishedAt), 'yyyy年MM月dd日 HH:mm', { locale: ja })}
          </Typography>
          {currentNews.isImportant && (
            <Chip variant="outlined" label="大事なお知らせ"
                      color="error" size="small" />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" component="h1">
            {currentNews.title}
          </Typography>
        </Box>
        <Box mt={5}>
          <CustomMarkdown markdown={currentNews.body} />
        </Box>
      </NewsDetailWrapper>
    </CommonPanel>
  );
};

export default NewsDetail;
