import React from 'react';
import { Button, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import apiAxios from '@/lib/axios';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { captureException } from '@sentry/react';
import { FileUpload } from '../file.type';
import { FileStorageType } from '../file.constant';

interface FileDownloadButtonProps {
  file: FileUpload;
  variant?: 'text' | 'icon';
}

const FileDownloadButton: React.FC<FileDownloadButtonProps> = ({ file, variant = 'text' }) => {
  const { setErrorModal } = useErrorModal();

  const handleDownload = async () => {
    try {
      console.debug('Downloading file:', file);
      console.debug('file.storageType:', file.storageType);
      console.debug('condition:', file.storageType == FileStorageType.GCS_UPLOAD);
      if (file.storageType == FileStorageType.GCS_UPLOAD) {
        // GCSからダウンロード
        const response = await apiAxios.get(`/files/${file.id}/gcs-download`);
        window.location.href = response.data.download_url;

      } else {
        // APIサーバーからダウンロード
        const response = await apiAxios.get(`/files/${file.id}/download`, {
          responseType: 'blob',
        });

        const contentDisposition = response.headers['content-disposition'];

        let fileName = 'downloaded_file';
        if (contentDisposition) {
          let fileNameMatch = contentDisposition.match(/filename\*=UTF-8''([^;]*)/);
          if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = decodeURIComponent(fileNameMatch[1]);
          } else {
            fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error('ダウンロードエラー', error);
      captureException(error);
      setErrorModal(
        "ダウンロードエラー",
        "ファイルのダウンロードに失敗しました。\n" +
        "しばらく時間をおいて再度お試しください。\n" +
        "問題が解決しない場合は、管理者にお問い合わせください。"
      );
    }
  };

  return (
    variant === 'icon' ? (
      <IconButton color="primary" onClick={handleDownload}>
        <DownloadIcon />
      </IconButton>
    ) : (
      <Button variant="contained" color="primary" onClick={handleDownload}>
        ダウンロード
      </Button>
    )
  );
};

export default FileDownloadButton;
