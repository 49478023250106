import React, { useState } from 'react';
import { Plan } from '@/components/features/subscription/subscription.type';
import { ReqUpdateQuotaCustomization, ReqScheduleLicenseChange, ReqSchedulePlanChange, SvSubscriptionWithFull } from '../subscription.type';
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { SvTeamDetail } from '../../teams/team.type';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import SvSubscriptionPlanFormModal from '../components/SvSubscriptionPlanFormModal';
import SvSubscriptionLicenseFormModal from '../components/SvSubscriptionLicenseFormModal';
import SvQuotaCustomizationFormModal from '../components/SvQuotaCustomizationFormModal';

interface SvSubscriptionDetailPresenterProps {
  team: SvTeamDetail;
  plans: Plan[];
  subscriptions: SvSubscriptionWithFull[];
  onSchedulePlanChange: (data: ReqSchedulePlanChange) => void;
  onScheduleLicenseChange: (data: ReqScheduleLicenseChange) => void;
  onHandleQuotaCustomization: (subscriptionId: string, data: ReqUpdateQuotaCustomization) => void;
  onCancelPlanChange: () => void;
  onCancelLicenseChange: () => void;
}

const SvSubscriptionDetailPresenter: React.FC<SvSubscriptionDetailPresenterProps> = ({
  team,
  plans,
  subscriptions,
  onSchedulePlanChange,
  onScheduleLicenseChange,
  onHandleQuotaCustomization,
  onCancelPlanChange,
  onCancelLicenseChange,
}) => {
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
  
  const currentSub = team.currentSubscription;
  const [selectedSubscription, setSelectedSubscription] = useState(currentSub);

  return <>
    <SupervisorPageTitle title="契約内容" />
    <Divider />
    <SupervisorContentsWrapper>
      <Box sx={{ mb: 4 }}>
        <Tooltip title={team.scheduledSubscription ? "既に予約された変更があります。" : null}>
          <span>
            <Button
              variant="contained"
              onClick={() => setIsPlanModalOpen(true)}
              disabled={!!team.scheduledSubscription}
              sx={{ mr: 2}}
            >
              プラン変更
            </Button>
          </span>
        </Tooltip>

        <Tooltip title={currentSub.scheduledLicense ? "既に予約された変更があります。" : null}>
          <span>
            <Button
              variant="contained"
              onClick={() => setIsLicenseModalOpen(true)}
              disabled={!!currentSub.scheduledLicense}
            >
              ライセンス数変更
            </Button>
          </span>
        </Tooltip>

      </Box>

      <TableContainer>
        <Typography variant="h6" gutterBottom>
            現在の内容
        </Typography>
        <Table style={{ width: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell>プラン</TableCell>
              <TableCell>期間</TableCell>
              <TableCell>ライセンス数</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {currentSub.plan.name}
                {!!team.scheduledSubscription && (
                  <Typography variant="caption" color="error" component="span">
                    {' '}(変更予約あり)
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
              </TableCell>
              <TableCell>
                {currentSub.currentLicense?.amount}
                {!!currentSub.scheduledLicense && (
                  <Typography variant="caption" color="error" component="span">
                    {' '}(変更予約あり)
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 7 }}>
        <Typography variant="h6" gutterBottom>
            更新履歴
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>プラン</TableCell>
                <TableCell>状態</TableCell>
                <TableCell>適用開始日</TableCell>
                <TableCell>適用終了日</TableCell>
                <TableCell>契約基準日</TableCell>
                <TableCell>最新の期間</TableCell>
                <TableCell>メモ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((sub) => (
                <React.Fragment key={"subscription-" + sub.id}>
                  <TableRow>
                    <TableCell>
                      {sub.plan.name}
                      <Link
                        style={{ marginLeft: '3px' }}
                        component="button"
                        variant="body2"
                        onClick={() => {
                          setSelectedSubscription(sub);
                          setIsQuotaModalOpen(true);
                        }}
                      > カスタム
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Chip
                        sx={{ fontWeight: 'bold' }}
                        label={sub.isCurrent ? '現在' : sub.isScheduled ? '予約' : '終了'}
                        color={sub.isCurrent ? 'primary' : sub.isScheduled ? 'success' : 'default'}
                      />
                      {sub.isScheduled &&
                        <Button
                          variant='text'
                          size="small"
                          color="error"
                          onClick={() => onCancelPlanChange()}
                        >取消</Button>
                      }
                    </TableCell>
                    <TableCell>{sub.applyStartedOn}</TableCell>
                    <TableCell>{sub.applyEndedOn}</TableCell>
                    <TableCell>{sub.standardOn}</TableCell>
                    <TableCell>{sub.latestContractTerm[0]}〜{sub.latestContractTerm[1]}</TableCell>
                    <TableCell>{sub.memo}</TableCell>
                  </TableRow>

                  {sub.licenses.map((li) => (
                    <TableRow key={"license-" + li.id}>
                      <TableCell>
                          &nbsp;&nbsp;&nbsp;└{li.amount}ライセンス
                          {
                            li.diff && <>
                              (
                                {li.diff > 0 ? "+" : ""}{li.diff}
                              )
                            </>
                          }
                      </TableCell>
                      <TableCell sx={{
                        color: li.isCurrent ? 'primary.main' : li.isScheduled ? 'success.main' : 'text.primary',
                        paddingLeft: 3.2
                      }}>
                        { sub.licenses.length > 1 && <>
                          {
                            li.isCurrent ? '現在' :
                            li.isScheduled ? '予約' :
                            '終了'
                          }
                          {li.isScheduled &&
                            <Button
                              variant='text'
                              size="small"
                              color="error"
                              onClick={() => onCancelLicenseChange()}
                            >取消</Button>
                          }
                        </>}
                      </TableCell>
                      <TableCell>{
                        sub.licenses.length > 1 ? li.applyStartedOn : ""
                      }</TableCell>
                      <TableCell>{
                        sub.licenses.length > 1 ? li.applyEndedOn : ""
                      }</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>{li.memo}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </SupervisorContentsWrapper>
    <SvSubscriptionPlanFormModal
      open={isPlanModalOpen}
      plans={plans}
      onSubmit={onSchedulePlanChange}
      handleClose={() => setIsPlanModalOpen(false)}
    />
    <SvSubscriptionLicenseFormModal
      open={isLicenseModalOpen}
      onSubmit={onScheduleLicenseChange}
      handleClose={() => setIsLicenseModalOpen(false)}
    />
    <SvQuotaCustomizationFormModal
      open={isQuotaModalOpen}
      onSubmit={(data) => onHandleQuotaCustomization(selectedSubscription.id, data)}
      handleClose={() => setIsQuotaModalOpen(false)}
      subscription={selectedSubscription}
    />
  </>;
};

export default SvSubscriptionDetailPresenter;
