import { FetchEventSourceInit, fetchEventSource } from '@microsoft/fetch-event-source';
import { joinPath } from './utils';

// 環境変数からAPIのベースURLを取得
const apiUrl = import.meta.env.VITE_API_URL;

export interface ServerSentEventResponse {
    id: string,
    event: string,
    data: object | object[] | undefined,
    retry?: number;
}

type CustomFetchEventSourceInit = Omit<FetchEventSourceInit, 'onmessage'> & {
    jsonBody?: object;
    onmessage: (ev: ServerSentEventResponse) => void;
}

export function apiSSE(endpoint: string, detail: CustomFetchEventSourceInit) : Promise<void>{
    const sseUrl = joinPath(apiUrl, 'sse', endpoint);

    const customOnClose = detail.onclose;
    const customOnError = detail.onerror;
    const customOnMessage = detail.onmessage;
    const jsonBody = detail.jsonBody;

    const wrappedDetail: FetchEventSourceInit = {
        ...detail,
        headers: jsonBody ? {'Content-Type': 'application/json'} : detail.headers,
        body: jsonBody ? JSON.stringify(jsonBody) : detail.body,
        openWhenHidden: true, // バックグラウンドでも接続を維持
        onmessage(event) {
            console.debug("sse event:", event.event, event)
            customOnMessage({
                ...event,
                data: event.data ? JSON.parse(event.data) : undefined
            })
        },
        onclose() {
            if (customOnClose) {
                customOnClose();
            }
        },
        onerror(err) {
            // 401 Unauthorizedエラーの場合、カスタムイベントを発火してリダイレクト
            if (err.response && err.response.status === 401) {
                window.dispatchEvent(new CustomEvent("unauthorized"));
            }

            if (customOnError) {
                customOnError(err);
            }
        }
    };
    return fetchEventSource(sseUrl, wrappedDetail);
}

let globalAbortController: AbortController | null = null;
let onAborted: (() => void) | null = null;

export const getGlobalAbortController = (customOnAborted?: () => void): AbortController => {
    if (!globalAbortController || globalAbortController.signal.aborted) {
        globalAbortController = new AbortController();
    }
    if (customOnAborted) {
        onAborted = customOnAborted;
    }
  return globalAbortController;
};

export const abortGlobalController = (): void => {
    if (globalAbortController) {
        globalAbortController.abort();
        globalAbortController = null;
    }
    if (onAborted) {
        onAborted();
        onAborted = null;
    }
};
