import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { isAdmin } from '../../auth/auth.type';

const TeamAdminGuard: FC = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (loginUser && !isAdmin(loginUser)) {
      // 管理者権限がない場合は権限不足画面へ遷移
      navigate('/error/forbidden');
    }
  }, [loginUser, navigate]);


  return <Outlet />;
};

export default TeamAdminGuard;
