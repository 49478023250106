import React from 'react';
import { RootState } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { hideErrorModal } from '../errorModal.slice';
import ErrorModalPresenter from '../presenters/ErrorModalPresenter';

const StateErrorModalContainer: React.FC = () => {
  const errorContent = useSelector((state: RootState) => state.error.content);
  const dispatch = useDispatch();

  const isOpen = !!errorContent;
  const closeErrorModal = () => {
    dispatch(hideErrorModal());
  };
  const title = errorContent ? errorContent.title : null;
  const message = errorContent ? errorContent.message : null;
  const buttonOption = errorContent ? errorContent.buttonOption : null;

  return (
    <ErrorModalPresenter
      isOpen={isOpen}
      title={title}
      message={message}
      buttonOption={buttonOption}
      onClose={closeErrorModal}
    />
  );
};

export default StateErrorModalContainer;
