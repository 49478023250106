import apiAxios from "@/lib/axios";

const ApiErrorPage = () => {

  const callError = async (endpoint: string) => {
    await apiAxios.get('/debug/' + endpoint);
  };

  return <div style={{"paddingTop": 100}}>
      <button onClick={() => callError('error500')}>callError500</button>
      <br/><br/>
      <button onClick={() => callError('error400')}>callError400</button>
  </div>;
};

export default ApiErrorPage;
