import React from 'react';
import {
  Divider} from '@mui/material';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { SvTeamDetail } from '../../teams/team.type';
import SharedLatestUsageStatisticsPresenter from '@/components/features/usage/presenters/SharedLatestUsageStatisticsPresenter';

const SvLatestUsageStatisticsPresenter: React.FC<{
  team: SvTeamDetail
}> = ({
  team
}) => {
  return (
    <>
      <SupervisorPageTitle title="最新の利用状況" />
      <Divider />
      <SupervisorContentsWrapper>
        <SharedLatestUsageStatisticsPresenter
            team={team}
          />
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvLatestUsageStatisticsPresenter;
