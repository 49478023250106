import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { changeCurrent } from '../../auth/auth.slice';

const TeamMembershipGuard: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (loginUser) {
      // チームに所属していない場合はチーム作成へ
      if (!loginUser.memberships || loginUser.memberships.length === 0) {
        navigate('/teams/create');
        return;
      }

      // チームに所属しているが、１つも参加していない場合は、参加ナシページへ
      if (!loginUser.memberships.some(membership => membership.isJoined)) {
        navigate('/teams/not-joined');
        return;
      }

      // currentなチームがない場合
      if (!loginUser.memberships.some(membership => membership.isCurrent)) {
        // join済みのmembershipsを取得
        const joinedMemberships = loginUser.memberships.filter(membership => membership.isJoined);
        // currentなチームがない場合
        if (joinedMemberships.length === 1) {
          // 所属チームが１つだけならそのチームをcurrentとして、元の処理を続行
          apiAxios.put(`/memberships/${loginUser.memberships[0].id}/current`)
          dispatch(changeCurrent(loginUser.memberships[0]));
        }  else {
          // チームが複数あるならチーム選択画面へ
          navigate('/teams/select');
        }
      }
    }
  }, [loginUser, navigate]);

  if (!loginUser) {
    return <></>
  }

  return <Outlet />;
};

export default TeamMembershipGuard;
