import { ReactRenderer } from "@tiptap/react";
import { AIModelConst, AIModelConsts } from '@/components/features/aiModel/aiModel.constant';
import { SuggestionOptions } from '@tiptap/suggestion'
import tippy, { GetReferenceClientRect } from 'tippy.js';
import MentionList, { MentionListRef } from '@/components/features/thread/components/MessageForm/MentionList';
import { AIModel } from "../../thread.type";
import { isThreadDetailPage } from "@/lib/utils";

const activeAiModelConsts = AIModelConsts.filter(model => !model.deprecated);

export type MentionedAiModelsAtLastThreadCreation = {
  aiModels: AIModel[],
};
export type MentionItem = {
  kind: 'aiModelConst' | 'mentionedAiModelsAtLastThreadCreation',
  aiModelConst?: AIModelConst
  aiModelConsts?: AIModelConst[],
}

export const getSuggestion = (
  aiModelCodes: string[] | undefined = undefined,
  mentionedAiModelCodesAtLastThreadCreation: string[] | undefined = undefined
): Omit<SuggestionOptions<any>, "editor"> => {
  // メンションデータを作成
  const mentionItems: MentionItem[] = [];
  const selectableAiModelConsts = aiModelCodes ? activeAiModelConsts.filter(item => aiModelCodes.includes(item.id)) : activeAiModelConsts;
  if (mentionedAiModelCodesAtLastThreadCreation) {
    mentionItems.push({
      kind: 'mentionedAiModelsAtLastThreadCreation',
      aiModelConsts: activeAiModelConsts.filter(
        item => mentionedAiModelCodesAtLastThreadCreation.includes(item.id)
      ),
    });
  }
  mentionItems.push(...selectableAiModelConsts.map(aiModelConst => ({
    kind: 'aiModelConst' as const,
    aiModelConst,
  })));

  return {
    items: ({ query }: { query: string }) => {
      return mentionItems.filter((item) => {
        if (item.kind === 'aiModelConst') {
          return item.aiModelConst?.name.toLowerCase().includes(query.toLowerCase());
        }

        // スレッド詳細ページでは前回スレッドは出さない
        if (
          item.kind === 'mentionedAiModelsAtLastThreadCreation' &&
          isThreadDetailPage()
        ) {
          return false;
        }

        return query === '';
      })
    },
    render: () => {
      let component: ReactRenderer<MentionListRef> | null = null;
      let popup: any | null = null;

      return {
        onStart: (props) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
            maxWidth: '400px',
          })
        },
        onUpdate(props) {
          if (!component) {
            return
          }
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },
        onKeyDown(props) {
          if (!component?.ref) {
            return false
          }

          if (props.event.key === 'Escape') {
            popup[0].hide()

            return true
          }

          return component.ref.onKeyDown(props)
        },
        onExit() {
          if (!popup) {
            return
          }

          popup[0].destroy()

          if (!component) {
            return
          }
          component.destroy()
        },
      }
    },
  }
}


export default getSuggestion;
