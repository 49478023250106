import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import { News } from '../news.type';
import {
  CircularProgress,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { displayTimestamp } from '@/lib/utils';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { NavLink } from 'react-router-dom';

interface NewsListPresenterProps {
  newsList: News[];
  onScroll: () => void;
  onDelete: (news: News) => void;
  hasMore: boolean;
}

const SvNewsListPresenter: React.FC<NewsListPresenterProps> = ({
  newsList,
  onScroll,
  onDelete,
  hasMore,
}) => {
  return (
    <>
      <SupervisorPageTitle title="お知らせ管理" />
      <Divider />
      <SupervisorContentsWrapper id="scrollableDiv">
        <Box display="flex" justifyContent="flex-start" mb={2}>
          <Button
            variant="contained"
            color="primary"
            component={NavLink}
            to="/supervisor/news/new"
          >
            新規作成
          </Button>
        </Box>
        <InfiniteScroll
          dataLength={newsList.length}
          next={onScroll}
          hasMore={hasMore}
          loader={<CircularProgress />}
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>タイトル</TableCell>
                  <TableCell>タイプ</TableCell>
                  <TableCell>公開日時</TableCell>
                  <TableCell>作成日時</TableCell>
                  <TableCell>重要?</TableCell>
                  <TableCell>非表示?</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newsList.map((news) => (
                  <TableRow key={news.id}>
                    <TableCell>{news.id}</TableCell>
                    <TableCell>
                      <NavLink to={`/supervisor/news/${news.id}/edit`}>
                        {news.title}
                      </NavLink>
                    </TableCell>
                    <TableCell>{
                      news.type == 'internal' ? '内部コンテンツ' : '外部リンク'
                    }</TableCell>
                    <TableCell>{displayTimestamp(news.publishedAt)}</TableCell>
                    <TableCell>{displayTimestamp(news.createdAt)}</TableCell>
                    {/* <TableCell>
                      {news.isImportant && (
                        <Tooltip title="重要">
                          <PriorityHighIcon color="error" fontSize="small" />
                        </Tooltip>
                      )}
                      {news.isHidden && (
                        <Tooltip title="非表示">
                          <VisibilityOffIcon color="action" fontSize="small" />
                        </Tooltip>
                      )}
                    </TableCell> */}
                    <TableCell>{news.isImportant && '重要'}</TableCell>
                    <TableCell>{news.isHidden && '非表示'}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        component={NavLink}
                        to={`/supervisor/news/${news.id}/edit`}
                        sx={{ mr: 1 }}
                      >
                        編集
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onDelete(news)}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvNewsListPresenter;
