import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { displayDate, displayMonth, removeDecimalPart } from '@/lib/utils';
import { TeamWithSubscription } from '../../team/team.type';
import { SvTeamDetail } from '../../supervisor/teams/team.type';
import { SvTeamUsage } from '../../supervisor/usage/usage.type';
import { getSpecialCreditLabel } from '../usage.util';
import { PlanType } from '../../subscription/subscription.constant';

const SharedLatestUsageStatisticsPresenter: React.FC<{
  team: SvTeamDetail | TeamWithSubscription,
  hideCreditInfo?: boolean,
}> = ({
  team,
  hideCreditInfo = false
}) => {
  const usage = team.usage;
  const quota = team.quota;
  const currentSub = team.currentSubscription;
  const term = currentSub.latestMonthTerm;
  const isStarter = currentSub.plan.type === PlanType.STARTER;

  const isSvTeamDetail = (team: SvTeamDetail | TeamWithSubscription): boolean => {
    // team.usage.hasOwnProperty("totalJpy")だとlint errorになるため
    // TODO とはいえここまでやってLint Error回避するなら、このerrorをdisabledにしたほうがよいのでは？
    return Object.prototype.hasOwnProperty.call(team.usage, "totalJpy");
  }

  const svUsage = usage as SvTeamUsage;

  return (
    <>
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>対象期間</Typography>
        <Grid container spacing={2}>
          {
            isStarter ?
            <>
              <Grid item xs={6}>
                <Typography variant="body1">{displayDate(term[0])} 〜 </Typography>
              </Grid>
            </>
            :
            <>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">対象月</Typography>
                <Typography variant="body1">{displayMonth(term[0])}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">期間</Typography>
                <Typography variant="body1">{displayDate(term[0])} 〜 {displayDate(term[1])}</Typography>
              </Grid>
            </>
          }
        </Grid>
      </Paper>
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        { isStarter ?
          <>
            <Typography variant="h6" gutterBottom>投稿回数状況</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">利用率</Typography>
                <Typography variant="body1">{
                  quota.maxAiGenerateCount ?
                  (usage.aiGenerateCount / quota.maxAiGenerateCount * 100).toFixed(2) :
                  0.0
                }%</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">残り回数</Typography>
                <Typography variant="body1">
                  { quota.maxAiGenerateCount ?
                    <>
                      {quota.maxAiGenerateCount - usage.aiGenerateCount}
                      &nbsp;&nbsp;／&nbsp;&nbsp;
                      {quota.maxAiGenerateCount}
                    </> :
                    <></>
                  }
                </Typography>
              </Grid>
            </Grid>
          </> :
          <>
            <Typography variant="h6" gutterBottom>クレジット利用状況</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">利用率</Typography>
                <Typography variant="body1">{removeDecimalPart(team.creditUsageRate, 3)}%</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">残りクレジット</Typography>
                <Typography variant="body1">
                  { getSpecialCreditLabel(team.creditRemaining) ||
                    <>
                      {removeDecimalPart(team.creditRemaining)}
                      &nbsp;&nbsp;／&nbsp;&nbsp;
                      {removeDecimalPart(quota.totalCredit)}
                    </>
                  }
                  </Typography>
              </Grid>
            </Grid>
          </>
        }
      </Paper>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>利用回数</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>スレッド作成回数</TableCell>
                    <TableCell align="right">{usage.threadNum}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>メッセージ投稿回数</TableCell>
                    <TableCell align="right">{usage.postCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>AIからの回答回数</TableCell>
                    <TableCell align="right">{usage.aiGenerateCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>入出力文字数</Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>合計値</TableCell>
                    <TableCell align="right">{usage.inputCharsToAi + usage.outputChars}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>生成AIに入力した文字数</TableCell>
                    <TableCell align="right">{usage.inputCharsToAi}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>生成AIが出力した文字数</TableCell>
                    <TableCell align="right">{usage.outputChars}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {!hideCreditInfo && <>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>利用クレジット</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>合計値</TableCell>
                      <TableCell align="right">{
                        removeDecimalPart(usage.totalCredit, 3)
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>生成AIへの入力に使った値</TableCell>
                      <TableCell align="right">{removeDecimalPart(usage.inputCredit, 3)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>生成AIからの出力に使った値</TableCell>
                      <TableCell align="right">{removeDecimalPart(usage.outputCredit, 3)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>利用可能クレジット</Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>合計値</TableCell>
                      <TableCell align="right">{
                        getSpecialCreditLabel(quota.totalCredit) ||
                        removeDecimalPart(quota.totalCredit)
                      } / 月</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1ライセンスあたりの利用可能クレジット</TableCell>
                      <TableCell align="right">{
                        getSpecialCreditLabel(quota.licenseCredit) ||
                        removeDecimalPart(quota.licenseCredit)
                      } / 月</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>ライセンス数</TableCell>
                      <TableCell align="right">{quota.licenseAmount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </>}

        { isSvTeamDetail(team) && <>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>原価(日本円)</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>合計金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.totalJpy, 3)}円</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>入力に使った金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.inputJpy, 3)}円</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>出力に使った金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.outputJpy, 3)}円</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>原価(USドル)</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>合計金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.totalUsd, 3)}ドル</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>入力に使った金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.inputUsd, 3)}ドル</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>出力に使った金額</TableCell>
                        <TableCell align="right">{removeDecimalPart(svUsage.outputUsd, 3)}ドル</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </>
        }
      </Grid>
    </>
  );
};

export default SharedLatestUsageStatisticsPresenter;
