import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import { Thread } from '../thread.type';
import { List, ListItemButton, ListItemText, CircularProgress, Grid, ListItem, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ComponentWrapper } from '@/components/ui/StyledComponents';
import { UserMessageType } from '../thread.constant';
import { convertTiptapDataToText } from '@/common/utils/tiptap';
import { restoreMask } from '../thread.utils';
import { displayTimestamp } from '@/lib/utils';

interface ThreadListPresenterProps {
  threads: Thread[];
  onScroll: () => void;
  onThreadClick: (threadId: string) => void;
  hasMore: boolean;
}
const ThreadHistoryList = styled(List)`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 20px;
`;
const ThreadHistoryButton = styled(ListItemButton)`
`

const ThreadListPresenter: React.FC<ThreadListPresenterProps> = ({
  threads,
  onScroll,
  onThreadClick,
  hasMore
}) => {
  return (
    <ComponentWrapper id="scrollableDiv" style={{ height: '500px', overflow: 'auto' }}>
      {threads.length === 0 && !hasMore ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <Grid item>
            <Typography variant="h6" align="center" color="textSecondary">
              スレッドはまだ作成されていません。
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              新しいスレッドを作成して、会話を始めましょう。
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <InfiniteScroll
          dataLength={threads.length}
          next={onScroll}
          hasMore={hasMore}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <ThreadHistoryList>
            {threads.map((thread) => (
              <ThreadHistoryButton key={thread.id} onClick={() => onThreadClick(thread.id)}>
                <Grid container direction="column">
                  {/* タイトル */}
                  <Grid container direction="row">
                    <Grid item >
                      <ListItemText secondary={
                        displayTimestamp(thread.createdAt)
                      } />
                    </Grid>
                    <Grid item sx={{ paddingLeft: 3 }}>
                      <Grid container direction="column" >
                        <Grid item>
                          <ListItemText primary={
                            thread.title ?
                            restoreMask(thread.title, thread.dlpReplaces) :
                            "タイトルなし"
                          } />
                        </Grid>

                        <List>
                          {thread.messageNodes.map((messageNode) => {
                            if (messageNode.type == 'user' && messageNode.userMessage) {
                              const userMsg = messageNode.userMessage
                              let body = "";
                              if (userMsg.type == UserMessageType.NORMAL) {
                                body = convertTiptapDataToText(userMsg.body);
                              }
                              if (userMsg.type == UserMessageType.QUICK_PROMPT) {
                                body = Object.values(userMsg.body).map(value => {
                                  if (Array.isArray(value)) {
                                    return value.join(',');
                                  }
                                  return value;
                                }).join(' | ');
                              }
                              body = restoreMask(body, thread.dlpReplaces)
                              return (
                                <ListItem key={messageNode.id} sx={{"padding": 0}}>
                                  <Grid item sx={{"paddingRight": 1}}>
                                    <ListItemText secondary={<b>あなた</b>} />
                                  </Grid>
                                  <Grid item>
                                    {/* 100文字程度に短くする */}
                                    <ListItemText secondary={
                                      body.slice(0, 50) + (body.length > 50 ? '...' : "")
                                    } />
                                  </Grid>
                                </ListItem>
                              )
                            }
                            if (
                              messageNode.type == 'ai' &&
                              messageNode.aiMessages &&
                              messageNode.aiMessages.length > 0
                            ) {
                              return (
                                <ListItem key={messageNode.id} sx={{"padding": 0}}>
                                  <List>
                                    { messageNode.aiMessages.map((aiMsg) => {
                                        let body = aiMsg.body ?? "生成されませんでした";
                                        body = restoreMask(body, thread.dlpReplaces)
                                        return (
                                          <ListItem key={aiMsg.id} sx={{"padding": 0}}>
                                            <Grid item sx={{"paddingRight": 1}}>
                                              <ListItemText secondary={ <b>{aiMsg.version.aiModel.name}</b> } />
                                            </Grid>
                                            <Grid item>
                                              {/* 100文字程度に短くする */}
                                              <ListItemText secondary={ body?.slice(0, 50) + (body?.length > 50 ? '...' : "")} />
                                            </Grid>
                                          </ListItem>
                                        )
                                    })}
                                  </List>
                                </ListItem>
                              )
                            }

                            return <ListItem key={messageNode.id}></ListItem>
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ThreadHistoryButton>
            ))}
          </ThreadHistoryList>
        </InfiniteScroll>
      )}
    </ComponentWrapper>
  );
};

export default ThreadListPresenter;
