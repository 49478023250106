import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvTeamListPresenter from '../presenters/SvTeamListPresenter';
import { RootState } from '@/store';
import { SvTeam } from '../team.type';
import apiAxios from '@/lib/axios';
import { appendSvTeams, setSvTeams } from '../team.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';

const LIMIT = 20;

const SvTeamListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const teams = useSelector((state: RootState) => state.supervisor.team.teams);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState('');
  const { setUnknownErrorModal } = useErrorModal();

  const handleScroll = async (init: boolean = false, noKeyword: boolean = false) => {
    try {
      const response = await apiAxios.get<SvTeam[]>('/supervisor/teams', {
        params: {
          keyword: noKeyword ? "" : keyword,
          offset: init ? 0 : teams.length,
          limit: LIMIT,
        },
      });
      if (init) {
        dispatch(setSvTeams(response.data));
      } else {
        dispatch(appendSvTeams(response.data));
      }
      if (response.data.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('Failed to fetch more teams:', error);
    }
  };

  useEffect(() => {
    handleScroll(true);
  }, []);

  const handleSearch = () => {
    setHasMore(true);
    handleScroll(true);
  };

  const handleClear = () => {
    setHasMore(true);
    setKeyword("");
    handleScroll(true, true);
  };


  return (
    <SvTeamListPresenter
      teams={teams}
      onScroll={handleScroll}
      hasMore={hasMore}
      keyword={keyword}
      setKeyword={setKeyword}
      doSearch={handleSearch}
      clearSearchCondition={handleClear}
    />
  );
};

export default SvTeamListContainer;
