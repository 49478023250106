import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { ContentsWrapper, MainAreaWrapper } from '@/components/ui/StyledComponents';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { supervisorTheme } from '@/theme';
import styled from "@emotion/styled";
import { SvTeam } from '../team.type';

const SIDEBAR_WIDTH = 300;

export const ContentsWrapperWithLeftSidebar = styled(ContentsWrapper)`
  padding-left: ${SIDEBAR_WIDTH}px;
`;

type Props = {
  currentTeam: SvTeam;
};

const SvTeamLayoutPresenter: React.FC<Props> = ({ currentTeam }) => {
  const teamId = currentTeam.id;
  return (
    <ThemeProvider theme={supervisorTheme}>
      <ContentsWrapperWithLeftSidebar>
        {/* 左サイドバー */}
        <Drawer
          variant={"permanent"}
          open={true}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: supervisorTheme.palette.background.default,
              boxSizing: 'border-box',
              width: SIDEBAR_WIDTH,
              borderRight: 'none',
            },
          }}
        >
          <Toolbar />

          <List component="div" disablePadding>
            <NavLink to="/supervisor/teams/">
              <ListItemButton>
                <ListItemText primary={"一覧に戻る"} primaryTypographyProps={{ fontSize: '0.875rem' }} />
              </ListItemButton>
            </NavLink>
            <Divider/>
            <ListItem>
              <ListItemText
                primary={currentTeam.companyName || currentTeam.name}
                secondary={currentTeam.companyName ? currentTeam.name : null} />
            </ListItem>
            <Divider/>
            <NavLink to={`/supervisor/teams/${teamId}/summary`}>
              <ListItemButton>
                <ListItemText primary={"サマリー"} />
              </ListItemButton>
            </NavLink>
            <NavLink to={`/supervisor/teams/${teamId}/subscriptions`}>
              <ListItemButton>
                <ListItemText primary={"契約管理"} />
              </ListItemButton>
            </NavLink>
            <NavLink to={`/supervisor/teams/${teamId}/latest-usage-statistics`}>
              <ListItemButton>
                <ListItemText primary={"最新の利用状況"} />
              </ListItemButton>
            </NavLink>
            <NavLink to={`/supervisor/teams/${teamId}/usage-reports`}>
              <ListItemButton>
                <ListItemText primary={"利用状況レポート"} />
              </ListItemButton>
            </NavLink>
            <NavLink to={`/supervisor/teams/${teamId}/quick-prompts/bookmarks`}>
              <ListItemButton>
                <ListItemText primary={"お気に入りQP管理"} />
              </ListItemButton>
            </NavLink>
            <NavLink to={`/supervisor/teams/${teamId}/logo-customization`}>
              <ListItemButton>
                <ListItemText primary={"ロゴカスタマイズ"} />
              </ListItemButton>
            </NavLink>
          </List>
        </Drawer>

        {/* メインコンテンツ */}
        <MainAreaWrapper>
          <Outlet />
        </MainAreaWrapper>
      </ContentsWrapperWithLeftSidebar>
    </ThemeProvider>
  );
};

export default SvTeamLayoutPresenter;
