import React from 'react';
import { Editor } from "@tiptap/react";
import { IconButton } from '@mui/material';
import MentionIcon from '@mui/icons-material/AlternateEmail';

const MentionButton: React.FC<{ editor: Editor }> = ({ editor }) => {
  const onClick = () => {
    // [Notice]
    // メンションボタンの場合はメンションを確実に出すために、
    // @の前に文字がある場合は、空白をいれるようにする
    // ※ 前提として、@を入力してもその手前に文字があるとメンションは機能しない。
    //   (これはslackなども同じなのでバグとはしない)

    const { selection } = editor.state;
    const { empty, $anchor } = selection;

    if (!empty) {
      // 選択範囲がある場合は、選択範囲を削除
      editor.commands.deleteSelection();
    }

    // 現在のカーソル位置の直前の文字を取得
    const prevChar = $anchor.nodeBefore?.text?.slice(-1);

    // 直前の文字が空白文字でない場合は、スペースを挿入
    if (prevChar && !/\s/.test(prevChar)) {
      editor.commands.insertContent(' ');
    }

    editor.chain().focus().insertContent('@').run();
    editor.commands.command(({ tr }) => {
      tr.setMeta('mention', { open: true });
      return true;
    });
  };

  return (
    <IconButton color="primary" onClick={onClick}
      className={editor.isActive('mention') ? 'is-active' : ''}>
      <MentionIcon />
    </IconButton>
  );
};

export default MentionButton;

