import React from 'react';
import { Box, Typography } from '@mui/material';
import { RightSideBarWrapper } from '@/components/ui/StyledComponents';
import { TeamWithSubscription } from '../../team/team.type';
import { ThreadWithUsage } from '../thread.type';
import { displayTimestamp, formatDecimal } from '@/lib/utils';
import LatestNewsPanel from '../../news/components/LatestNewsPanel';

const calcUsageStartDateStr : string = import.meta.env.VITE_CALC_USAGE_START_DATE;
const calcUsageStartDate : Date = new Date(calcUsageStartDateStr);

interface Props {
  thread?: ThreadWithUsage | null;
  team: TeamWithSubscription;
  isUserAdmin: boolean;
}

const ThreadRightSidebarPaidPlanPresenter: React.FC<Props> = ({thread}) => {
  if (!thread) {
    return (
      <RightSideBarWrapper
        sx={{
          paddingLeft: "10px !important",
          paddingRight: "10px !important",
          overflowY: "auto",
          maxHeight: "100vh"
        }}
      >
        <Box mb={4}>
          <LatestNewsPanel />
        </Box>
      </RightSideBarWrapper>
    );
  }

  // スレッドを作成したのが集計開始以前の場合は
  // 統計情報を表示しない
  if (new Date(thread.createdAt) < calcUsageStartDate) {
    return <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="body2" color="text.secondary">
          正式リリース前に作成されたスレッドのため、詳細情報の表示はできません。
        </Typography>
      </Box>
    </RightSideBarWrapper>;
  }

  const usage = thread.usage;

  return (
    <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          スレッド情報
        </Typography>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            あなたの投稿
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {usage.postCount}回<br/>
            (合計{usage.inputCharsInView}文字)
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            AIによる回答
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {usage.aiGenerateCount}回<br/>
            (合計{usage.outputChars}文字)
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            消費クレジット
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDecimal(usage.totalCredit)} クレジット
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            作成日
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayTimestamp(thread.createdAt)}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="subtitle2" color="primary">
            更新日
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {displayTimestamp(thread.updatedAt)}
          </Typography>
        </Box>
      </Box>
    </RightSideBarWrapper>
  );
};

export default ThreadRightSidebarPaidPlanPresenter;
