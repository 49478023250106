import React, { useEffect } from 'react';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Divider, Grid, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';

declare global {
  interface Window {
    hbspt: any; // `any`型を使用していますが、より具体的な型定義に置き換えることが可能です。
  }
}
const UpgradeContactForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "41556612",
          formId: "5efbd4e5-b24f-405d-8caa-c10967dd9228",
          target: '#hubSpotForm'
        });
      }
    });
  }, []);

  return (
    <>
      <AdminPageTitle title="アップグレードのお問い合わせ" />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
          アップグレードに関するお問い合わせは以下のフォームよりお願いいたします。<br/>
          お問い合わせいただいた内容については、担当者よりご連絡いたします。
        </Typography>
        <Typography variant="caption" color="textSecondary" style={{ display:"block", marginBottom: '25px' }}>
          ※ フォームが表示されない場合はページを再読み込みしてください
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <div id="hubSpotForm" className="hubSpotForm"></div>
          </Grid>
        </Grid>
      </AdminContentsWrapper>
    </>
  );
};

export default UpgradeContactForm;
