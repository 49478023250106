import React from 'react';
import { useContext } from 'react';
import { ToastContext } from '../contexts/ToastContext';
import ToastPresenter from '../presenters/ToastPresenter';

const ContextToastContainer: React.FC = () => {
  const { isOpen, message, severity, hideToast } = useContext(ToastContext);

  return <ToastPresenter isOpen={isOpen} message={message} severity={severity} onClose={hideToast} />;
};

export default ContextToastContainer;
