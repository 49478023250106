import { RootState } from "@/store";
import { Avatar, Box, Checkbox, Chip, InputLabel, MenuItem, Select } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";
import { useSelector } from "react-redux";
import { getCurrentMembership } from "../../auth/auth.type";
import { AIModelConsts } from "../../aiModel/aiModel.constant";
import AIModelListItem from "../../aiModel/components/AIModelListItem";

const SelectAiModelCodes = (props: WidgetProps) => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser)

  if (!membership) {
    return <></>
  }

  const usableAiModelCodes = membership.team.quota.usableAiCodes || []
  const usableAiModelConsts = AIModelConsts.filter((model) => {
    return usableAiModelCodes.includes(model.id) && !model.deprecated
  });

  // 全てのAIモデルが同じタグを持っているか
  const isAllAiModelHasSameTags = usableAiModelConsts.length > 0 &&
    usableAiModelConsts.every(model =>
      model.tags.length === usableAiModelConsts[0].tags.length &&
      model.tags.every(tag => usableAiModelConsts[0].tags.includes(tag))
    );

  return (
    <>
      <InputLabel id="select-ai-model-codes">{props.label}</InputLabel>
      <Select
        label={props.label}
        labelId="select-ai-model-codes"
        id={props.id}
        multiple
        value={props.value}
        required={props.required}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        error={props.rawErrors ? props.rawErrors.length > 0 : false}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value: string) => {
              const aiModel = AIModelConsts.find((item) => item.id == value)
              return <Chip
                key={value}
                label={aiModel?.name}
                avatar={<Avatar alt={aiModel?.name} src={aiModel?.avatar} />}
                variant="outlined"
              />
            })}
          </Box>
        )}
      >
        {usableAiModelConsts.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={props.value.indexOf(item.id) > -1} />
            <AIModelListItem
              aiModel={item}
              isAllAiModelHasSameTags={isAllAiModelHasSameTags}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectAiModelCodes;