import React from 'react';
import { RootState } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from '../toast.slice';
import ToastPresenter from '../presenters/ToastPresenter';

const StateToastContainer: React.FC = () => {
  const toastContent = useSelector((state: RootState) => state.toast.content);
  const dispatch = useDispatch();

  const isOpen = !!toastContent;
  const hideToastHandler = () => {
    dispatch(hideToast());
  };
  const message = toastContent ? toastContent.message : '';
  const severity = toastContent ? toastContent.severity : undefined;

  return <ToastPresenter isOpen={isOpen} message={message} severity={severity} onClose={hideToastHandler} />;
};

export default StateToastContainer;
