import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import {
  CircularProgress,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { displayTimestamp } from '@/lib/utils';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { FileUploadWithMembership } from '../file.type';
import { formatBytes } from '../file.utils';
import FileDownloadButton from '../components/FileDownloadButton';

interface FileListPresenterProps {
  files: FileUploadWithMembership[];
  onScroll: () => void;
  hasMore: boolean;
}

const FileListPresenter: React.FC<FileListPresenterProps> = ({
  files,
  onScroll,
  hasMore,
}) => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return null;
  }

  return (
    <>
      <AdminPageTitle title="VAULT ファイルアップロード履歴" />
      <Divider />
      <AdminContentsWrapper id="scrollableDiv">

        <Typography variant="body2" color="textSecondary" sx={{mb: 5}}>
          各メンバーがアップロードしたファイルを確認することができます。
        </Typography>

        <InfiniteScroll
          dataLength={files.length}
          next={onScroll}
          hasMore={hasMore}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>ファイル名</TableCell>
                  <TableCell>タイプ</TableCell>
                  <TableCell>サイズ</TableCell>
                  <TableCell>アップロード者</TableCell>
                  <TableCell>アップロード日時</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.id}>
                    <TableCell>{file.id}</TableCell>
                    <TableCell style={{ maxWidth: '200px'}}>
                      {file.filename}
                    </TableCell>
                    <TableCell style={{ maxWidth: '200px'}}>
                      {file.mimetype}
                    </TableCell>
                    <TableCell style={{ maxWidth: '200px'}}>
                      {formatBytes(file.filesize)}
                    </TableCell>
                    <TableCell>
                      {
                        file.membership ?
                          <>
                            {file.membership.user.name}<br/>
                            <Typography variant='caption'>({file.membership.user.email})</Typography>
                          </> :
                          "メンバー削除済"
                      }
                    </TableCell>
                    <TableCell>
                      {displayTimestamp(file.createdAt)}
                    </TableCell>
                    <TableCell>
                      <FileDownloadButton file={file} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </AdminContentsWrapper>
    </>
  );
};

export default FileListPresenter;
