import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { SvTeamDetail } from '../team.type';
import { captureException } from '@sentry/react';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import apiAxios from '@/lib/axios';
import { clearCurrentTeam, setCurrentTeam } from '../team.slice';
import SvTeamLayoutPresenter from '../presenters/SvTeamLayoutPresenter';

const SvTeamLayoutContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams<{ teamId: string }>();
  const currentTeam = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  const { setUnknownErrorModal } = useErrorModal();

  useEffect(() => {
    const fetchTeam = async () => {
      // URLでチームIDが指定されてる場合
      if (teamId) {
        // 既に選択チームがある場合
        if (currentTeam) {
          if (currentTeam.id === teamId) {
            // チームに関連するデータを復元する処理（必要に応じて実装）
          } else {
            // もし違うチームが選択されてる場合は選択解除
            // (useEffectがもう一度走る)
            dispatch(clearCurrentTeam());
          }
          return
        }

        // 現在チームがない場合は、チームIDのチームを取得して、それを現在チームにセットする。
        try {
          const response = await apiAxios.get<SvTeamDetail>(`/supervisor/teams/${teamId}`);
          dispatch(setCurrentTeam(response.data));
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to fetch sb team:', error);
        }
        return
      }
      // URLでチームIDが指定されていないのに、現在チームがある場合はクリアする
      if (currentTeam) {
        dispatch(clearCurrentTeam());
      }
    };

    fetchTeam();
  }, [teamId, currentTeam, dispatch]);

  if (!currentTeam) {
    return null;
  }

  return <SvTeamLayoutPresenter currentTeam={currentTeam} />;
};

export default SvTeamLayoutContainer;