import { AdminContentsWrapper } from '@/components/features/admin/components/AdminUI';
import { isSuperAdmin } from '@/components/features/auth/auth.type';
import { ADMIN_MANUAL_URL } from '@/config';
import { RootState } from '@/store';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const AdminIndexPage = () => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  if (!loginUser) {
    return;
  }

  return (
    <AdminContentsWrapper>
      <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-line', marginBottom: 5 }}>
        こちらは管理者限定の機能となります。<br/>
        { isDesktop ? 'サイドバーからメニューをご選択ください。' : '左上のメニューアイコンから機能をご選択ください。'}
      </Typography>

      { isSuperAdmin(loginUser) && <>
        <Typography variant="subtitle1" sx={{ whiteSpace: 'pre-line', marginBottom: 5, display: 'flex', alignItems: 'center' }}>
          <VerifiedUserIcon fontSize="small" sx={{ mb: '2px', color: 'green' }} />がついているメニューは、特権管理者専用となっております。
        </Typography>
      </>}

      <Typography variant="subtitle1">
        不明点などありましたら、<a
          href={ADMIN_MANUAL_URL}
          target="_blank"
          rel="noopener noreferrer"
        >管理者マニュアル</a>をご参照ください。
      </Typography>

    </AdminContentsWrapper>
  );
};

export default AdminIndexPage;
