export enum PlanType {
  STARTER = 'starter',
  LITE = 'lite',
  BUSINESS = 'business',
  ENTERPRISE = 'enterprise',
  DELETE_SCHEDULED = 'delete_scheduled'
}

export const getPlanTypeLabel = (planType: PlanType): string => {
  switch (planType) {
    case PlanType.STARTER:
      return 'スタータープラン';
    case PlanType.LITE:
      return 'ライトプラン';
    case PlanType.BUSINESS:
      return 'ビジネスプラン';
    case PlanType.ENTERPRISE:
      return 'エンタープライズプラン';
    case PlanType.DELETE_SCHEDULED:
      return '削除予定';
    default:
      return '';
  }
};
