import React, { createContext, useState } from 'react';

type ConfirmModalContextProps = {
  isOpen: boolean;
  title: string;
  message: string;
  confirmLabel: string;
  cancelLabel: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  setConfirmModal: (title: string, message: string, onConfirm?: () => void, onCancel?: () => void) => void;
  closeConfirmModal: () => void;
};

export const ConfirmModalContext = createContext<ConfirmModalContextProps>({
  isOpen: false,
  title: '',
  message: '',
  confirmLabel: 'OK',
  cancelLabel: 'キャンセル',
  setConfirmModal: () => {},
  closeConfirmModal: () => {},
});

export const ConfirmModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('OK');
  const [cancelLabel, setCancelLabel] = useState('キャンセル');

  const [onConfirm, setOnConfirm] = useState<(() => void) | undefined>(undefined);
  const [onCancel, setOnCancel] = useState<(() => void) | undefined>(undefined);

  const setConfirmModal = (
    title: string,
    message: string,
    onConfirm?: () => void,
    onCancel?: () => void,
    confirmLabel: string = 'OK',
    cancelLabel: string = 'キャンセル',
  ) => {
    setTitle(title);
    setMessage(message);
    setConfirmLabel(confirmLabel);
    setCancelLabel(cancelLabel);
    setOnConfirm(() => onConfirm);
    setOnCancel(() => onCancel);
    setIsOpen(true);
  };

  const closeConfirmModal = () => {
    setIsOpen(false);
  };

  return (
    <ConfirmModalContext.Provider value={{ isOpen, title, message, confirmLabel, cancelLabel, onConfirm, onCancel, setConfirmModal, closeConfirmModal }}>
      {children}
    </ConfirmModalContext.Provider>
  );
};
