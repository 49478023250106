import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '@/store';
import { fetchNewsById, updateNews } from '../news.slice';
import SvNewsForm from '../components/SvNewsForm';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { Divider } from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { NewsInput } from '../news.type';

const SvNewsUpdateContainer: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { currentNews, loading } = useSelector((state: RootState) => state.supervisor.news);

  useEffect(() => {
    if (id) {
      dispatch(fetchNewsById(id));
    }
  }, [dispatch, id]);

  const handleSubmit = async (data: NewsInput) => {
    await dispatch(updateNews(data));
    navigate('/supervisor/news');
  };

  if (loading || !currentNews) return <div>Loading...</div>;

  return (
    <>
      <SupervisorPageTitle title="お知らせ更新" />
      <Divider />
      <SupervisorContentsWrapper id="scrollableDiv">
        <SvNewsForm onSubmit={handleSubmit} defaultValues={currentNews} />
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvNewsUpdateContainer;
