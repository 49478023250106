import React from 'react';
import { MessageNode } from '../thread.type';
import styled from '@emotion/styled';
import { MessageType } from '../thread.constant';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import ThreadPostUserMessage from './ThreadPostUserMessage';
import ThreadPostAiMessages from './ThreadPostAiMessages';

interface ThreadPostContainerProps {
  node: MessageNode;
}

export const PostInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;


const ThreadPost: React.FC<ThreadPostContainerProps> = ({node}) => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);

  if (!loginUser || !currentThread) return <></>;

  if (node.type == MessageType.USER) {
    const userMsg = node.userMessage;
    if (!userMsg) return <></>;
    return <ThreadPostUserMessage userMsg={userMsg} node={node} />;
  }

  if (node.type == MessageType.AI) {
    return <ThreadPostAiMessages node={node} />;
  }

  return <></>

}

export default ThreadPost;
