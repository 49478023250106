import { useSelector } from "react-redux";
import { useErrorModal } from "../../generic/hooks/useErrorModal";
import { RootState } from "@/store";
import { getCurrentSubscription, isAdmin } from "../../auth/auth.type";
import { PlanType } from "../../subscription/subscription.constant";
import { QuotaCheckResult } from "../../usage/usage.type";
import { PLAN_UPGRADE_URL } from "@/common/constants";

export const useThreadAlertModal = () => {
  const { setErrorModal } = useErrorModal();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const isUserAdmin = isAdmin(loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  const isStarter = currentSub?.plan.type === PlanType.STARTER

  const showQuotaExceededModal = (data: QuotaCheckResult) => {
    let title : string | undefined;
    let content : string | undefined;
    let buttonTitle : string | undefined;
    let buttonUrl : string | undefined;
    let onCloseCallback : (() => void) | undefined

    if (data.isMaxAiGenerateCountExceeded) {
      if (isStarter) {
        title = "スタータープランの利用可能上限に達しました"
        content = "大変恐縮ですが、スタータープランの利用可能上限に達しました。\n\n" +
        `現在の利用回数: ${data.usage.aiGenerateCount}\n` +
        `利用可能上限: ${data.quota.maxAiGenerateCount}\n` +
        `この投稿で使われる回数: ${data.postedMentionsCount}\n\n` +
        (
          data.isMaxAiGenerateCountAlreadyExceeded ?
          "これ以上使うにはプランのアップグレードが必要となります。\n\n" :
          "メンションの数を減らすことで投稿が可能になりますので、\nご対応いただけますと幸いです。\n\n"
        ) +
        "プランをアップグレードすることで、より沢山を利用でき、また高度なAIも利用可能になります。ぜひご検討ください。"
      } else {
        title = "投稿回数上限に達しました"
        content = "大変恐縮ですが、投稿回数上限に達しました。\n\n" +
        `現在の利用回数: ${data.usage.aiGenerateCount}\n` +
        `利用可能上限: ${data.quota.maxAiGenerateCount}\n` +
        `この投稿で使われる回数: ${data.postedMentionsCount}\n` +
        `※ メンションの数だけカウントされます。\n` +
        (
          data.isMaxAiGenerateCountAlreadyExceeded ?
          "これ以上お使いになるにはプランのアップグレードが必要となります。是非ご検討ください。" :
          "メンションの数を減らすことで投稿が可能になりますので、\nご対応いただけますと幸いです。\n\n"
        )
      }
      if (isUserAdmin) {
        buttonTitle = "アップグレード申し込み"
        buttonUrl = PLAN_UPGRADE_URL
      }
    }

    if (data.isMaxInputCharsLengthExceeded) {
      if (isStarter) {
        title = "スタータープランの文字数制限について"
        content = `大変恐縮ですが、スタータープランでは1回あたり${data.quota.maxInputCharsLength}文字まで入力可能となっております。` +
        `(現在の合計入力文字数: ${data.checkText?.length})\n\n` +
        "プランをアップグレードすることで、より沢山を利用でき、また高度なAIも利用可能になります。ぜひご検討ください。"
        if (isUserAdmin) {
          buttonTitle = "アップグレード申し込み"
          buttonUrl = PLAN_UPGRADE_URL
        }
      } else {
        title = "文字数制限をオーバーしました"
        content = "大変恐縮ですが、文字数上限をオーバーしました。\n\n" +
          `文字数上限: ${data.quota.maxInputCharsLength}\n` +
          `入力文字数: ${data.checkText?.length}\n\n` +
          "入力文字数を減らして再投稿いただけますよう何卒よろしくお願いいたします。"

      }
    }

    if (data.isMaxInputCharsLengthExceededByMemberOver) {
      title = "現在、制限がかかっております"
      content = `現在、メンバー数超過により${data.maxInputCharsLengthWhenOverMember}文字制限がかかっております。\n`
      if (isUserAdmin) {
        content += "メンバー管理画面よりメンバーを減らすか、ライセンスの追加をご検討ください。"
      } else {
        content += "詳しくは管理者にお問い合わせください。"
      }
    }

    if (data.isCreditAlreadyExceeded) {
      if (data.isCreditAlreadyExceeded) {
        title = "チームの保有クレジット数をオーバーしました"
        content = "大変恐縮ですが、保有クレジット数の上限に達しました。\n\n" +
          "これ以上お使いになるにはプランのアップグレードが必要となります。是非ご検討ください。"
      }
      if (data.isCreditWillExceeded) {
        title = "チームの保有クレジット数をオーバーします"
        content = "この投稿をすると、保有クレジット数をオーバーしてしまいます。\n" +
          "メンション数を減らす、文字数を減らす、新規スレッドではじめる等で投稿できる可能性がございます。\n" +
          "またプランのアップグレードでより多くのクレジットを利用できるようになります。是非ご検討ください。"
      }
      if (isUserAdmin) {
        buttonTitle = "アップグレード申し込み"
        buttonUrl = PLAN_UPGRADE_URL
      }
    }

    if (data.isAiCodeUnusable) {
      title = "不適切なメンションです"
      content = '現在のプランでは使えない生成AIモデルに対してメンションしています。画面をリロードして適切なモデルを選択してください。'
    }

    if (!title || !content) {
      return
    }

    setErrorModal(
      title,
      content,
      (
        buttonTitle && buttonUrl ? { title: buttonTitle, url: buttonUrl } : undefined
      ),
      onCloseCallback,
    );
  };

  return {
    showQuotaExceededModal,
  };
};
