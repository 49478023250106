import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import "github-markdown-css/github-markdown-light.css";

const StyledMarkdownPreview = styled(Box)`
  pre {
    padding: 10px;
    border-radius: 4px;
  }

  pre:has(code) {
    background: #2d2d2d;
  }

  h1, h2 {
    // 下線は表示しない
    border-bottom: none;
    padding-bottom: 0;
  }
`;

interface CustomMarkdownProps {
  markdown?: string | null;
}

const CustomMarkdown: React.FC<CustomMarkdownProps> = ({ markdown }) => {
  return (
    <StyledMarkdownPreview>
      <Markdown
        className="markdown-body"
        children={markdown}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{
          code(props) {
            const { children, className, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter
                {...rest}
                ref={undefined} // 文法エラー回避のため
                PreTag="div"
                children={String(children).replace(/\n$/, '')}
                language={match[1]}
                style={a11yDark}
              />
            ) : (
              <>
                {children}
              </>
            );
          },
          a(props) {
            return <a {...props} target="_blank" rel="noopener noreferrer" />;
          },
        }}
      />
    </StyledMarkdownPreview>
  );
};

export default CustomMarkdown;
