import React, { FormEvent, useEffect, useState } from 'react';
import { ThreadCreateType } from '../../thread/thread.type';
import { QuickPromptWithVersion, QuickPromptWithSetAndVersion } from '../quickPrompt.type';
import { IChangeEvent } from '@rjsf/core';
import useThreadApi from '../../thread/hooks/useThreadApi';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { handleDlpCheck } from '../../thread/thread.utils';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';
import { useThreadAlertModal } from '../../thread/hooks/useThreadAlertModal';
import apiAxios from '@/lib/axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuickPrompt } from '../quickPrompt.slice';
import { RootState } from '@/store';
import { RECOMMEND_QP_INFO_LIST } from '../quickPrompt.constant';
import QpFormPresenter from '../presenters/QpFormPresenter';
import { AIModelConsts } from '../../aiModel/aiModel.constant';

const OPTIONS_DISPLAY = 'optionsDisplay';

interface QpFormContainerProps {
  quickPromptId: string;
  versionId?: string;
  input?: { [key: string]: unknown };
  hideSendButton?: boolean;
  formChangedCallback?: (formData: object) => void;
}

const QpFormContainer: React.FC<QpFormContainerProps> = ({
  quickPromptId,
  versionId,
  input,
  formChangedCallback,
  hideSendButton = false,
}) => {
  const dispatch = useDispatch();
  const { createThread } = useThreadApi();
  const { setErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const [formData, setFormData] = useState<object>({});
  const [optionOpened, setOptionOpened] = useState(false);
  const quickPromptSets = useSelector((state: RootState) => state.quickPrompt.quickPromptSets);
  const threadOnGenerating = useSelector((state: RootState) => state.thread.threadOnGenerating);
  const [selectedQuickPrompt, setSelectedQuickPrompt] = useState<QuickPromptWithVersion | undefined>(undefined);
  const { showQuotaExceededModal } = useThreadAlertModal();
  const queryParams = new URLSearchParams(window.location.search);
  const recommendValue = queryParams.get('recommend');

  const setDefaultFormData = (qp: QuickPromptWithVersion) => {
    // propsでinputが渡されてる場合(編集の場合)はそれをそのままセット
    if (input) {
      setFormData(input);
      setOptionOpened(input[OPTIONS_DISPLAY] as boolean || false);
      return;
    }

    // 上記以外の場合は新規登録
    let baseInput: { [key: string]: unknown } = {};

    // 最初の要素名
    let firstProp: string | null = null;
    const version = qp.targetVersion;
    const uiSchema = version.uiSchema;
    if (uiSchema && 'ui:order' in uiSchema) {
      const order = uiSchema['ui:order'] as string[];
      if (order.length > 0) {
        firstProp = order[0];
      }
    }

    // 最新の入力があればそれを使う
    if (qp.latestInput) {
      baseInput = qp.latestInput as { [key: string]: unknown };

      // 最初の要素は空にする
      if (firstProp) {
        baseInput = { ...baseInput, [firstProp]: '' };
      }

      // AIモデルは非推奨になったものは除外
      if (baseInput['aiModelCodes'] && Array.isArray(baseInput['aiModelCodes'])) {
        const inputtedAiModels = baseInput['aiModelCodes'] as string[];
        const validAiModels = AIModelConsts.filter(model => !model.deprecated).map(model => model.id);
        baseInput['aiModelCodes'] = inputtedAiModels.filter(modelId => validAiModels.includes(modelId));
      }

    } else if (recommendValue && firstProp) {
      // おすすめから飛んだ場合はサンプルを表示
      RECOMMEND_QP_INFO_LIST.forEach((rqp) => {
        if (rqp.code === qp.officialCode) {
          baseInput = { ...baseInput, [firstProp]: rqp.sample };
        }
      });
    }

    setFormData(baseInput);
    setOptionOpened(baseInput[OPTIONS_DISPLAY] as boolean || false);
  };

  useEffect(() => {
    const fetch = async () => {
      const path = `/quick-prompts/${quickPromptId}${versionId ? `?versionId=${versionId}` : ''}`;
      const response = await apiAxios.get<QuickPromptWithSetAndVersion>(path);
      console.debug(`${path} response`, response);
      const updatedQuickPrompt = response.data;
      // バージョンIDが指定されてる場合はセットしない
      if (!versionId) {
        dispatch(updateQuickPrompt(updatedQuickPrompt));
      }
      setSelectedQuickPrompt(updatedQuickPrompt);
      setDefaultFormData(updatedQuickPrompt);
    };

    // IDを指定されていない場合は終了
    if (!quickPromptId) {
      return;
    }

    // 既に対象として設定されてる場合は終了
    if (
      quickPromptId && selectedQuickPrompt &&
      selectedQuickPrompt.id == quickPromptId &&
      (
        !versionId || selectedQuickPrompt.targetVersion.id == versionId
      )
    ) {
      return;
    }

    // 既に一覧として読み込んでいる場合は、それをセットして終了
    const quickPrompts = quickPromptSets.map((qps) => qps.quickPrompts).flat();
    const quickPrompt = quickPrompts.find((qp) => qp.id === quickPromptId);
    if (
      quickPrompt && quickPrompt.latest_version &&
      (!versionId || quickPrompt.latest_version.id == versionId)
    ) {
      const qp = quickPrompt as QuickPromptWithVersion;
      setSelectedQuickPrompt(qp);
      setDefaultFormData(qp);
      return;
    }

    // 上記いずれでもない場合はAPIから取得
    fetch();
  }, [dispatch, quickPromptId]);


  if (!selectedQuickPrompt) {
    return <QpFormPresenter loading={true} />;
  }

  const qp = selectedQuickPrompt;

  // フォームの変更を監視する関数
  const handleFormChange = (data: IChangeEvent) => {
    setOptionOpened(data.formData[OPTIONS_DISPLAY]);
    setFormData(data.formData);
    formChangedCallback?.(data.formData as object);
  };

  const handleSendMessage = async (data: IChangeEvent, _: FormEvent) => {
    const postQuickPrompt = (skipDlpBlock: boolean = false) => {
      createThread(
        {
          type: ThreadCreateType.QUICK_PROMPT,
          quickPromptDetail: {
            versionId: qp.targetVersion.id,
            formInput: data.formData,
          },
          skipDlpBlock: skipDlpBlock,
        },
        {
          onThreadCreated: () => {
            const updatedQp = { ...qp, latestInput: data.formData };
            dispatch(updateQuickPrompt(updatedQp));
            setFormData({});
          },
          onBlockedByDlp: (result) => handleDlpCheck(result, postQuickPrompt, setErrorModal, setConfirmModal),
          onQuotaExceeded: (data) => showQuotaExceededModal(data.quotaCheckResult),
        }
      );
    };

    postQuickPrompt();
  };

  const showTrySend = !!(
    recommendValue && !qp.latestInput
  );

  return (
    <QpFormPresenter
      loading={false}
      qp={selectedQuickPrompt}
      formData={formData}
      onGenerating={threadOnGenerating}
      optionOpened={optionOpened}
      handleFormChange={handleFormChange}
      handleSendMessage={handleSendMessage}
      showTrySend={showTrySend}
      hideSendButton={hideSendButton}
    />
  );
};

export default QpFormContainer;
