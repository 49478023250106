import { FC } from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import useLoading from '../hooks/useLoading';

const LoadingIndicator: FC = () => {
  const { isLoading } = useLoading();

  console.debug("isLoading", isLoading)

  return (
    <Backdrop
      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress />
    </Backdrop>
  )
};

export default LoadingIndicator;
