import { FC, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/store';
import { setRedirectUrl, setUserInfo } from '../auth.slice';
import useLoading from '../../generic/hooks/useLoading';
import apiAxios from '@/lib/axios';

const AuthGuard: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const { setIsLoading } = useLoading();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const response = await apiAxios.get('/auth/profile');
        console.debug('/auth/profile response', response)
        dispatch(setUserInfo(response.data));
        setIsLoading(false);
      } catch (error) {
        console.debug('/auth/profile error', error)
        dispatch(setRedirectUrl(window.location.pathname));
        setIsLoading(false);
        navigate('/login');
      }
    };

    if (!isAuthenticated) {
      checkAuth();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, navigate, dispatch]);

  return isAuthenticated ? <Outlet /> : null;
};

export default AuthGuard;
