import React from 'react';
import { Box, Typography, Button, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonPanel from '@/components/ui/CommonPanel';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership } from '@/components/features/auth/auth.type';

const TeamsCompletedPage: React.FC = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return;
  }
  const team = getCurrentMembership(loginUser)?.team;
  if (!team) {
    return;
  }

  return (
    <CommonPanel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant="h4"  align="center" sx={{ mb: 2 }}>
          おめでとうございます🎉<br/>「チーム」が作成されました。
        </Typography>
        {/* <Typography variant="body1" align="center" sx={{ mb: 2 }}>
          (完了メールを送信しました)
        </Typography> */}

        <TableContainer sx={{
            minWidth: 300,
            mb: 4,
            border: '1px solid',
            borderColor: 'divider',
        }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">チーム名</TableCell>
                <TableCell>{team.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">チームID</TableCell>
                <TableCell>{team.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">特権管理者</TableCell>
                <TableCell>{loginUser.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
        >
          さっそくWorkAIzerを使ってみる
        </Button>
      </Box>
    </CommonPanel>
  );
};

export default TeamsCompletedPage;