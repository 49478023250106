import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { setUserInfo } from '../../auth/auth.slice';
import { LoginUser } from '../../auth/auth.type';
import InfoPanel from '@/components/ui/InfoPanel';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import CommonPanel from '@/components/ui/CommonPanel';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { AxiosError } from 'axios';

const termUrl = import.meta.env.VITE_TERM_PAGE_URL;
const privacyPolicyUrl = import.meta.env.VITE_PRIVACY_POLICY_PAGE_URL;

type TeamJoinProps = {
  teamId: string;
  inviteCode: string;
};

const TeamJoin: React.FC<TeamJoinProps> = ({ teamId, inviteCode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [agreed, setAgreed] = useState(false);
  const { setUnknownErrorModal, setErrorModal } = useErrorModal();

  if (!loginUser) {
    return <></>
  }

  const membership = loginUser.memberships.find((m) => m.team.id === teamId);


  if (!membership) {
    return (
      <InfoPanel title="不正な招待URL">
        ご入力いただいた招待URLは不正なURLです。<br/>
        今一度、メールで送られたURLをご確認ください。<br/>
        <br/>
        URLが正しい場合は、招待が解除された可能性がございます。<br/>
        あなたを招待した管理者にお問い合わせください。
      </InfoPanel>
    );
  }

  const handleJoinTeam = async () => {
    try {
      await apiAxios.put(`/memberships/${membership.id}/join`, { inviteCode });
      await apiAxios.put(`/memberships/${membership.id}/current`);
      const response = await apiAxios.get<LoginUser>('/auth/profile');
      dispatch(setUserInfo(response.data));
      navigate('/teams/join/success');
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        const data = error.response.data;
        switch (data.errorCode) {
          case 'already_joined':
            setErrorModal(
              "既にチームに参加しています",
              "既にこのチームには参加済みです。WorkAIzerを使ってみましょう。",
              undefined,
              () => {
                window.location.href = '/';
              }
            )
            return;
          case 'approve_deadline_exceeded':
            setErrorModal(
              "招待メールが有効期限切れになりました",
              "大変恐縮ですが、操作中に招待メールが有効期限切れになりました。"+
              "あなたを招待した管理者に再度招待メールを送るようお伝えください。",
            )
            return;
          case 'invite_code_mismatch':
            setErrorModal(
              "招待リンクが不正です",
              "URLが間違っている可能性があります。URLが正しいかご確認ください。"+
              "もし正しい場合は、管理者が再招待をしたことで無効化された可能性があります。"+
              "メールボックスを確認し、他に招待メールが届いていないかご確認ください。"+
              "それでも問題が解決しない場合は、管理者にお問い合わせください。",
            )
            return;
        }
      }
      captureException(error);
      setUnknownErrorModal();
      console.error('チーム参加エラー:', error);
    }
  };

  if (membership.isJoined) {
    return (
      <InfoPanel title="チーム参加済">
        既にチームに参加しています。<br/>

        <a href="/">サービスを利用する</a>
      </InfoPanel>
    );
  }

  if (membership.invite?.isApproveDeadlineExceeded) {
    return (
      <InfoPanel title="認証URL期限切れ">
        既に招待URLが有効期限切れとなっています。<br/>
        あなたを招待した管理者に再度招待メールを送るようお伝えください。
      </InfoPanel>
    );
  }

  const handleAgree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const team = membership.team;

  return (
    <CommonPanel>
      <Box display="flex" flexDirection="column" alignItems="center" p={4}>
        <Typography variant="h4" align="center" gutterBottom>
          チーム「{team.name}」にようこそ
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px', marginBottom: '30px' }}>
          参加にはサービス利用規約への同意が必要です。
        </Typography>
        <Box display="flex" alignItems="center" mb={4}>
          <FormControlLabel
            control={<Checkbox checked={agreed} onChange={handleAgree} />}
            label={
              <Box component="span" display="flex" alignItems="center">
                <Typography variant="body2">
                  <Link to={termUrl} target="_blank" color="primary">
                    利用規約
                  </Link>と
                  <Link to={privacyPolicyUrl} target="_blank" color="primary">
                    プライバシーポリシー
                  </Link>
                  に同意します
                </Typography>
              </Box>
            }
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handleJoinTeam} disabled={!agreed} style={{ padding: '10px 20px' }}>
          チームに参加する
        </Button>
      </Box>
    </CommonPanel>
  );
};

export default TeamJoin;
