import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ConfirmDialog } from '../../../ui/dialogs/ConfirmDialog'; // ConfirmDialogのパスを適切に設定してください

export const APIUnauthorizedHandler: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const handleUnauthorized = () => {
      setDialogOpen(true);
    };

    // イベントリスナーを登録
    window.addEventListener("unauthorized", handleUnauthorized);

    // コンポーネントのアンマウント時にイベントリスナーを解除
    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, []);

  const handleConfirm = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        open={dialogOpen}
        title="ログイン状態エラー"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmButtonText="再ログイン"
        cancelButtonText="キャンセル"
      >
        ログイン状態が失われました。再度ログインしてください。
      </ConfirmDialog>
      <Outlet />
    </>
  );
};
