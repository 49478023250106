import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ThreadLeftSidebarContainer from '../containers/ThreadLeftSidebarContainer';
import { ContentsWrapperWithLeftSidebar, MainAreaWrapper, RightSideBarWrapper } from '@/components/ui/StyledComponents';
import ThreadRightSidebarContainer from '../containers/ThreadRightSidebarContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Drawer, IconButton, Toolbar } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface ThreadsLayoutProps {
  isLeftSidebarOpen: boolean;
  onCloseSidebar: () => void;
}

const ThreadsLayout: React.FC<ThreadsLayoutProps> = ({ isLeftSidebarOpen, onCloseSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const shouldHideRightSidebar = useMediaQuery('(max-width:1350px)');
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);

  const handleRightSidebarToggle = () => {
    setIsRightSidebarOpen(!isRightSidebarOpen);
  };

  return (
    <ContentsWrapperWithLeftSidebar>
      {/* 左サイドバー */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? isLeftSidebarOpen : true}
        onClose={onCloseSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.default,
            boxSizing: 'border-box',
            width: 275,
            borderRight: 'none',
          },
        }}
      >
        <Toolbar />
        <ThreadLeftSidebarContainer onItemClick={onCloseSidebar} />
      </Drawer>

      {/* メインコンテンツ */}
      <MainAreaWrapper>
        <Outlet />
      </MainAreaWrapper>

      {/* 右サイドバー */}
      {shouldHideRightSidebar ? (
        <>
          <RightSideBarWrapper
            sx={{
              width: '20px !important',
              minWidth: 'unset !important',
              padding: '0 !important',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            onClick={handleRightSidebarToggle}
          >
            <IconButton size="small">
              {isRightSidebarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </RightSideBarWrapper>
          <Drawer
            anchor="right"
            open={isRightSidebarOpen}
            onClose={handleRightSidebarToggle}
            sx={{
              '& .MuiDrawer-paper': {
                width: 275,
                paddingTop: 10,
                paddingLeft: 2,
                backgroundColor: theme.palette.background.default,
              },
            }}
          >
            <ThreadRightSidebarContainer />
          </Drawer>
        </>
      ) : (
        <ThreadRightSidebarContainer />
      )}
    </ContentsWrapperWithLeftSidebar>
  );
};

export default ThreadsLayout;
