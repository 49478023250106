import axios from 'axios';

// 環境変数からAPIのベースURLを取得
const apiUrl = import.meta.env.VITE_API_URL;

// axiosのインスタンスを作成
const apiAxios = axios.create({
  baseURL: apiUrl,
});

// レスポンスインターセプターを追加
apiAxios.interceptors.response.use(
  response => response,
  error => {
    // 401 Unauthorizedエラーの場合、カスタムイベントを発火してリダイレクト
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new CustomEvent("unauthorized"));
    }
    return Promise.reject(error);
  }
);

export default apiAxios;

