import { UnitType } from "./report.type";


export const getDateStrForGraph = (targetOn: string, unitType: UnitType): string => {
    if (unitType == UnitType.WEEK) {
        // 月曜〜日曜の文字列を返す
        const date = new Date(targetOn);
        const day = date.getDay();
        const diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);
        const monday = new Date(date.setDate(diffToMonday));
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        const formatDate = (d: Date) => `${('0' + (d.getMonth() + 1)).slice(-2)}/${('0' + d.getDate()).slice(-2)}`;
        console.log(`${formatDate(monday)}〜${formatDate(sunday)}`)
        return `${formatDate(monday)}〜${formatDate(sunday)}`;
    }
    if (unitType == UnitType.MONTH) {
        // 2024/01/31なら2024/01を返す
        console.log(targetOn.replace(/-/g, '/').slice(0, 7))
        return targetOn.replace(/-/g, '/').slice(0, 7);
    }

    // 2024-01-31なら01/31を返す
    return targetOn.replace(/-/g, '/').slice(5);
};

export const getCsvTermFrom = (targetOn: string, unitType: UnitType): string => {
    if (unitType == UnitType.WEEK) {
        // 月曜を返す
        const date = new Date(targetOn);
        const day = date.getDay();
        const diffToMonday = date.getDate() - day + (day === 0 ? -6 : 1);
        const monday = new Date(date.setDate(diffToMonday));
        return monday.toISOString().replace(/-/g, '/').slice(0, 10);
    }
    if (unitType == UnitType.MONTH) {
        // 2024-01-31なら2024/01/01を返す
        return targetOn.replace(/-/g, '/').slice(0, 7) + '/01';
    }

    // 2024-01-01なら2024-01-01を返す
    return targetOn.replace(/-/g, '/');
}
