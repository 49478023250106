import React from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { AIMessageStatus } from '../thread.constant';
import { AIMessage } from '../thread.type';

interface AiGenerateStatusProps {
  aiMsg: AIMessage;
  hideSuccess?: boolean;
}

const AiGenerateStatus: React.FC<AiGenerateStatusProps> = ({ aiMsg, hideSuccess }) => {
  return (
    <>
      {
        aiMsg.status === AIMessageStatus.WAITING ? (
          <Chip label="生成待ち" color="secondary" size="small" />
        ) :
        aiMsg.status === AIMessageStatus.PROCESSING ? (
          <>
            <Chip label="生成中" color="primary" size="small" />
            <CircularProgress size="1rem" />
          </>
        ) :
        aiMsg.status === AIMessageStatus.FINISHED ? (
          !hideSuccess && <Chip label="生成完了" color="success" size="small" />
        ) :
        aiMsg.status === AIMessageStatus.FAILED ? (
          <Chip label="生成失敗" color="error" size="small" />
        ) : (
          <Chip label="不明" color="warning" size="small" />
        )
      }
    </>
  );
};

export default AiGenerateStatus;

