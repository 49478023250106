import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface ErrorModalContent {
  title: string;
  message: string;
  buttonOption?: {
    title: string;
    url: string;
  };
}

interface ErrorModalState {
  content: ErrorModalContent | null;
}

const UnknownErrorContent: ErrorModalContent = {
  title: '不明なエラー',
  message: '不明なエラーが発生しました。ブラウザをリロードするか、しばらくしてから再度お試しください。',
};

const initialState: ErrorModalState = {
  content: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showUnknownErrorModal: (state) => {
      state.content = UnknownErrorContent;
    },
    showErrorModal: (state, action: PayloadAction<ErrorModalContent>) => {
      state.content = action.payload;
    },
    hideErrorModal: (state) => {
      state.content = null;
    },
  },
});

export const { showUnknownErrorModal, showErrorModal, hideErrorModal } = errorSlice.actions;
export default errorSlice.reducer;
