import React from 'react';
import { Fade, List, ListItemButton, ListItemIcon, ListItemText, styled, Tooltip, tooltipClasses, TooltipProps, IconButton } from '@mui/material';
import { getAllQuickPrompts, QuickPromptSetWithQpList } from '../quickPrompt.type';
import * as Icons from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const MenuList = styled(List)`
  flex-grow: 1;
  overflow-y: hidden;
  padding-right: 17px; // スクロールバーの平均的な幅
  box-sizing: content-box; // paddingをwidthに含めない

  &::-webkit-scrollbar {
    width: 8px; // マウスオーバー時にはスクロールバーの幅を8pxに設定
  }

  &:hover {
    overflow-y: auto;
    // padding-right: 9px;
    &::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.5); // マウスオーバー時のスクロールバーの色と不透明度を設定
    }
  }
`;

const QpTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    TransitionComponent={Fade}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 13,
    padding: 10,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.light,
  },
}));

interface QpListPresenterProps {
  // クイックプロンプトセットの一覧 (既にソートされてる前提)
  sortedQuickPromptSets: QuickPromptSetWithQpList[];
  expandedSets: { [key: string]: boolean };
  bookmarkedQuickPromptIds: string[];
  handleToggleSet: (setId: string) => void;
  handleAddBookmark: (quickPromptId: string) => void;
  handleRemoveBookmark: (quickPromptId: string) => void;
}

const QpListPresenter: React.FC<QpListPresenterProps> = ({
  sortedQuickPromptSets,
  expandedSets,
  handleToggleSet,
  bookmarkedQuickPromptIds,
  handleAddBookmark,
  handleRemoveBookmark,
}) => {
  const DynamicIcon = ({ iconName }: { iconName?: string }) => {
    const IconComponent = iconName && iconName in Icons ?
      Icons[iconName as keyof typeof Icons] :
      Icons.InfoOutlined
    ;
    return <IconComponent />;
  };

  const qpSets = [...sortedQuickPromptSets];

  if (bookmarkedQuickPromptIds.length > 0) {
    const qpList = getAllQuickPrompts(qpSets)
    const favoriteQpSet: QuickPromptSetWithQpList = {
      id: '0',
      officialCode: 'favorite',
      name: "お気に入り",
      icon: "StarOutline",
      createdAt: "",
      updatedAt: "",
      quickPrompts: qpList.filter((qp) => {
        return bookmarkedQuickPromptIds.includes(qp.id);
      }),
    }
    qpSets.unshift(favoriteQpSet);
  }

  return (
    <MenuList>
      {qpSets.map((set) => (
        <React.Fragment key={set.id}>
          <ListItemButton
            dense
            sx={{
              paddingBottom: 0.5,
              paddingTop: 1,
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
            }}
            onClick={() => handleToggleSet(set.id)}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <DynamicIcon iconName={set.icon} />
            </ListItemIcon>
            <ListItemText primary={set.name} sx={{ m: 0, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }} />
            {expandedSets[set.id] ?
              <Icons.ExpandLess sx={{ mr: "-3px" }}/> :
              <Icons.ExpandMore sx={{ mr: "-3px" }}/>
            }
          </ListItemButton>
          {expandedSets[set.id] && (
            <List component="div" disablePadding sx={{marginBottom: "10px"}}>
              {set.quickPrompts.map((qp) => (
                <QpTooltip
                  key={qp.id}
                  title={qp.description}
                  placement="right"
                >
                  <ListItemButton
                    dense
                    sx={{ pl: 4, pr: 1, display: 'flex', alignItems: 'center' }}
                    component={RouterLink}
                    to={`/quick-prompts/${qp.id}/threads/new`}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <DynamicIcon iconName={qp.icon} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ m: 0, flexGrow: 1, '& .MuiListItemText-primary': { fontSize: '0.875rem' } }}
                      primary={qp.name}
                    />
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        bookmarkedQuickPromptIds.includes(qp.id)
                          ? handleRemoveBookmark(qp.id)
                          : handleAddBookmark(qp.id);
                      }}
                    >
                      {bookmarkedQuickPromptIds.includes(qp.id)
                        ? <Icons.Star sx={{ color: 'primary.light' }} />
                        : <Icons.StarBorder />
                      }
                    </IconButton>
                  </ListItemButton>
                </QpTooltip>
              ))}
            </List>
          )}
        </React.Fragment>
      ))}
    </MenuList>
  );
};

export default QpListPresenter;
