import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { Plan } from '@/components/features/subscription/subscription.type';
import { ReqUpdateQuotaCustomization, ReqScheduleLicenseChange, ReqSchedulePlanChange, SvSubscriptionWithFull } from '../subscription.type';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import SvSubscriptionDetailPresenter from '../presenters/SvSubscriptionDetailPresenter';
import { AxiosError } from 'axios';
import { useConfirmModal } from '@/components/features/generic/hooks/useConfirmModal';

const SvSubscriptionDetailContainer: React.FC = () => {
  const [ plans, setPlans ] = useState<Plan[]>([]);
  const [ subscriptions, setSubscriptions ] = useState<SvSubscriptionWithFull[]>([]);
  const { setUnknownErrorModal, setErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);

  useEffect(() => {
    if (!team) return

    const fetchData = async () => {
      try {
        const [rePlans, resSubscriptions] = await Promise.all([
          apiAxios.get<Plan[]>('/supervisor/plans'),
          apiAxios.get<SvSubscriptionWithFull[]>(`/supervisor/teams/${team.id}/subscriptions`),
        ]);
        setPlans(rePlans.data);
        setSubscriptions(resSubscriptions.data);
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [team]);

  if (!team) {
    return null;
  }

  const handleSchedulePlanChange = async (data: ReqSchedulePlanChange) => {
    setConfirmModal(
      "プラン変更の確認",
      "プランの変更を予約しますか？",
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`, data);
          window.alert(
            '[予約成功]\n\n' +
            'プランの変更予約が正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleScheduleLicenseChange = async (data: ReqScheduleLicenseChange) => {
    setConfirmModal(
      "ライセンス変更の確認",
      "ライセンスの変更を予約しますか？",
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`, data);
          window.alert(
            '[予約成功]\n\n' +
            'ライセンスの変更予約が正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleUpdateQuotaCustomization = async (subscriptionId: string, data: ReqUpdateQuotaCustomization) => {
    setConfirmModal(
      "プランカスタマイズの確認",
      "プランのカスタマイズを実行しますか？",
      async () => {
        try {
          await apiAxios.put(`/supervisor/subscriptions/${subscriptionId}/quota-customization`, data);
          window.alert(
            '[実行成功]\n\n' +
            'プランのカスタマイズが正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const cancelScheduledPlanChange = async () => {
    setConfirmModal(
      "キャンセル確認",
      "プランの変更予約をキャンセルしますか？",
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`);
          window.alert(
            '[キャンセル成功]\n\n' +
            'プランの変更予約がキャンセルされました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  const handleCancelLicenseChangeScheduled = async () => { // FIXME: handleCancelHoge か cancelHoge かが不統一
    setConfirmModal(
      "キャンセル確認",
      "ライセンスの変更予約をキャンセルしますか？",
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`);
          window.alert(
            '[キャンセル成功]\n\n' +
            'ライセンスの変更予約がキャンセルされました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  return (
    <SvSubscriptionDetailPresenter
      team={team}
      plans={plans}
      subscriptions={subscriptions}
      onSchedulePlanChange={handleSchedulePlanChange}
      onScheduleLicenseChange={handleScheduleLicenseChange}
      onHandleQuotaCustomization={handleUpdateQuotaCustomization}
      onCancelPlanChange={cancelScheduledPlanChange}
      onCancelLicenseChange={handleCancelLicenseChangeScheduled}
    />
  );
};

export default SvSubscriptionDetailContainer;
