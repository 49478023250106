import { CenteredMainAreaWrapper, ContentsWrapper } from "@/components/ui/StyledComponents";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorContainer() {
  const error = useRouteError();
  console.debug("Error Page", error);

  if (isRouteErrorResponse(error)) {
    return (
      <ContentsWrapper id="error-page">
        <CenteredMainAreaWrapper>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
              <i>{error.statusText}</i>
          </p>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  } else if (error instanceof Error) {
    return (
      <ContentsWrapper id="error-page">
        <CenteredMainAreaWrapper>
          <h1>Oops! Unexpected Error</h1>
          <p>Something went wrong.</p>
          <p>
            <i>{error.message}</i>
          </p>
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  } else {
    return (
      <ContentsWrapper>
        <CenteredMainAreaWrapper>
          404 Notfound
        </CenteredMainAreaWrapper>
      </ContentsWrapper>
    );
  }
}
