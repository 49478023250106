import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, TextField, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setUserInfo, clearRedirectUrl } from '@/components/features/auth/auth.slice';
import { RootState } from '@/store';
import { useSelector } from 'react-redux';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import { ContentsWrapper } from '@/components/ui/StyledComponents';

const DummyLoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<{ email: string, password: string }>();
  const { setUnknownErrorModal } = useErrorModal();

  const redirectUrl = useSelector((state: RootState) => state.auth.redirectUrl);

  const onSubmit = async (data: { email: string, password: string }) => {
    try {
      const response = await apiAxios.post('/auth/login', null, {
        params: { auth_code: `DUMMY_LOGIN:${data.email}:${data.password}` },
      });

      dispatch(setUserInfo(response.data));
      navigate(redirectUrl || '/');
      dispatch(clearRedirectUrl());
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('ダミーログインエラー:', error);
      if (error instanceof Error) {
        alert(`ダミーログインに失敗しました: ${error.message}\n\n詳細:\n${JSON.stringify(error, null, 2)}`);
      } else {
        alert('ダミーログインに失敗しました。詳細なエラー情報が取得できませんでした。');
      }
    }
  };

  return (
    <ContentsWrapper>
      <form onSubmit={handleSubmit(onSubmit)} style={{ margin: '20px' }}>
        <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.email)}>
          <TextField
            label="メールアドレス"
            variant="outlined"
            fullWidth
            autoComplete="off"
            {...register('email', {
              required: 'メールアドレスは必須です',
              maxLength: { value: 250, message: 'メールアドレスは250文字以内で入力してください' },
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '正しいメールアドレス形式で入力してください' },
            })}
          />
          {errors.email && <span>{errors.email.message}</span>}
        </FormControl>
        <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.password)}>
          <TextField
            label="パスワード"
            variant="outlined"
            fullWidth
            type="password"
            autoComplete="off"
            {...register('password', {
              required: 'パスワードは必須です',
            })}
          />
          {errors.password && <span>{errors.password.message}</span>}
        </FormControl>
        <Button type="submit" color="primary" style={{ marginTop: '20px' }}>ダミーログイン</Button>
      </form>
    </ContentsWrapper>
  );
};

export default DummyLoginPage;
