import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AppBar, Toolbar, ThemeProvider, Box, Button } from '@mui/material';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { supervisorTheme } from '@/theme';
import { isSystemSupervisor } from '@/components/features/auth/auth.type';

const SupervisorHeader: React.FC = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const isSupervisor = loginUser && isSystemSupervisor(loginUser);

  if (!isSupervisor) {
    return <Outlet />;
  }

  return (
    <ThemeProvider theme={supervisorTheme}>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            component="a"
            onClick={() => navigate('/supervisor')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '1.5em',
            }}
          >
            システム運用画面
          </Box>
          <div style={{
            marginLeft: 140,
            flexGrow: 1,
          }}></div>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/"
          >
            通常の画面に戻る
          </Button>
        </Toolbar>
      </AppBar>
      <Outlet />
    </ThemeProvider>
  );
};

export default SupervisorHeader;
