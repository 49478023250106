import { useEditor, EditorContent, Editor } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { MainAreaWrapper } from "../../ui/StyledComponents";
import styled from '@emotion/styled';
import Placeholder from "@tiptap/extension-placeholder";

export const EditorArea = styled.div`
  position: relative;

  width: 100%;
  margin-bottom: 48px;
  border: 1px solid #ccc;

  .ProseMirror {
    padding: 10px;
  }

  .ProseMirror p.is-editor-empty:first-of-type::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

`;


export function TiptapPage() {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: 'Write something …',
      }),
    ],
  }) as Editor;

  if (!editor) {
    return null;
  }

  return (
    <div style={{"paddingTop": 100}}>
      <MainAreaWrapper>
        aaaa
        <EditorArea>
          <EditorContent editor={editor} />
        </EditorArea>
        bbbb
      </MainAreaWrapper>
    </div>
  );
}
