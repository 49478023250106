export enum DlpPreventType {
  REPLACE = 'replace',
  PROHIBIT = 'prohibit',
  WARN = 'warn',
}
export const getDlpPreventTypeLabel = (preventType: DlpPreventType): string => {
  switch (preventType) {
    case DlpPreventType.REPLACE:
      return '置換';
    case DlpPreventType.PROHIBIT:
      return '禁止';
    case DlpPreventType.WARN:
      return '警告';
    default:
      return '';
  }
};

export enum DlpCheckSpeedType {
  FAST = 'fast',
  NORMAL = 'normal',
  SLOW = 'slow',
}

export const getDlpCheckSpeedTypeLabel = (speedType: DlpCheckSpeedType): string => {
  switch (speedType) {
    case DlpCheckSpeedType.FAST:
      return '高速';
    case DlpCheckSpeedType.NORMAL:
      return '標準';
    case DlpCheckSpeedType.SLOW:
      return '低速';
    default:
      return '';
  }
};
