import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

type ToastPresenterProps = {
  isOpen: boolean;
  message: string;
  severity?: AlertColor;
  onClose: () => void;
};

const ToastPresenter: React.FC<ToastPresenterProps> = ({ isOpen, message, severity = 'info', onClose }) => {
  return (
    <Snackbar
      open={isOpen}
      sx={{ minWidth: 300, maxWidth: 600 }}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastPresenter;
