import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

export interface ToastContent {
  message: string;
  severity?: AlertColor;
}

interface ToastState {
  content: ToastContent | null;
}

const initialState: ToastState = {
  content: null,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastContent>) => {
      state.content = action.payload;
    },
    hideToast: (state) => {
      state.content = null;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
