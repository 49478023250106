import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import { MembershipManaged } from '../membership.type';
import {
  CircularProgress,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import MembershipFormModal from '../components/MembershipFormModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembership, reinviteMembership } from '../membership.slice';
import { AppDispatch, RootState } from '@/store';
import { getCurrentMembership, getCurrentSubscription, isAdmin, isSuperAdmin } from '@/components/features/auth/auth.type';
import { MembershipRole, getMembershipRoleLabel } from '../membership.constant';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { ResponsiveTableActionFooter, ResponsiveTableCell, ResponsiveTableRow, ResponsiveTableRowHeader } from '@/components/ui/ResponsiveComponents';
import { displayTimestamp } from '@/lib/utils';
import { PlanType } from '../../subscription/subscription.constant';
import { MembershipGroup } from '../../membership-group/membership-group.type';

interface MembershipListPresenterProps {
  memberships: MembershipManaged[];
  membershipNum: number;
  membershipGroups: MembershipGroup[];
  onScroll: () => void;
  selectedGroupId: string;
  onChangeSelectedGroupId: (groupId: string) => void;
  hasMore: boolean;
}

const MembershipListPresenter: React.FC<MembershipListPresenterProps> = ({
  memberships,
  membershipGroups,
  onScroll,
  selectedGroupId,
  onChangeSelectedGroupId,
  hasMore,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState<MembershipManaged | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const team = getCurrentMembership(loginUser)?.team;
  const currentSub = getCurrentSubscription(loginUser);
  if (!(loginUser && team  && currentSub)) {
    return null;
  }
  const totalMembersCount = team.membersCount;
  const licenseAmount = currentSub.currentLicense.amount;
  const isLicenseLimitReached = totalMembersCount >= licenseAmount;
  const isLicenseLimitOver = totalMembersCount > licenseAmount;

  const handleAddClick = () => {
    setSelectedMembership(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (membership: MembershipManaged) => {
    setSelectedMembership(membership);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    if (window.confirm('本当に削除しますか？')) {
      await dispatch(deleteMembership(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleReinvite = async (membership: MembershipManaged) => {
    await dispatch(reinviteMembership({
      "id": membership.id,
    }));
  }

  return (
    <>
      <AdminPageTitle title="メンバー管理" />
      <Divider />
      <AdminContentsWrapper id="scrollableDiv">
        {
          isLicenseLimitOver &&
          <Box sx={{ mt: '15px', mb: '25px', p: 2, backgroundColor: 'error.main', borderRadius: 1 }}>
            <Typography variant="subtitle1" gutterBottom sx={{color: 'error.contrastText', fontWeight: 'bold'}}>
              ライセンス数超過
            </Typography>
            <Typography variant="body2" sx={{color: 'error.contrastText', fontWeight: 'bold'}}>
              現在のメンバー数がライセンス数を超過しているため、一部の機能に制限がかかっております。<br/>
              サービスを快適にご利用いただくために、速やかにライセンス数の追加、もしくはメンバー数の調整をお願いいたします。
            </Typography>
          </Box>
        }
        {
          currentSub.plan.type === PlanType.STARTER ?
          <Typography variant="body2" color="textSecondary">
            同じ会社や部署の仲間を招待することで、一緒にWorkAIzerを使うことができます。<br/>
            ※ スタータープランではメンバーは原則10名までとなっております。
          </Typography> :
          <>
            <Typography variant="body2" color="textSecondary">
              ご契約のライセンス数に応じて、メンバーを招待することができます。<br/>
              効率的なチーム運営のため、適切なライセンス数の設定をおすすめします。<br/>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom
              sx={{mt:'10px'}}>
              ライセンス数: {licenseAmount}
              &nbsp;&nbsp;&nbsp;
              メンバー数: {team.membersCount}
            </Typography>
          </>
        }
        <Box sx={{ mt:'15px', mb: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tooltip title={isLicenseLimitReached ? "ライセンス数を超えるため招待できません" : null}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                disabled={isLicenseLimitReached}
              >
                招待
              </Button>
            </span>
          </Tooltip>
          { membershipGroups.length > 0 && <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle1' color="text.secondary">
                グループで絞り込む:&nbsp;
              </Typography>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                <Select
                  value={selectedGroupId}
                  onChange={(e) => onChangeSelectedGroupId(e.target.value as string)}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>全メンバー</em>
                  </MenuItem>
                  {membershipGroups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </>}
        </Box>
        <InfiniteScroll
          dataLength={memberships.length}
          next={onScroll}
          hasMore={hasMore}
          loader={
            <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
              <CircularProgress />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <TableContainer component={Paper}>
            <Table>
              {!isSmallScreen && (
                <TableHead>
                  <TableRow>
                    <TableCell>名前</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>ロール</TableCell>
                    {membershipGroups.length > 0 &&
                      <TableCell>所属グループ</TableCell>
                    }
                    <TableCell>ステータス</TableCell>
                    <TableCell>作成日時</TableCell>
                    <TableCell>更新日時</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {memberships.map((membership) => (
                  <ResponsiveTableRow key={membership.id}>
                    <ResponsiveTableRowHeader>{membership.user.name || membership.user.email}</ResponsiveTableRowHeader>
                    <ResponsiveTableCell data-label="メールアドレス">{membership.user.email}</ResponsiveTableCell>
                    <ResponsiveTableCell data-label="ロール">{getMembershipRoleLabel(membership.role)}</ResponsiveTableCell>
                    {membershipGroups.length > 0 &&
                      <ResponsiveTableCell data-label="所属グループ">{membership.group?.name}</ResponsiveTableCell>
                    }
                    <ResponsiveTableCell data-label="ステータス">
                      {membership.isJoined ? '参加済' : '招待中'}
                      {membership.invite && !membership.invite.isApproved && (
                        <>
                          <br/>
                          <div>(承認期限: {displayTimestamp(membership.invite.approveDeadline)})</div>
                          <div>
                            {membership.invite.isApproveDeadlineExceeded && !membership.invite.isApproved && (<>
                              <span style={{ color: 'red' }}>期限切れ</span>
                              <Button size="small" onClick={() => handleReinvite(membership)}>再招待</Button>
                            </>)}
                          </div>
                        </>
                      )}
                    </ResponsiveTableCell>
                    <ResponsiveTableCell data-label="作成日時">{displayTimestamp(membership.createdAt)}</ResponsiveTableCell>
                    <ResponsiveTableCell data-label="更新日時">{displayTimestamp(membership.updatedAt)}</ResponsiveTableCell>
                    <ResponsiveTableActionFooter>
                      { (isSuperAdmin(loginUser) || membershipGroups.length > 0) &&
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEditClick(membership)}
                        >
                          <EditIcon />
                        </IconButton>
                      }
                      {(isSuperAdmin(loginUser) || (isAdmin(loginUser) && membership.role === 'normal')) && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteClick(membership.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ResponsiveTableActionFooter>
                  </ResponsiveTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </AdminContentsWrapper>
      <MembershipFormModal
        open={isModalOpen}
        handleClose={handleModalClose}
        membershipGroups={membershipGroups}
        defaultValues={
          selectedMembership ?
          {
            // 更新
            id: selectedMembership.id,
            groupId: selectedMembership.group?.id,
            role: selectedMembership.role,
          }:
          {
            // 新規登録
            emails: "",
            role: MembershipRole.NORMAL
          }
        }
      />
    </>
  );
};

export default MembershipListPresenter;
