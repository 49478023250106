import { Typography } from "@mui/material";
import CommonModal from "./CommonModal";
import styled from '@emotion/styled';

const PreContent = styled(Typography)`
  white-space: pre-wrap;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  line-break: anywhere;
  margin-block-start: 1em;
  margin-block-end: 1em;
`;

const CommonPreModal: React.FC<{
  open: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  children: React.ReactNode;
  additionalActions?: React.ReactNode;
}> = ({ open, onClose, children, title, additionalActions }) => {
  return (
    <CommonModal
      open={open} onClose={onClose} title={title} additionalActions={additionalActions}
      maxWidth="md" fullWidth>
      <PreContent>{children}</PreContent>
    </CommonModal>
  );
};

export default CommonPreModal;