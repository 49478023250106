import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentsWrapperWithLeftSidebar, MainAreaWrapper } from '@/components/ui/StyledComponents';
import { Drawer, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { supervisorTheme } from '@/theme';
import SupervisorLeftSidebar from './SupervisorLeftSidebar';

const SupervisorLayout: React.FC = () => {

  return (
    <ThemeProvider theme={supervisorTheme}>
      <ContentsWrapperWithLeftSidebar>
        {/* 左サイドバー */}
        <Drawer
          variant={"permanent"}
          open={true}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: supervisorTheme.palette.background.default,
              boxSizing: 'border-box',
              width: 275,
              borderRight: 'none',
            },
          }}
        >
          <Toolbar />
          <SupervisorLeftSidebar />
        </Drawer>

        {/* メインコンテンツ */}
        <MainAreaWrapper>
          <Outlet />
        </MainAreaWrapper>
      </ContentsWrapperWithLeftSidebar>
    </ThemeProvider>
  );
};

export default SupervisorLayout;
