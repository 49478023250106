import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContext } from '../contexts/ToastContext';
import { showToast as showToastAction, hideToast as hideToastAction } from '../toast.slice';
import { AlertColor } from '@mui/material';

export const useToast = () => {
  const { isOpen, message, severity } = useContext(ToastContext);
  const dispatch = useDispatch();

  const showToast = (message: string, severity?: AlertColor) => {
    dispatch(showToastAction({ message, severity }));
  };

  const hideToast = () => {
    dispatch(hideToastAction());
  };

  return { isOpen, message, severity, showToast, hideToast };
};
