import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ThreadListPresenter from '../presenters/ThreadListPresenter';
import { RootState } from '@/store';
import { Thread } from '../thread.type';
import apiAxios from '@/lib/axios';
import { appendThreads } from '../thread.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';

const LIMIT = 20;

const ThreadListContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const threads = useSelector((state: RootState) => state.thread.threads);
  const [ hasMore, setHasMore ] = useState(true)
  const { setUnknownErrorModal } = useErrorModal();

  const handleScroll = async () => {
    try {
      const response = await apiAxios.get<Thread[]>('/threads', {
        params: {
          offset: threads.length,
          limit: LIMIT,
        },
      });
      dispatch(appendThreads(response.data));
      if (response.data.length < LIMIT) {
        setHasMore(false)
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('Failed to fetch more threads:', error);
    }
  };

  useEffect(() => {
    // 20件以上スレッドが読み込まれていない場合は、初期読み込みを行う
    if (!threads || threads.length < 20) {
      handleScroll();
    }
  }, [threads, dispatch]);


  const handleThreadClick = (threadId: string) => {
    navigate(`/threads/${threadId}`);
  };

  return (
    <ThreadListPresenter
      threads={threads}
      onScroll={handleScroll}
      onThreadClick={handleThreadClick}
      hasMore={hasMore}
    />
  );
};

export default ThreadListContainer;
