import { removeDecimalPart } from "@/lib/utils";

export const getSpecialCreditLabel = (val?: string) : string | null => {
    if (val === undefined || val === null) {
        return "制限はありません"
    }
    if (removeDecimalPart(val) === "0") {
        return "クレジットの利用を許可されていません";
    }
    return null;
}
