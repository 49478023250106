import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TextField, Paper, Typography } from '@mui/material';
import CustomMarkdown from '@/components/ui/CustomMarkdown';

const initialMarkdown = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
| 1 | 2 |
| 3 | 44444444444 |


Here is some JavaScript code:

~~~js
console.log('It works!')
~~~


危険なHTML
<div onmouseover="alert('alpha')">
  <a href="jAva script:alert('bravo')">delta</a>
  <img src="x" onerror="alert('charlie')">
  <iframe src="javascript:alert('delta')"></iframe>
  <math>
    <mi xlink:href="data:x,<script>alert('echo')</script>"></mi>
  </math>
</div>
<script>
require('child_process').spawn('echo', ['hack!']);
</script>
<script type="text/javascript">
  console.log("Hello World!");
</script>
`;

const StyledContainer = styled(Paper)`
  display: flex;
  gap: 24px;
  padding: 32px;
  max-width: 1200px;
  margin: 32px auto;
  margin-top: 100px;
`;

const StyledTextArea = styled(TextField)`
  flex: 1;
`;

const StyledMarkdownPreview = styled(Paper)`
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  max-height: 600px;

  .markdown-body {
    color: #333;
  }

  pre {
    background: #2d2d2d;
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
  }
`;

const DebugMarkdown: React.FC = () => {
  const [markdown, setMarkdown] = useState(initialMarkdown);

  return (
    <StyledContainer elevation={3}>
      <StyledTextArea
        multiline
        rows={20}
        variant="outlined"
        value={markdown}
        onChange={(e) => setMarkdown(e.target.value)}
        label="Markdown入力"
      />
      <StyledMarkdownPreview elevation={1}>
        <Typography variant="h6" gutterBottom>
          プレビュー
        </Typography>
        <CustomMarkdown
          markdown={markdown}
        />
      </StyledMarkdownPreview>
    </StyledContainer>
  );
};

export default DebugMarkdown;
