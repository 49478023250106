import React from 'react';
import { Outlet } from 'react-router-dom';
import { ContentsWrapperWithLeftSidebar, MainAreaWrapper } from '@/components/ui/StyledComponents';
import AdminLeftSidebar from './AdminLeftSidebar';
import { Drawer, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface AdminLayoutProps {
  isLeftSidebarOpen: boolean;
  onCloseSidebar: () => void;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ isLeftSidebarOpen, onCloseSidebar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContentsWrapperWithLeftSidebar>
      {/* 左サイドバー */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? isLeftSidebarOpen : true}
        onClose={onCloseSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.background.default,
            boxSizing: 'border-box',
            width: 275,
            borderRight: 'none',
          },
        }}
      >
        <Toolbar />
        <AdminLeftSidebar onItemClick={onCloseSidebar} />
      </Drawer>

      {/* メインコンテンツ */}
      <MainAreaWrapper>
        <Outlet />
      </MainAreaWrapper>
    </ContentsWrapperWithLeftSidebar>
  );
};

export default AdminLayout;
