import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QpListPresenter from '../presenters/QpListPresenter';
import { AppDispatch, RootState } from '@/store';
import { QuickPromptSetWithQpList } from '../quickPrompt.type';
import apiAxios from '@/lib/axios';
import { setQuickPromptSets, setBookmarkedQuickPromptIds, addBookmark, removeBookmark } from '../quickPrompt.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import { getCurrentMembership } from '../../auth/auth.type';

const QpListContainer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { quickPromptSets, bookmarkedQuickPromptIds } = useSelector((state: RootState) => state.quickPrompt);
  const { setUnknownErrorModal } = useErrorModal();
  const [expandedSets, setExpandedSets] = useState<{ [key: string]: boolean }>({'0': true});
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentMembership = getCurrentMembership(loginUser);

  useEffect(() => {
    // チームに所属していないコールしない
    if (!currentMembership) {
      return;
    }

    const fetchData = async () => {
      if (quickPromptSets.length > 0 && bookmarkedQuickPromptIds.length > 0) return;

      try {
        const [quickPromptSetsResponse, bookmarkedIdsResponse] = await Promise.all([
          apiAxios.get<QuickPromptSetWithQpList[]>('/quick-prompt-sets'),
          apiAxios.get<string[]>('/bookmarked-quick-prompt-ids')
        ]);

        dispatch(setQuickPromptSets(quickPromptSetsResponse.data));
        dispatch(setBookmarkedQuickPromptIds(bookmarkedIdsResponse.data));

        // ローカルストレージから開閉状態を取得し、初期状態として設定
        const storedExpandedSets = localStorage.getItem('expandedSets');
        if (storedExpandedSets) {
          setExpandedSets(JSON.parse(storedExpandedSets));
        }
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch quick prompt sets:', error);
      }
    };

    fetchData();
  }, [dispatch, currentMembership]);

  const handleToggleSet = (setId: string, forceValue: boolean | undefined = undefined) => {
    setExpandedSets((prevExpandedSets) => {
      const newExpandedSets = {
        ...prevExpandedSets,
        [setId]: forceValue !== undefined ? forceValue : !prevExpandedSets[setId],
      };
      // 開閉状態をローカルストレージに保存
      localStorage.setItem('expandedSets', JSON.stringify(newExpandedSets));
      return newExpandedSets;
    });
  };

  const handleAddBookmark = async (quickPromptId: string) => {
    try {
      handleToggleSet("0", true) // お気に入りを必ず開く
      await dispatch(addBookmark(quickPromptId));
    } catch (error) {
      console.error('ブックマークの追加に失敗しました:', error);
    }
  };

  const handleRemoveBookmark = async (quickPromptId: string) => {
    try {
      await dispatch(removeBookmark(quickPromptId));
    } catch (error) {
      console.error('ブックマークの削除に失敗しました:', error);
    }
  };


  return (
    <QpListPresenter
      sortedQuickPromptSets={quickPromptSets}
      expandedSets={expandedSets}
      handleToggleSet={handleToggleSet}
      bookmarkedQuickPromptIds={bookmarkedQuickPromptIds}
      handleAddBookmark={handleAddBookmark}
      handleRemoveBookmark={handleRemoveBookmark}
    />
  );
};

export default QpListContainer;