import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMembershipGroup } from '../membership-group.slice';
import { AppDispatch, RootState } from '@/store';
import { getCurrentSubscription, isAdmin } from '@/components/features/auth/auth.type';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { ResponsiveTableActionFooter, ResponsiveTableCell, ResponsiveTableRow, ResponsiveTableRowHeader } from '@/components/ui/ResponsiveComponents';
import { displayTimestamp } from '@/lib/utils';
import { MembershipGroup } from '../membership-group.type';
import MembershipGroupFormModal from '../components/MembershipGroupFormModal';

interface MembershipGroupListPresenterProps {
  membershipGroups: MembershipGroup[];
  membershipGroupNum: number;
  onScroll: () => void;
  hasMore: boolean;
}

const MembershipGroupListPresenter: React.FC<MembershipGroupListPresenterProps> = ({
  membershipGroups,
  membershipGroupNum,
  onScroll,
  hasMore,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMembershipGroup, setSelectedMembershipGroup] = useState<MembershipGroup | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  if (!loginUser) {
    return null;
  }
  const currentSub = getCurrentSubscription(loginUser);
  if (!currentSub) {
    return null;
  }

  const handleAddClick = () => {
    setSelectedMembershipGroup(null);
    setIsModalOpen(true);
  };

  const handleEditClick = (membershipGroup: MembershipGroup) => {
    setSelectedMembershipGroup(membershipGroup);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (id: string) => {
    if (window.confirm('本当に削除しますか？\n\n※ グループを削除しても所属メンバーは削除されません。')) {
      await dispatch(deleteMembershipGroup(id));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AdminPageTitle title="グループ管理" />
      <Divider />
      <AdminContentsWrapper id="scrollableDiv">
        <Typography variant="body2" color="textSecondary" gutterBottom>
          グループを追加・編集・削除できます。<br/>
          この機能を使うことで、メンバーをグループ単位で管理することができます。<br/>
          各メンバーをグループに追加したい場合は、メンバー管理画面から行ってください。
        </Typography>

        <Box sx={{ mt:'15px', mb: '25px' }}>
          <Tooltip title={null}>
            <span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddClick}
                style={{ marginRight: '20px' }}
              >
                追加
              </Button>
            </span>
          </Tooltip>
        </Box>
        {membershipGroupNum === 0 ? (
          <Typography variant="caption" align="left" color="textSecondary">
            グループはまだ追加されていません。
            追加ボタンから設定を追加しましょう。
          </Typography>
        ) : (
          <InfiniteScroll
            dataLength={membershipGroups.length}
            next={onScroll}
            hasMore={hasMore}
            loader={
              <div style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <CircularProgress />
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <TableContainer component={Paper}>
              <Table>
                {!isSmallScreen && (
                  <TableHead>
                    <TableRow>
                      <TableCell>名前</TableCell>
                      <TableCell>説明</TableCell>
                      <TableCell>作成日時</TableCell>
                      <TableCell>更新日時</TableCell>
                      <TableCell>操作</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {membershipGroups.map((group) => (
                    <ResponsiveTableRow key={group.id}>
                      <ResponsiveTableRowHeader>{group.name}</ResponsiveTableRowHeader>
                      <ResponsiveTableCell data-label="説明">{group.description}</ResponsiveTableCell>
                      <ResponsiveTableCell data-label="作成日時">{displayTimestamp(group.createdAt)}</ResponsiveTableCell>
                      <ResponsiveTableCell data-label="更新日時">{displayTimestamp(group.updatedAt)}</ResponsiveTableCell>
                      <ResponsiveTableActionFooter>
                        {isAdmin(loginUser) && (
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEditClick(group)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {isAdmin(loginUser) && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteClick(group.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ResponsiveTableActionFooter>
                    </ResponsiveTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        )}
      </AdminContentsWrapper>
      <MembershipGroupFormModal
        open={isModalOpen}
        handleClose={handleModalClose}
        defaultValues={selectedMembershipGroup || {
          name: "",
          description: ""
        }}
      />
    </>
  );
};

export default MembershipGroupListPresenter;
