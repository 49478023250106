import React from 'react';
import { CenteredMainAreaWrapper, ComponentWrapper, ContentsWrapper } from '@/components/ui/StyledComponents';

interface InfoPanelProps {
  children: React.ReactNode;
}

const CommonPanel: React.FC<InfoPanelProps> = ({ children }) => (
  <ContentsWrapper>
    <CenteredMainAreaWrapper>
      <ComponentWrapper>
        {children}
      </ComponentWrapper>
    </CenteredMainAreaWrapper>
  </ContentsWrapper>
);

export default CommonPanel;
