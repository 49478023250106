import { Mention } from "@/components/features/thread/thread.type";

export function convertTiptapDataToText(jsonData: object): string {
  let textOutput = '';
  for (const content of (jsonData as any).content) {
    if (content.type === 'paragraph') {
      if (Array.isArray(content.content)) {
        for (const item of content.content) {
          if (item.type === 'mention') {
            textOutput += `@${item.attrs.label}`;
          } else if (item.type === 'text') {
            textOutput += item.text;
          } else if (item.type === 'hardBreak') {
            textOutput += '\n';
          }
        }
      }
      textOutput += '\n\n';
    }
  }
  return textOutput;
}

export function convertTiptapDataToTextWithoutMention(jsonData: object): string {
  let textOutput = '';
  for (const content of (jsonData as any).content) {
    if (content.type === 'paragraph') {
      if (Array.isArray(content.content)) {
        for (const item of content.content) {
          if (item.type === 'text') {
            textOutput += item.text;
          } else if (item.type === 'hardBreak') {
            textOutput += '\n';
          }
        }
      }
      textOutput += '\n\n';
    }
  }
  return textOutput;
}

export function extractMentionIdsFromTiptapData(jsonData: object): string[] {
  const mentionIds: string[] = [];
  for (const item of (jsonData as any).content) {
    if (item.type === 'paragraph') {
      if (Array.isArray(item.content)) {
        for (const content of item.content) {
          if (content.type === 'mention') {
            mentionIds.push(content.attrs.id);
          }
        }
      }
    }
  }
  return mentionIds;
}

export function hasMentionInTiptapData(jsonData: object): boolean {
  return extractMentionIdsFromTiptapData(jsonData).length > 0;
}

export function isEmptyWithoutMention(jsonData: object): boolean {
  let text = convertTiptapDataToTextWithoutMention(jsonData);
  // トリム
  text = text.trim();
  // 全角空白を除去
  text = text.replace(/　/g, '');
  return text === '';
}

export function extractMentionOnlyFromTiptapData(jsonData: object): object {
  const mentionOnlyData: any = {
    type: 'doc',
    content: [],
  };

  for (const item of (jsonData as any).content) {
    if (item.type === 'paragraph') {
      const mentionOnlyParagraph: any = {
        type: 'paragraph',
        content: [],
      };

      if (Array.isArray(item.content)) {
        for (const content of item.content) {
          if (content.type === 'mention') {
            mentionOnlyParagraph.content.push(content);
            mentionOnlyParagraph.content.push({
              type: 'text',
              text: ' ',
            });
          }
        }
      }

      if (mentionOnlyParagraph.content.length > 0) {
        mentionOnlyData.content.push(mentionOnlyParagraph);
      }
    }
  }

  return mentionOnlyData;
}

export function createMentionOnlyData(mentions: Mention[]): object {
  const mentionOnlyData: any = {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [],
      },
    ],
  };

  for (const mention of mentions) {
    const mentionData = {
      type: 'mention',
      attrs: {
        id: mention.aiModel.code,
        label: mention.aiModel.name,
      },
    };

    const spaceData = {
      type: 'text',
      text: ' ',
    };

    mentionOnlyData.content[0].content.push(mentionData);
    mentionOnlyData.content[0].content.push(spaceData);
  }

  return mentionOnlyData;
}

