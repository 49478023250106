import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

/**
 * WorkAizer用の基本のTextField
 *
 * - 各種オートコンプリートをオフにしている (1passやlast pass含め)
 */
export const WaTextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    autoComplete="off"
    inputProps={{
      'data-1p-ignore': true,
      'data-lpignore': true,
      ...props.inputProps,
    }}
  />
));
