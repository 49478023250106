import React, { useRef } from 'react';
import { IconButton } from '@mui/material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { FileUpload } from '@/components/features/file/file.type';

interface FileUploadButtonProps {
  onFileSelect: (file: File) => void;
  currentFile?: File | FileUpload | undefined;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onFileSelect, currentFile }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const prevFileRef = useRef<File | FileUpload | undefined>(currentFile);

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  React.useEffect(() => {
    if (prevFileRef.current && !currentFile && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    prevFileRef.current = currentFile;
  }, [currentFile]);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
        multiple={false} // ファイルは一つのみ選択できるようにする
      />
      <IconButton color="primary" onClick={onClick}>
        <AddPhotoAlternateOutlinedIcon />
      </IconButton>
    </>
  );
};

export default FileUploadButton;
