import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { Box, Button, Divider, Typography } from '@mui/material';
import { displayDate, displayMonth, displayTimestamp, removeDecimalPart } from '@/lib/utils';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import SvTeamFormModal from '../components/SvTeamFormModal';
import { getSpecialCreditLabel } from '@/components/features/usage/usage.util';

const SvTeamSummaryContainer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  if (!team) {
    return null;
  }


  return (
    <>
      <SupervisorPageTitle title="サマリー" />
      <Divider />
      <SupervisorContentsWrapper>

        <Box sx={{ display: 'flex', flexDirection: 'row', border: '1px dashed grey', p: 2 }}>
          <Box sx={{ flex: 1, borderRight: '1px dashed grey', pr: 2 }}>
            <Typography variant="h6" gutterBottom>
              基本情報
              <Button
                variant="text"
                color="primary"
                style={{ marginLeft: '20px' }}
                onClick={() => setIsModalOpen(true)}
              >
                編集
              </Button>
            </Typography>

            <Typography variant="caption" gutterBottom>
              チームID
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.id}</Typography>

            <Typography variant="caption" gutterBottom>
              チーム名
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.name}</Typography>

            <Typography variant="caption" gutterBottom>
              会社名
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.companyName}</Typography>

            <Typography variant="caption" gutterBottom>
              ドメイン
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{team.primaryDomain}</Typography>

            <Typography variant="caption" gutterBottom>
              Salesforce商談URL
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              { team.salesforceUrl &&
                <a href={team.salesforceUrl} target="_blank">
                  {team.salesforceUrl}
                </a>
              }
            </Typography>

            <Typography variant="caption" gutterBottom>
              登録日時
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{displayTimestamp(team.createdAt)}</Typography>

            <Typography variant="caption" gutterBottom>
              登録ユーザー
            </Typography>
            <Box sx={{ mb: 2 }}>
              {team.creator ?
                <>
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    {team.creator.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ({team.creator.email})
                  </Typography>
                </> :
                <Typography variant="body1">
                  不明、または、削除済
                </Typography>
              }
            </Box>

            <Typography variant="caption" gutterBottom>
              メモ
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap'}}>{team.memo}</Typography>
          </Box>

          <Box sx={{ flex: 1, pl: 2 }}>
            <Box sx={{ borderBottom: '1px dashed grey', pb: 2 }}>
              <Typography variant="h6" gutterBottom>
                特権管理者
              </Typography>
              <Box>
                {team.memberships.map((membership) => (
                  <Box key={membership.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      {membership.user.name ? membership.user.name : <Typography variant="caption">招待中</Typography>}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ({membership.user.email})
                    </Typography>
                  </Box>
                ))}
                <Typography variant="caption">※ 最大5人まで表示</Typography>
              </Box>

            </Box>
            <Box sx={{ borderBottom: '1px dashed grey', pb: 2, pt: 2  }}>
              <Typography variant="h6" gutterBottom>
                契約内容
              </Typography>
              <Typography variant="caption" gutterBottom>
                プラン
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team.currentSubscription.plan.name}
                {!!team.scheduledSubscription && (
                  <Typography variant="caption" color="error" component="span">
                    {' '}(変更予約あり)
                  </Typography>
                )}
              </Typography>

              <Typography variant="caption" gutterBottom>
                現在の期間
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
              </Typography>

              <Typography variant="caption" gutterBottom>
                ライセンス数
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team.currentSubscription.currentLicense?.amount}
                {!!team.currentSubscription.scheduledLicense && (
                  <Typography variant="caption" color="error" component="span">
                    {' '}(変更予約あり)
                  </Typography>
                )}
              </Typography>
            </Box>

            <Box sx={{ pt: 2 }}>
              <Typography variant="h6" gutterBottom>
                最新の利用状況
              </Typography>
              <Typography variant="caption" gutterBottom>
                対象月
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {displayMonth(team.currentSubscription.latestMonthTerm[0])}&nbsp;&nbsp;
                <Typography variant="caption" >({displayDate(team.currentSubscription.latestMonthTerm[0])} 〜 {displayDate(team.currentSubscription.latestMonthTerm[1])})</Typography>
              </Typography>

              <Typography variant="caption" gutterBottom>
                クレジット利用率
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {removeDecimalPart(team.creditUsageRate, 3)}%
              </Typography>

              <Typography variant="caption" gutterBottom>
                残りクレジット
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {
                  getSpecialCreditLabel(team.creditRemaining) ||
                  <>
                    {removeDecimalPart(team.creditRemaining)}
                    &nbsp;&nbsp;／&nbsp;&nbsp;
                    {removeDecimalPart(team.quota.totalCredit)}
                  </>
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </SupervisorContentsWrapper>
      <SvTeamFormModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        defaultValues={team}
      />
    </>
  );
};

export default SvTeamSummaryContainer;
