import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentSubscription } from '../../auth/auth.type';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Box, Divider, Typography } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import { displayDate } from '@/lib/utils';
import { PlanType } from '../subscription.constant';

const CurrentSubscriptionDetail: React.FC = () => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  if (!currentSub) {
    return;
  }
  const term = currentSub.latestContractTerm

  return (
    <>
      <AdminPageTitle title="現在の契約内容" />
      <Divider />
      <AdminContentsWrapper>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '25px' }}>
          現在の契約内容を確認できます。
        </Typography>
        <Box mb={4}>
          <Typography variant="caption" gutterBottom>
            プラン
          </Typography>
          <Typography variant="body1">
            {currentSub.plan.name}
          </Typography>
          <br/>
          { currentSub.plan.type !== PlanType.STARTER && <>
            <Typography variant="caption" gutterBottom>
              現在の期間
            </Typography>
            <Typography variant="body1">{
              displayDate(term[0]) + ' ~ ' + displayDate(term[1])
            }</Typography>
            <br/>
            <Typography variant="caption" gutterBottom>
              ライセンス数
            </Typography>
            <Typography variant="body1">
              {currentSub.currentLicense.amount}ライセンス
            </Typography>
          </>}

        </Box>
      </AdminContentsWrapper>
    </>
  );
};

export default CurrentSubscriptionDetail;
