import React from 'react';
import { useContext } from 'react';
import { ErrorModalContext } from '../contexts/ErrorModalContext';
import ErrorModalPresenter from '../presenters/ErrorModalPresenter';

const ContextErrorModalContainer: React.FC = () => {
  const { isOpen, title, message, buttonOption, closeErrorModal } = useContext(ErrorModalContext);

  return (
    <ErrorModalPresenter
      isOpen={isOpen}
      title={title}
      message={message}
      buttonOption={buttonOption}
      onClose={closeErrorModal}
    />
  );
};

export default ContextErrorModalContainer;
