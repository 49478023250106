import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemText} from '@mui/material';

const SupervisorLeftSidebar: React.FC = () => {
  return (
    <List component="div" disablePadding>
      <NavLink to="/supervisor/teams">
        <ListItemButton>
          <ListItemText primary={"チーム管理"} />
        </ListItemButton>
      </NavLink>
      <NavLink to="/supervisor/news">
        <ListItemButton>
          <ListItemText primary={"お知らせ管理"} />
        </ListItemButton>
      </NavLink>
    </List>
  );
};

export default SupervisorLeftSidebar;
