import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import useLoading from '@/components/features/generic/hooks/useLoading';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import { useLogout } from '@/components/features/auth/hooks/useLogout';

const SlidePage = () => {
  const { isLoading, setIsLoading } = useLoading();
  const { setUnknownErrorModal, setErrorModal } = useErrorModal();
  const logout = useLogout();

  const handleClick = async () => {
    setIsLoading(true);
    try {
      // アクセストークンを取得
      const res1 = await apiAxios.get('/auth/access_token');
      const token = res1.data.accessToken;

      // Google APIを使って、現在のユーザーのスコープ情報を取得
      const res2 = await apiAxios.get('https://www.googleapis.com/oauth2/v1/tokeninfo', {
        params: {
          access_token: token,
        },
      });

      const scopes = res2.data.scope.split(' ');

      // 必要なスコープ（drive.file）が含まれているかチェック
      if (!scopes.includes('https://www.googleapis.com/auth/drive.file')) {
        // 含まれていない場合、再度ログインを促す
        setErrorModal(
          'ドライブへのアクセス権限が必要です',
          '続行するには、再度ログインし、Googleドライブへのアクセスを許可してください。',
          undefined,
          () => {
            console.log('called logout');
            console.trace();
            logout(); // 再度ログインを開始
          }
        );
        return;
      }

      await apiAxios.post('/debug/slide', {token});
      setIsLoading(false);
    } catch (err) {
      setUnknownErrorModal();
      captureException(err);
      console.error(err);
    }
  };

  return (
    <div style={{"paddingTop": 100}}>
      <button onClick={handleClick} disabled={isLoading}>
        Create Slide
      </button>
    </div>
  );
};

export default SlidePage;
