import { captureException } from "@sentry/react";

const SentryPage = () => {
  const methodNotExist = () => {
    throw new Error("Method does not exist");
  };

  const captureError = () => {
    try {
      throw new Error("Capture Error");
    } catch (error) {
      captureException(error);
    }
  }

  return (
    <div style={{"paddingTop": 100}}>
      <button onClick={methodNotExist}>Break the world</button>
      <br/><br/>

      <button onClick={captureError}>capture Error</button>
    </div>
  );
};

export default SentryPage;
