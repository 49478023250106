import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ja } from 'date-fns/locale/ja';
import { UnitType } from '../report.type';

const schema = z.object({
  unitType: z.nativeEnum(UnitType),
  termFrom: z.date(),
  termTo: z.date(),
})
.refine(
  data => {
    const diffDays = Math.ceil((data.termTo.getTime() - data.termFrom.getTime()) / (1000 * 60 * 60 * 24));
    switch (data.unitType) {
      case UnitType.DAY:
        return diffDays <= 65;
      case UnitType.WEEK:
        return diffDays <= 365;
      case UnitType.MONTH:
        return diffDays <= 365 * 30;
    }
  },
  {
    message: '期間が長すぎます。日単位の場合は65日以内、週単位の場合は1年以内、月単位の場合は30年以内にしてください。',
    path: ['termFrom'],
  }
)
.refine(
  data => data.termFrom <= data.termTo,
  {
    message: '期間の指定が不正です。開始日は終了日より前にしてください。',
    path: ['termFrom'],
  }
);

type FormData = z.infer<typeof schema>;

interface Props {
  onSubmit: (data: FormData) => void;
}

const ReportConditionForm: React.FC<Props> = ({ onSubmit }) => {
  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      unitType: UnitType.DAY,
      termTo: new Date(new Date().setDate(new Date().getDate() - 1)),
      termFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    },
  });

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const unitType = watch('unitType');

  const handleUnitTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newUnitType: UnitType | null
  ) => {
    if (newUnitType !== null) {
      setValue('unitType', newUnitType);
    }
  };

  const renderDatePicker = (
    name: "termFrom" | "termTo"
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            maxDate={new Date()}
          />
        )}
      />
    );
  };

  const onSuccess = (data: FormData) => {
    onSubmit(data);
  };

  const onError = (errors: any) => {
    setErrorMessage(errors.termFrom?.message || errors.termTo?.message || '');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <form onSubmit={handleSubmit(onSuccess, onError)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography>単位:</Typography>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={unitType}
              exclusive
              onChange={handleUnitTypeChange}
              aria-label="unit type"
            >
              <ToggleButton value={UnitType.DAY} aria-label="day">
                日
              </ToggleButton>
              <ToggleButton value={UnitType.WEEK} aria-label="week">
                週
              </ToggleButton>
              <ToggleButton value={UnitType.MONTH} aria-label="month">
                月
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item ml={1}>
            <Typography>期間:</Typography>
          </Grid>
          <Grid item>
            {renderDatePicker('termFrom')}
          </Grid>
          <Grid item>
            <Box>〜</Box>
          </Grid>
          <Grid item>
            {renderDatePicker('termTo')}
          </Grid>
          <Grid item ml={2}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
            >
              表示
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ReportConditionForm;
