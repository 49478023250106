import React from 'react';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import { RightSideBarWrapper } from '@/components/ui/StyledComponents';
import { TeamWithSubscription } from '../../team/team.type';
import { PLAN_UPGRADE_URL } from '@/common/constants';

interface Props {
  team: TeamWithSubscription;
  isUserAdmin: boolean;
}

const ThreadRightSidebarStarterPresenter: React.FC<Props> = ({team, isUserAdmin}) => {
  const quota = team.quota;
  const usage = team.usage;
  const currentCount = usage.aiGenerateCount
  const maxCount = quota.maxAiGenerateCount || 30;
  const usagePercentage = (usage.aiGenerateCount / maxCount) * 100;

  return (
    <RightSideBarWrapper>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          現在のプラン
        </Typography>
        <Typography variant="subtitle1" color="primary" paragraph>
          スタータープラン
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            利用回数: {currentCount} / {maxCount}
          </Typography>
          <LinearProgress variant="determinate" value={usagePercentage} />
          {currentCount >= maxCount && (
            <Typography variant="body2" color="error" mt={1}>
              スタータープランの利用可能回数に達しました
            </Typography>
          )}
        </Box>
        <Box mt={3}>
          <Typography variant="body2" color="text.secondary">
            スタータープランの制限:
          </Typography>
          <ul style={{ paddingLeft: 25 }}>
            <li>
              <Typography variant="body2" color="text.secondary">
                {maxCount}回まで利用可能
              </Typography>
              <ul style={{ paddingLeft: 20 }}>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    ドメイン単位
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    複数のメンションは、その分だけカウント
                  </Typography>
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="body2" color="text.secondary">
                1回あたり{quota.maxInputCharsLength}文字まで送信可
              </Typography>
            </li>
          </ul>
        </Box>
        <Box mt={3}>
          {
            isUserAdmin ?
            <>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                もっと沢山使いたい方、より高度なAIを利用したい方は、プランのアップグレードご検討ください。
              </Typography>
              <br/>
              <Button
                variant="contained"
                color="primary"
                href={PLAN_UPGRADE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                アップグレードに関する<br/>お問い合わせはこちら
              </Button>
            </>:
            <Typography variant="body2" color="text.secondary" gutterBottom>
              もっと沢山使いたい方、より高度なAIを利用したい方は、管理者にお問い合わせください。
            </Typography>
          }
        </Box>
      </Box>

      {/* <TipsContainer /> */}
    </RightSideBarWrapper>
  );
};

export default ThreadRightSidebarStarterPresenter;
