// import DummyAvatarImage from '@/assets/images/avatar/ai_models/dummy.svg';
import GeminiAvatar from '@/assets/images/avatar/ai_models/gemini.png';
// import GeminiAvatar from '@/assets/images/avatar/ai_models/Google Ai Gemini.png';
import GPTAvatar from '@/assets/images/avatar/ai_models/openai.png';
import ClaudeAvatar from '@/assets/images/avatar/ai_models/anthropic.svg';
import { FileType } from '../file/file.constant';

export const DEFAULT_AI_MODEL_ID = 'gemini-1.5-flash';

export type AIModelConst = {
  id: string;
  name: string;
  company: string;
  description: string;
  tags: string[];
  knowledge: Date,
  avatar: string;
  deprecated: boolean;
  uploadableFileTypes: FileType[]
};

export const AIModelConsts: AIModelConst[] = [
  // ----------------------------------------------
  // Google
  // ----------------------------------------------
  {
    id: 'gemini-1.0-pro',
    name: 'Gemini 1.0 Pro',
    company: 'Google',
    description: '',
    tags: [],
    avatar: GeminiAvatar,
    knowledge: new Date(2023, 2),
    deprecated: true,
    uploadableFileTypes: [],
  },
  {
    id: DEFAULT_AI_MODEL_ID,
    name: 'Gemini 1.5 Flash',
    company: 'Google',
    description: 'Googleの軽量AIモデル',
    tags: [
      "高速",
      "安価",
    ],
    avatar: GeminiAvatar,
    knowledge: new Date(2024, 5),
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.AUDIO,
      FileType.DOCUMENT,
      FileType.TEXT,
    ],
  },
  {
    id: 'gemini-1.5-pro',
    name: 'Gemini 1.5 Pro',
    company: 'Google',
    description: 'Googleの標準AIモデル',
    tags: [
      "高品質",
    ],
    knowledge: new Date(2024, 5),
    avatar: GeminiAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.AUDIO,
      FileType.DOCUMENT,
      FileType.TEXT,
    ],
  },
  // ----------------------------------------------
  // GPT
  // ----------------------------------------------
  {
    id: 'gpt-3.5-turbo',
    name: 'GPT-3.5 Turbo',
    company: 'OpenAI',
    description: '',
    tags: [],
    knowledge: new Date(2021, 9),
    avatar: GPTAvatar,
    deprecated: true,
    uploadableFileTypes: [
    ],
  },
  {
    id: 'gpt-4.0-omni-mini',
    name: 'GPT-4o mini',
    company: 'OpenAI',
    description: 'ChatGPTの軽量AIモデル',
    tags: [
      "高速",
      "安価",
    ],
    knowledge: new Date(2023, 8),
    avatar: GPTAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.TEXT,
    ],
  },
  {
    id: 'gpt-4.0-omni',
    name: 'GPT-4o',
    company: 'OpenAI',
    description: 'ChatGPTの標準AIモデル',
    tags: [
      "高品質",
    ],
    knowledge: new Date(2023, 8),
    avatar: GPTAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
  {
    id: 'gpt-4.0-turbo',
    name: 'GPT-4.0 Turbo',
    company: 'OpenAI',
    description: 'ChatGPTの従来AIモデル',
    tags: [
      "高品質",
      "高価",
    ],
    knowledge: new Date(2023, 10),
    avatar: GPTAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
  // ----------------------------------------------
  // Claude
  // ----------------------------------------------
  {
    id: 'claude-3-haiku',
    name: 'Claude 3 Haiku',
    company: 'Anthropic',
    description: 'Anthropicの軽量AIモデル',
    tags: [
       "高速",
       "安価",
    ],
    knowledge: new Date(2023, 8),
    avatar: ClaudeAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
  {
    id: 'claude-3-sonnet',
    name: 'Claude 3 Sonnet',
    company: 'Anthropic',
    description: '',
    tags: [],
    knowledge: new Date(2023, 8),
    avatar: ClaudeAvatar,
    deprecated: true,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
  {
    id: 'claude-3.5-sonnet',
    name: 'Claude 3.5 Sonnet',
    company: 'Anthropic',
    description: 'Anthropicの標準AIモデル',
    tags: [
      "高品質",
    ],
    knowledge: new Date(2024, 4),
    avatar: ClaudeAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
  {
    id: 'claude-3-opus',
    name: 'Claude 3 Opus',
    company: 'Anthropic',
    description: 'Anthropicの高級AIモデル',
    tags: [
      "最高品質",
      "高価",
    ],
    knowledge: new Date(2023, 8),
    avatar: ClaudeAvatar,
    deprecated: false,
    uploadableFileTypes: [
      FileType.IMAGE,
      FileType.TEXT,
    ],
  },
]

