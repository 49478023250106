export const MB = 1024 * 1024;

export enum FileAnalysisStatus {
    PENDING = 'pending', // 解析待ち
    PROCESSING = 'processing', // 解析中
    DONE = 'done', // 解析完了
    FAILED = 'failed' // 解析失敗
}

export enum FileStorageType {
    GCS_UPLOAD = 'gcs_upload',
    BASE64_IMAGE = 'base64_image',
    TEXT = 'text'
}

export enum FileType {
    IMAGE = 'image', // 画像
    VIDEO = 'video', // 動画
    AUDIO = 'audio', // 音声
    DOCUMENT = 'document', // ドキュメント
    TEXT = 'text' // テキスト
}


export const UploadableTxtMimeTypes = [
  "text/plain",
  "text/csv",
  "text/markdown",
];

export const UploadableImageMimeTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const UploadableVideoMimeTypes = [
  "video/mp4",
  "video/webm",
  "video/mkv",
  "video/quicktime",
];

export const UploadableAudioMimeTypes = [
  "audio/mpeg",
  "audio/wav",
  "audio/webm",
  "audio/mp4",
  "audio/opus",
  "audio/aac",
  "audio/flac",
  "audio/pcm"
];

export const UploadableDocumentMimeTypes = [
  "application/pdf",
];

export const UploadableTxtMaxMb = 1;
export const UploadableImageMaxMb = 5;
export const UploadableVideoMaxMb = 100;
export const UploadableAudioMaxMb = 100;
export const UploadableDocumentMaxMb = 20;

export const MimeTypeToName = {
  "image/jpeg": "JPEG",
  "image/jpg": "JPG",
  "image/png": "PNG",
  "image/webp": "WEBP",
  "video/mp4": "MP4",
  "video/webm": "WEBM",
  "video/mkv": "MKV",
  "video/quicktime": "MOV",
  "audio/mpeg": "MP3",
  "audio/mpga": "MPGA",
  "audio/wav": "WAV",
  "audio/webm": "WEBM",
  "audio/mp4": "M4A",
  "audio/opus": "OPUS",
  "audio/aac": "AAC",
  "audio/flac": "FLAC",
  "audio/pcm": "PCM",
  "application/pdf": "PDF",
  "text/plain": "TXT",
  "text/csv": "CSV",
  "text/markdown": "Markdown",
};
