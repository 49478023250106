export function joinPath(...paths: string[]): string {
    if (paths.length === 0) return '';

    // パスを結合
    const combined = paths.join('/');

    // URLのスキームを保護するための正規表現
    const schemeRegex = /^https?:\/\//;

    // スキームを除外してからスラッシュの正規化を行う
    const normalized = combined.replace(schemeRegex, '').replace(/\/+/g, '/');

    // スキームがあれば再度追加
    const resultWithScheme = combined.match(schemeRegex) ? combined.match(schemeRegex)![0] + normalized : normalized;

    // 先頭がスラッシュで始まる場合、またはURLの場合はスラッシュを保持
    const preserveLeadingSlash = paths[0].startsWith('/') || paths[0].startsWith('http://') || paths[0].startsWith('https://');
    const preserveTrailingSlash = paths[paths.length - 1].endsWith('/');

    // 先頭のスラッシュを処理
    let result = preserveLeadingSlash ? resultWithScheme : resultWithScheme.replace(/^\//, '');

    // 末尾のスラッシュを処理
    result = preserveTrailingSlash ? result : result.replace(/\/$/, '');

    return result;
}

export function displayTimestamp(date?: string | Date): string {
    if (!date) return '';
    const dateObj = date instanceof Date ? date : new Date(date);
    if (isNaN(dateObj.getTime())) return '';
    return dateObj.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}

export function displayDate(date?: string | Date): string {
    if (!date) return '';
    const dateObj = date instanceof Date ? date : new Date(date);
    if (isNaN(dateObj.getTime())) return '';
    return dateObj.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}

export function displayMonth(date?: string | Date): string {
    if (!date) return '';
    const dateObj = date instanceof Date ? date : new Date(date);
    if (isNaN(dateObj.getTime())) return '';
    return dateObj.toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit'
    });
}
export function formatDateHyphen(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export function removeDecimalPart(value: string | undefined, digits: number = 0): string {
    if (!value) return '';

    if (digits === 0) {
        return value.split('.')[0];
    }
    const regex = new RegExp(`(\\d+\\.\\d{${digits}})\\d*`);
    return value.replace(regex, '$1');
}

/**
 * Decimal文字列の小数点以下部分を調整する
 *
 * - 1未満の場合は、小数点2桁まで表示
 * - 10未満の場合は、小数点1桁まで表示
 * - それ以外の場合は、整数のみ表示
 */
export function formatDecimal(value: string | undefined): string {
    if (!value) return '';

    const num = parseFloat(value);
    if (num < 1) {
        return num.toFixed(2);
    } else if (num < 10) {
        return num.toFixed(1);
    } else {
        return Math.round(num).toString();
    }
}

/**
 * 引数の文字列が指定文字数より長い場合は「…」をつける
 */
export function truncate(str: string, length: number): string {
    if (str.length <= length) return str;
    return str.slice(0, length) + '…';
}

/**
 * スレッド詳細ページか判定する
 */
export function isThreadDetailPage(): boolean {
    const currentUrl = window.location.pathname;
    const threadUrlPattern = /\/threads\/[a-zA-Z0-9]+/;
    return threadUrlPattern.test(currentUrl);
}

