import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, FormHelperText, Tooltip,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { MembershipCreate, MembershipUpdate } from '../membership.type';
import { createMembership, updateMembership } from '../membership.slice';
import { AppDispatch } from '@/store';
import { MembershipRole, getMembershipRoleDescription, getMembershipRoleLabel } from "../membership.constant";
import { isSuperAdmin } from '@/components/features/auth/auth.type';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { MembershipGroup } from '../../membership-group/membership-group.type';
import { WaTextField } from '@/components/ui/WaTextField';


type MembershipSave = MembershipCreate | MembershipUpdate;

interface MembershipFormModalProps {
  open: boolean;
  handleClose: () => void;
  defaultValues: MembershipSave;
  membershipGroups: MembershipGroup[];
}

const MembershipFormModal: React.FC<MembershipFormModalProps> = ({
  open,
  handleClose,
  defaultValues,
  membershipGroups,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
    defaultValues: defaultValues,
  });
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  if(!loginUser) {
    return null;
  }

  // 編集可否か
  const isEdit = 'id' in defaultValues;

  const onSubmit = async (data: MembershipSave) => {
    setIsLoading(true);
    try {
      if (isEdit) {
        await dispatch(updateMembership(data as MembershipUpdate));
      } else {
        await dispatch(createMembership(data as MembershipCreate));
      }
    }
    catch (error) {
      console.error('MembershipFormModal onSubmit error:', error);
    }
    finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const roleOptions = isSuperAdmin(loginUser)
    ? Object.values(MembershipRole).map((role) => ({
        label: getMembershipRoleLabel(role),
        value: role,
        tooltip: getMembershipRoleDescription(role)
      }))
    : [
        {
          label: getMembershipRoleLabel(MembershipRole.NORMAL),
          value: MembershipRole.NORMAL,
          tooltip: getMembershipRoleDescription(MembershipRole.NORMAL)
        },
      ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEdit ? 'メンバー更新' : 'メンバー追加'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isEdit && (
            <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.emails)}>
              <WaTextField
                label="メールアドレス"
                variant="outlined"
                fullWidth
                autoComplete="off"
                {...register('emails', {
                  required: 'メールアドレスは必須です',
                  validate: (value: string) => {
                    const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

                    const invalidEmails = emails.filter(email => !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)));
                    if (invalidEmails.length > 0) {
                      return '正しいメールアドレス形式で入力してください';
                    }
                    const longUsernames = emails.filter(email => {
                      const [username] = email.split('@');
                      return username.length > 37;
                    });
                    if (longUsernames.length > 0) {
                      return 'ユーザー名(@の左の部分)は37文字以内で入力してください';
                    }
                    return true;
                  },
                })}
                error={!!errors.emails}
                helperText={errors.emails?.message}
              />
              <FormHelperText>※ カンマ区切りで複数入力可能です。</FormHelperText>
            </FormControl>
          )}
          { membershipGroups.length > 0 &&
            <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.groupId)} style={{ marginTop: '20px' }}>
              <FormLabel component="legend">所属グループ</FormLabel>
              <Controller
                name="groupId"
                control={control}
                defaultValue={defaultValues.groupId || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    displayEmpty
                  >
                    <MenuItem value="">未所属</MenuItem>
                    {membershipGroups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          }
          {isSuperAdmin(loginUser) && (
            <FormControl component="fieldset" error={Boolean(errors.role)} style={{ marginTop: '20px' }}>
              <FormLabel component="legend">ロール</FormLabel>
              <Controller
                name="role"
                control={control}
                rules={{ required: 'ロールの選択は必須です' }}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {roleOptions.map((option) => (
                      <Tooltip title={option.tooltip} key={option.value} >
                        <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                      </Tooltip>
                    ))}
                  </RadioGroup>
                )}
              />
              {errors.role && <FormHelperText>{errors.role.message}</FormHelperText>}
            </FormControl>
          )}
          <DialogActions style={{ marginTop: '20px' }}>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : '保存'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipFormModal;

