import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, isSuperAdmin } from '../../auth/auth.type';
import SharedCSVDownloadPresenter from '../presenters/SharedCSVDownloadPresenter';
import axios from 'axios';
import { downloadCsv } from '@/lib/csv';
import useLoading from '../../generic/hooks/useLoading';


const MessageCSVDownloadContainer: React.FC = () => {
  const { setIsLoading } = useLoading();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const membership = getCurrentMembership(loginUser);

  if (!(
    loginUser &&
    membership &&
    isSuperAdmin(loginUser) &&
    membership.team.quota.vaultEnabled
  )) {
    return null;
  }

  const handleDownload = async (termFrom: string, termTo: string) => {
    setIsLoading(true);
    try {
      await downloadCsv('/vault/messages/csv/download', { termFrom, termTo });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        if (error.response.data.errorDetail === 'your_team_is_not_vault_enabled') {
          window.alert('現在のプランではこの機能を使用できません。');
        } else {
          window.alert('ダウンロードに失敗しました。');
        }
      } else {
        window.alert('エラーが発生しました。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return <SharedCSVDownloadPresenter
    title="VAULT メッセージ履歴"
    description='投稿されたメッセージや生成された文章の履歴をCSVでダウンロードすることができます。'
    description2='各メンバーが具体的にどんなメッセージを投稿してるか、AIがどんな文章を生成しているか知りたい場合にご利用ください。'
    onDownload={handleDownload}
  />;
};

export default MessageCSVDownloadContainer;
