import React from "react";
import { Button, Typography, Link, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styled from "@emotion/styled";
import { CenteredMainAreaWrapper, ContentsWrapper } from "@/components/ui/StyledComponents";
import theme from "@/theme";

const LoginPanel = styled(CenteredMainAreaWrapper)`
  justify-content: top;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

const LoginTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 24px;
`;

const LoginDescription = styled(Typography)`
  margin-bottom: 16px;
`;

const LoginButton = styled(Button)`
  margin-top: 24px;
`;

export const AlertTypography = styled(({ component, ...props }: { component: React.ElementType, [key: string]: any }) => <Typography component={component} {...props} />)`
  &&& {
    color: ${theme.palette.warning.main};
  }
`;

interface LoginContainerProps {
  login: () => void;
}

const trial_entry_url : string = import.meta.env.VITE_TRIAL_ENTRY_URL;

const LoginPresenter: React.FC<LoginContainerProps> = ({ login }) => {
  const dot = (
    <ListItemIcon style={{ minWidth: "24px", marginRight: "8px" }}>
      <FiberManualRecordIcon style={{ fontSize: "8px" }} />
    </ListItemIcon>
  );

  return (
    <ContentsWrapper>
      <LoginPanel>
        <LoginTitle variant="h4">WorkAIzerへようこそ</LoginTitle>
        <LoginDescription variant="body1">
          本サービスをご利用いただくにあたり、以下の点にご留意ください。
        </LoginDescription>
        <List>
          <ListItem>
            { dot }
            <ListItemText primaryTypographyProps={{ variant: "body2" }}
              primary={
                <>
                  <Typography component="span">本サービスは、原則 Google Workspace アカウントでのみご利用が可能です。</Typography><br/>
                </>
              }
              secondary={
                <>
                  (その他、Googleアカウントをお使いの方は
                    <Link href={trial_entry_url} target="_blank" rel="noopener">
                      こちら
                    </Link>
                  よりお問い合わせください)
                </>
              }
            />

          </ListItem>
          <ListItem>
            { dot }
            <ListItemText
              primaryTypographyProps={{ variant: "body2" }}
              primary={
                <Typography component="span">
                  トライアルのご利用には、事前に
                  <Link href={trial_entry_url} target="_blank" rel="noopener">
                    トライアルお申込みフォーム
                  </Link>
                  でのご登録が必要です。
                </Typography>
              }
              secondary="(ご登録が確認できない場合、トライアルを停止させていただく場合がございます)"
            />
          </ListItem>
        </List>
        <LoginButton variant="contained" color="primary" size="large" onClick={login}>
          Googleアカウントでログイン
        </LoginButton>
      </LoginPanel>
    </ContentsWrapper>
  );
};

export default LoginPresenter;
