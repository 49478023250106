import apiAxios from '@/lib/axios';
import React, { useState } from 'react';
import SupervisorPageTitle from '../../base/components/SupervisorPageTitle';
import { Box, Button, Divider, Typography } from '@mui/material';
import { SupervisorContentsWrapper } from '../../base/components/SupervisorUI';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import HeaderLogo from '@/components/features/header/components/HeaderLogo';

const SvLogoCustomization = () => {
  const [file, setFile] = useState<File | null>(null);
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  if (!team) {
    return null;
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"];
      const maxSize = 100_000; // 500KB

      if (!allowedTypes.includes(selectedFile.type)) {
        alert('無効なファイル形式です。PNG、JPEG、SVG形式のファイルを選択してください。');
        return;
      }

      if (selectedFile.size > maxSize) {
        alert('ファイルサイズが大きすぎます。100KB以下のファイルを選択してください。');
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiAxios.put(
        `/supervisor/teams/${team.id}/logo`,
        formData
      );
      if (response.status === 200) {
        window.alert(
          '[カスタマイズ成功]\n\n' +
          'ロゴのカスタマイズが正常に完了しました。\n画面をリロードします。'
        );
        window.location.reload();
      } else {
        alert('ファイルのアップロードに失敗しました。');
      }
    } catch (error) {
      alert('ファイルのアップロードに失敗しました。');
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('削除しますよろしいですか？');
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await apiAxios.delete(
        `/supervisor/teams/${team.id}/logo`
      );
      if (response.status === 200) {
        window.alert(
          '[カスタマイズ解除]\n\n' +
          'ロゴのカスタマイズが正常に解除されました。\n画面をリロードします。'
        );
        window.location.reload();
      } else {
        alert('ファイルの削除に失敗しました。');
      }
    } catch (error) {
      alert('ファイルの削除に失敗しました。');
    }
  };

  return (
    <>
      <SupervisorPageTitle title="ロゴカスタマイズ" />
      <Divider />
      <SupervisorContentsWrapper>
        <Box mb={5}>
          <Typography variant="h6" gutterBottom>
            現在ヘッダーロゴの表示
          </Typography>
          <Box sx={{ mb: 3 }}>
            <HeaderLogo logo={team.logo} />
          </Box>
          <Typography variant="body2" gutterBottom>
            {team.logo ?
              <>
              現在カスタマイズされたロゴが表示されています。<br/>
              ロゴを変更する場合は、新しいロゴをアップロードしてください。<br/>
              通常のWorkAIzerのロゴに戻す場合は、下部の「削除する」ボタンをクリックしてください。
              </>:
              <>
              現在ロゴはカスタマイズされていません。<br/>
              通常のWorkAIzerのロゴが表示されます。
              </>
            }
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          ロゴをアップロードしてください
        </Typography>
        <form onSubmit={handleSubmit} style={{ marginBottom: '50px' }}>
          <Box mb={2}>
            <Box mb={2}>
              {file && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    選択されたファイル: {file.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    ファイルサイズ: {(file.size / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              )}
            </Box>

            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="upload-button"
            />
            <label htmlFor="upload-button">
              <Button variant="outlined" size="small" component="span" color="primary">
                ファイルを選択
              </Button>
            </label>
            <Typography variant="caption" display="block" gutterBottom>
              ※ 100KBまで。推奨: 縦幅52px 横幅190〜230px
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!file}
          >
            アップロードしてロゴを変更する
          </Button>
        </form>
        {
          team.logo && <>
            <Typography variant="h6" gutterBottom>
              設定済みのロゴを削除する
            </Typography>
            <Button
              onClick={handleDelete}
              variant="outlined"
              color="error"
            >
              削除する
            </Button>
          </>
        }
      </SupervisorContentsWrapper>
    </>
  );
};

export default SvLogoCustomization;
