import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MembershipListPresenter from '../presenters/MembershipListPresenter';
import { RootState } from '@/store';
import { MembershipManagedList } from '../membership.type';
import apiAxios from '@/lib/axios';
import { appendMemberships, clearMemberships } from '../membership.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import useLoading from '../../generic/hooks/useLoading';
import { MembershipGroupList } from '../../membership-group/membership-group.type';
import { appendMembershipGroups } from '../../membership-group/membership-group.slice';

const LIMIT = 20;

const MembershipListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const memberships = useSelector((state: RootState) => state.membership.memberships);
  const membershipNum = useSelector((state: RootState) => state.membership.num);
  const [hasMore, setHasMore] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const { setUnknownErrorModal } = useErrorModal();
  const { setIsLoading } = useLoading();
  const membershipGroups = useSelector((state: RootState) => state.membershipGroup.membershipGroups);
  const membershipGroupTotalNum = useSelector((state: RootState) => state.membershipGroup.num);

  // 最初にグループ一覧を全件読み込む
  useEffect(() => {
    const fetchMembershipGroups = async () => {
      if (
        membershipGroupTotalNum == -1 ||
        membershipGroups.length < membershipGroupTotalNum
      ) {
        setIsLoading(true);
        const response = await apiAxios.get<MembershipGroupList>('/membership-groups', {
          params: {
            offset: membershipGroups.length,
            limit: 20,
          },
        });
        dispatch(appendMembershipGroups(response.data));
      } else {
        setIsLoading(false);
      }
    };
    fetchMembershipGroups();
  }, [membershipGroups.length, membershipGroupTotalNum, dispatch, setIsLoading]);


  const handleScroll = async () => {
    try {
      const response = await apiAxios.get<MembershipManagedList>('/memberships', {
        params: {
          offset: memberships.length,
          limit: LIMIT,
          groupId: selectedGroupId,
        },
      });
      dispatch(appendMemberships(response.data));
      if (response.data.memberships.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('Failed to fetch more memberships:', error);
    }
  };

  useEffect(() => {
    handleScroll();
  }, [selectedGroupId]);

  const onChangeSelectedGroupId = (groupId: string) => {
    setSelectedGroupId(groupId);
    dispatch(clearMemberships())
  }

  return (
    <MembershipListPresenter
      memberships={memberships}
      membershipNum={membershipNum}
      membershipGroups={membershipGroups}
      onScroll={handleScroll}
      selectedGroupId={selectedGroupId}
      onChangeSelectedGroupId={onChangeSelectedGroupId}
      hasMore={hasMore}
    />
  );
};

export default MembershipListContainer;
