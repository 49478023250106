import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { AIMessage, MessageNode } from '../thread.type';
import { AIMessageStatus } from '../thread.constant';
import ThreadPostAiMessage from '../containers/ThreadPostAiMessage';

interface ThreadAiPostCompareProps {
  open: boolean;
  onClose: () => void;
  messageNode: MessageNode;
  aiMessages: AIMessage[];
}

const ModalContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 90vh;
  max-height: 90vh;
`;

const PostsRow = styled.div<{ rowSize: number }>`
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: ${({ rowSize }) => `calc(100% / ${rowSize} - 20px)`};
`;

const PostWrapper = styled.div<{ colSize: number }>`
  flex: 1;
  max-width: ${({ colSize }) => `calc(100% / ${colSize} - 13px)`};
`;

const ThreadAiPostCompare: React.FC<ThreadAiPostCompareProps> = ({ open, onClose, messageNode, aiMessages }) => {
  const renderPosts = () => {
    const rows: AIMessage[][] = [];

    const rowSize = aiMessages.length <= 3 ? 1 :
                    aiMessages.length <= 6 ? 2 :
                    Math.floor(aiMessages.length/3);
    const colSize = aiMessages.length <= 3 ? aiMessages.length :
                    aiMessages.length == 4 ? 2 : 3;

    for (let i = 0; i < aiMessages.length; i += colSize) {
      rows.push(aiMessages.slice(i, i + colSize));
    }

    return rows.map((row, index) => {
      return (
        <PostsRow key={index} rowSize={rowSize}>
          {row.map((aiMsg) => (
            <PostWrapper key={aiMsg.id} colSize={colSize}>
              <ThreadPostAiMessage
                node={messageNode}
                aiMessage={aiMsg}
                minimizeHeaderInfo={true}
                scroll={true}
              />
            </PostWrapper>
          ))}
        </PostsRow>
      );
    });
  };

  const totalNum = aiMessages.length;
  const finishedNum = aiMessages.filter((msg) => msg.status === AIMessageStatus.FINISHED).length;
  const failedNum = aiMessages.filter((msg) => msg.status === AIMessageStatus.FAILED).length;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        [回答状況]&nbsp;&nbsp;{finishedNum}&nbsp;&nbsp;/&nbsp;&nbsp;{totalNum}&nbsp;&nbsp;{failedNum > 0 ? `(失敗: ${failedNum})` : ''}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ModalContent>{renderPosts()}</ModalContent>
    </Dialog>
  );
};

export default ThreadAiPostCompare;


