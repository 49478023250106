import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { ReqScheduleLicenseChange } from '../subscription.type';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';

const schema = z.object({
  licenseAmount: z.number().min(0, "0以上の数値を入力してください").int(),
  applyStartedOn: z.string().refine((date) => new Date(date) >= new Date(), "明日以降の日付を選択してください"),
  memo: z.string().max(5000).optional(),
});

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: ReqScheduleLicenseChange) => void;
}

const SvSubscriptionLicenseFormModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  const currentSub = team?.currentSubscription;
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      licenseAmount: currentSub?.latestLicense.amount || 0,
      applyStartedOn: currentSub?.latestContractTerm[1] || "",
      memo: "",
    },
  });

  if (!(team && currentSub)) {
    return null;
  }

  const onFormSubmit = async (data: ReqScheduleLicenseChange) => {
    onSubmit(data);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>ライセンス数変更</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ mb: 5 }}>
          <Typography variant="h6" gutterBottom>
              現在の内容
          </Typography>
          <Table style={{ width: 600 }}>
            <TableHead>
              <TableRow>
                <TableCell>プラン</TableCell>
                <TableCell>期間</TableCell>
                <TableCell>ライセンス数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {currentSub.plan.name}
                  {!!team.scheduledSubscription && (
                    <Typography variant="caption" color="error" component="span">
                      {' '}(変更予約あり)
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
                </TableCell>
                <TableCell>
                  {currentSub.currentLicense?.amount}
                  {!!currentSub.scheduledLicense && (
                    <Typography variant="caption" color="error" component="span">
                      {' '}(変更予約あり)
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Typography variant="h6" gutterBottom>
              変更内容
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.licenseAmount)}>
            <WaTextField
              type="number"
              label="ライセンス数"
              {...register('licenseAmount', { valueAsNumber: true })}
              error={!!errors.licenseAmount}
              helperText={errors.licenseAmount?.message}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.applyStartedOn)}>
            <WaTextField
              label="適用開始日"
              type="date"
              {...register('applyStartedOn')}
              error={!!errors.applyStartedOn}
              helperText={errors.applyStartedOn?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.memo)}>
            <WaTextField
              label="メモ"
              multiline
              rows={4}
              {...register('memo')}
              error={!!errors.memo}
              helperText={errors.memo?.message}
            />
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              保存
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SvSubscriptionLicenseFormModal;
