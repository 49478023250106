import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, DialogProps } from '@mui/material';

type ErrorModalPresenterProps = {
  isOpen: boolean;
  title: string | null;
  message: string | null;
  buttonOption: { title: string; url: string } | null | undefined;
  onClose: () => void;
};

const ErrorModalPresenter: React.FC<ErrorModalPresenterProps> = ({
  isOpen,
  title,
  message,
  buttonOption,
  onClose,
}) => {
  const [maxWidth, _] = React.useState<DialogProps['maxWidth']>('sm');

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message && message.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonOption && (
          <Button href={buttonOption.url} target="_blank" rel="noopener noreferrer">
            {buttonOption.title}
          </Button>
        )}
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModalPresenter;
