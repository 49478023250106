import React, { createContext, useState } from 'react';
import { AlertColor } from '@mui/material';

type ToastContextProps = {
  isOpen: boolean;
  message: string;
  severity?: AlertColor;
  showToast: (message: string, severity?: AlertColor) => void;
  hideToast: () => void;
};

export const ToastContext = createContext<ToastContextProps>({
  isOpen: false,
  message: '',
  showToast: () => {},
  hideToast: () => {},
});

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor | undefined>('info');

  const showToast = (message: string, severity?: AlertColor) => {
    setMessage(message);
    setSeverity(severity);
    setIsOpen(true);
  };

  const hideToast = () => {
    setIsOpen(false);
  };

  return (
    <ToastContext.Provider value={{ isOpen, message, severity, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};
