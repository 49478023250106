import { AlertColor } from "@mui/material"
import { captureException } from "@sentry/react"


export const copyToClipboard = (
  textPlain: string,
  textHtml?: string,
  showToast?: (message: string, severity?: AlertColor | undefined) => void,
) => {
  const blobPlain = new Blob([textPlain], { type: 'text/plain' })
  const items: { [mimeType: string]: Blob } = { 'text/plain': blobPlain }

  if (textHtml) {
    const blobHtml = new Blob([textHtml], { type: 'text/html' })
    items['text/html'] = blobHtml
  }
  const item = [new window.ClipboardItem(items)]

  try {
    navigator.clipboard.write(item);
    if (showToast) showToast('コピーしました', 'success');
  } catch (err) {
    console.error('Failed to copy element to clipboard', err);
    captureException(err);
    if (showToast) showToast('コピーに失敗しました', 'error');
  }
}

export const getOuterHtmlFromElement = (element: HTMLElement) => {
  const range = document.createRange();
  range.selectNode(element);
  window.getSelection()?.removeAllRanges();
  window.getSelection()?.addRange(range);
  const textHtml = element.outerHTML;
  window.getSelection()?.removeAllRanges();
  return textHtml;
}
