import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store';
import { fetchNews } from '../news.slice';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { displayDate } from '@/lib/utils';

const LatestNewsPanel: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const newsList = useSelector((state: RootState) => state.news.newsList);

  useEffect(() => {
    if (newsList.length === 0) {
      dispatch(fetchNews({ limit: 1 }));
    }
  }, [dispatch, newsList.length]);

  const news = newsList[0];

  if (!news) return null;

  const handleNewsClick = () => {
    const url = news.type === 'internal' ? `/news/${news.id}` : news.externalUrl;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // 公開して3日はNew表示
  const today = new Date();
  const publishedAt = new Date(news.publishedAt);
  const diff = today.getTime() - publishedAt.getTime();
  const isNew = diff < 1000 * 60 * 60 * 24 * 3;

  return (
    <Box>
      <Typography variant="subtitle2" color="textSecondary" sx={{marginLeft: "7px" }}>
        最新のお知らせ
      </Typography>
      <Button
        onClick={handleNewsClick}
        sx={{
          display: 'block',
          width: '100%',
          textAlign: 'left',
        }}
      >
        {
          (news.isImportant || isNew) &&
          <Typography variant="caption" color="#FF4D4D" sx={{paddingRight:1}}>
            {news.isImportant ? "[ 重要 ]" : "New"}
          </Typography>
        }
        <Typography variant="caption" color="textSecondary">
          {displayDate(news.publishedAt)}
        </Typography><br/>
        <Typography variant="body2" color="textSecondary">
          {news.title}
        </Typography>
      </Button>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          onClick={() => navigate('/news')}
          size="small"
        >
          すべてのお知らせを見る
        </Button>
      </Box>
    </Box>
  );
};

export default LatestNewsPanel;
