import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom';
import HeaderUserMenuPresenter from '../presenters/HeaderUserMenuPresenter';
import { useLogout } from '../../auth/hooks/useLogout';
import { getJoinMemberships, isAdmin, isSystemSupervisor } from '../../auth/auth.type';
import { USER_MANUAL_URL } from '@/config';

const HeaderUserMenuContainer: React.FC = () => {
  const navigate = useNavigate();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const logout = useLogout();
  // ユーザーメニューの表示制御
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  // ログインしていない場合は何も表示しない
  if (!loginUser) {
    return <></>
  }

  const closeMenuAndGo = (path_or_url: string, otherSite: boolean) => {
    return () => {
      handleMenuClose();
      if (otherSite) {
        window.open(path_or_url, '_blank');
      } else {
        navigate(path_or_url);
      }
    };
  }

  const handleLogout = () => {
    logout();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const menuItems = [];
  // menuItems.push({ label: '個人設定', onClick: handleConfigClick });
  if (isAdmin(loginUser)) {
    menuItems.push({ label: '管理メニュー', onClick: closeMenuAndGo('/admin', false) });
  }
  if (getJoinMemberships(loginUser).length > 1) {
    menuItems.push({ label: 'チーム切り替え', onClick: closeMenuAndGo('/teams/select', false) });
  }
  if (isSystemSupervisor(loginUser)) {
    menuItems.push({ label: '＜＜システム運営＞＞', onClick: closeMenuAndGo('/supervisor', false) });
  }
  menuItems.push({ label: 'ユーザーマニュアル', onClick: closeMenuAndGo(USER_MANUAL_URL, true) });
  menuItems.push({ label: 'ログアウト', onClick: handleLogout });


  return (
    <HeaderUserMenuPresenter
      loginUser={loginUser}
      isMenuOpen={isMenuOpen}
      anchorEl={anchorEl}
      menuItems={menuItems}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
};

export default HeaderUserMenuContainer;