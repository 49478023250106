import * as z from "zod"

export const newsInputSchema = z.object({
  id: z.string().nullable().optional(),
  title: z.string().min(1, { message: "必須" }),
  type: z.enum(["internal", "external"]),
  publishedAt: z.string(),
  body: z.string().nullable().optional(),
  externalUrl: z.string().url().nullable().optional().or(z.literal('')),
  isImportant: z.boolean().optional().or(z.literal(false)),
  isHidden: z.boolean().optional().or(z.literal(false)),
}).refine(
  (data) => {
    if (data.type === "internal") {
      return !!data.body;
    }
    return true;
  },
  {
    message: "必須",
    path: ["body"],
  }
).refine(
  (data) => {
    if (data.type === "external") {
      return !!data.externalUrl;
    }
    return true;
  },
  {
    message: "必須",
    path: ["externalUrl"],
  }
);

export type NewsInput = z.infer<typeof newsInputSchema>

export interface News extends NewsInput {
  id: string;
  createdAt: string;
}
