import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AIMessage, SseEventUpdateThreadTitle, SseEventAddMessageToAIMessage, SseEventMessageNodesAdded, Thread, ThreadWithUsage } from './thread.type';
import { ThreadUsage } from '../usage/usage.type';

interface ThreadState {
  currentThread: ThreadWithUsage | null;
  threads: Thread[];
  threadOnGenerating: boolean;
}

const initialState: ThreadState = {
  currentThread: null,
  threads: [],
  threadOnGenerating: false,
};

const threadSlice = createSlice({
  name: 'thread',
  initialState,
  reducers: {
    // ----------------------------------------------
    // スレッド一覧
    // ----------------------------------------------
    setThreads: (state, action: PayloadAction<Thread[]>) => {
      state.threads = action.payload;
    },
    appendThreads: (state, action: PayloadAction<Thread[]>) => {
      // idが同じものは追加しない
      action.payload.forEach((thread) => {
        if (!state.threads.some((t) => t.id === thread.id)) {
          state.threads.push(thread);
        }
      });
    },
    addThreadToTop: (state, action: PayloadAction<Thread>) => {
      state.threads.unshift(action.payload);
    },
    addTitleToCurrentThread: (state, action: PayloadAction<SseEventUpdateThreadTitle>) => {
      const thread = action.payload.thread
      const id = thread.id;
      const title = thread.title;

      if (state.currentThread) {
        state.currentThread.title = title;
      }

      const threadIndex = state.threads.findIndex(t => t.id === id);
      if (threadIndex !== -1) {
        state.threads[threadIndex].title = title;
      }
    },
    // ----------------------------------------------
    // 現在スレッド
    // ----------------------------------------------
    setCurrentThread: (state, action: PayloadAction<ThreadWithUsage>) => {
      state.currentThread = action.payload;
    },
    clearCurrentThread: (state) => {
      state.currentThread = null;
    },
    addMessagesToCurrentThread: (state, action: PayloadAction<SseEventMessageNodesAdded>) => {
      const result = action.payload;
      const thread = state.currentThread;
      if (!thread) {
        return;
      }

      // メッセージ追加
      thread.messageNodes.push(
        result.userMessageNode,
        result.aiMessageNode,
      );

      // 置換情報追加
      thread.dlpReplaces.push(...result.dlpReplaces);

      // CurrentNodeを更新
      thread.currentMessageNode.messageNodeId = result.aiMessageNode.id

      // 更新位置時をセット
      thread.updatedAt = result.aiMessageNode.updatedAt
    },
    updateAIMessageStatus: (state, action: PayloadAction<AIMessage>) => {
      if (!state.currentThread) {
        return;
      }
      const updateAiMessages = (thread: Thread | null) => {
        thread?.messageNodes.forEach((messageNode) => {
          const aiMessage = messageNode.aiMessages.find(
            (aiMessage) => aiMessage.id === action.payload.id
          );
          if (aiMessage) {
            Object.assign(aiMessage, action.payload);
          }
        });
      };

      updateAiMessages(state.currentThread);

      const threadIndex = state.threads.findIndex(t => t.id === state.currentThread?.id);
      if (threadIndex !== -1) {
        updateAiMessages(state.threads[threadIndex]);
      }

      // 更新位置時をセット
      state.currentThread.updatedAt = action.payload.updatedAt
    },
    addMessageToAIMessage: (
      state,
      action: PayloadAction<SseEventAddMessageToAIMessage>
    ) => {
      if (!state.currentThread) {
        return;
      }
      const appendMessageToAiMessages = (thread: Thread | null) => {
        thread?.messageNodes.forEach((messageNode) => {
          const aiMessage = messageNode.aiMessages.find(
            (aiMessage) => aiMessage.id === action.payload.id
          );
          if (aiMessage) {
            if (!aiMessage.body) {
              aiMessage.body = '';
            }
            aiMessage.body += action.payload.appendMessage;
          }
        });
      };

      appendMessageToAiMessages(state.currentThread);

      const threadIndex = state.threads.findIndex(t => t.id === state.currentThread?.id);
      if (threadIndex !== -1) {
        appendMessageToAiMessages(state.threads[threadIndex]);
      }
    },
    updateThreadUsage: (state, action: PayloadAction<ThreadUsage>) => {
      if (!state.currentThread) {
        return;
      }
      state.currentThread.usage = action.payload
    },
    // 追加: generatingステートを更新するアクション
    setThreadOnGenerating: (state, action: PayloadAction<boolean>) => {
      state.threadOnGenerating = action.payload;
    },
    // 現在のスレッドノードを更新する
    updateCurrentNode: (state, action: PayloadAction<string>) => {
      if (!state.currentThread) {
        return;
      }
      state.currentThread.currentMessageNode.messageNodeId = action.payload;
    }
  }
});

export const {
    setThreads,
    appendThreads,
    addThreadToTop,
    setCurrentThread,
    addTitleToCurrentThread,
    clearCurrentThread,
    addMessagesToCurrentThread,
    updateAIMessageStatus,
    addMessageToAIMessage,
    updateThreadUsage,
    setThreadOnGenerating,
    updateCurrentNode,
} = threadSlice.actions;

export default threadSlice.reducer;
