import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReports, ResReports } from "./report.api";
import { ReportState, UnitType } from './report.type';

const initialState: ReportState = {
    report: null,
    loading: false,
    error: null,
};

export const fetchReport = createAsyncThunk<
    ResReports,
    { teamId: string, unitType: UnitType; termFrom: Date; termTo: Date },
    { rejectValue: string, state: any }
>(
    'report/fetchReport',
    async (params) => {
        const response = await getReports(params);
        return response.data;
    }
);

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchReport.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchReport.fulfilled, (state, action) => {
            state.report = action.payload;
            state.loading = false;
            state.error = null;
        })
        .addCase(fetchReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message ?? 'Unknown error';
        });
    },
});

export default reportSlice.reducer;
