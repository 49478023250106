import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import logoTitleImg from '../../../../assets/images/logo/logo_title.svg';
import { TeamLogo } from '../../team/team.type';

const HeaderLogo: React.FC<{ logo?: TeamLogo }> = ({ logo }) => {
  let src = logoTitleImg;
  if (logo) {
    src = `data:${logo.mimetype};base64,${logo.blob}`;
  }

  return (
    <Box
      component={Link}
      to="/"
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& img': {
          height: { xs: 40, sm: 52 },
          // paddingTop: { xs: 2, sm: 5 },
        },
      }}
    >
      <img src={src} alt="WorkAIzer" />
    </Box>
  );
};

export default HeaderLogo;
