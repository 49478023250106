import { IChangeEvent } from "@rjsf/core";
import { Form } from "@rjsf/mui";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from '@rjsf/validator-ajv8';
import { useState } from "react";
import styled from '@emotion/styled';

const MainArea = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 50px;
  background-color: #fff;
  margin-right: 5px;
`;

const FormArea = styled.div`
  flex: 1;
  margin-right: 20px;

  .qp-form-section {
    h5 {
      font-size: 1em;
    }
  }
`;

const DataArea = styled.div`
  flex: 1;
`;

export function QPPage() {
  const [formData, setFormData] = useState<any>(null);

  const formScheme: RJSFSchema = {
    "title": "要約",
    "type": "object",
    "required": [
      "target",
      "models",
      "wordCount",
      "level",
      "format"
    ],
    "properties": {
      "target": {
        "type": "string",
        "title": "要約したい文章",
        "maxLength": 30000
      },
      "aiModelCodes": {
        "type": "array",
        "title": "どのモデルに要約させますか？",
        "items": {
          "type": "string",
          "oneOf": [
            { "const": "gemini-1.0-pro", "title": "GeminiPro" },
            { "const": "gpt-3.5-turbo", "title": "GPT3.5" },
            { "const": "claude-3-haiku", "title": "Claude 3 Haiku" }
          ]
        },
        "uniqueItems": true,
        "default": ["gemini-1.0-pro"]
      },
      "section1": {
        "type": "object",
        "title": "どのように要約するかを指定してください。",
        "properties": {},
      },
      "wordCount": {
        "type": "number",
        "title": "どの程度の文字数に要約しますか?",
        "oneOf": [
          {
            "const": 30,
            "title": "30文字程度"
          },
          {
            "const": 50,
            "title": "50文字程度"
          },
          {
            "const": 100,
            "title": "100文字程度"
          },
          {
            "const": 150,
            "title": "150文字程度"
          },
          {
            "const": 200,
            "title": "200文字程度"
          },
          {
            "const": 300,
            "title": "300文字程度"
          },
          {
            "const": 400,
            "title": "400文字程度"
          },
          {
            "const": 500,
            "title": "500文字程度"
          }
        ],
        "default": 100
      },
      "level": {
        "type": "string",
        "title": "この要約を読む人のレベルは?",
        "oneOf": [
          {
            "const": "専門家",
            "title": "専門家: この分野に詳しい専門家"
          },
          {
            "const": "関係者",
            "title": "関係者: この分野にある程度知見がある関係者"
          },
          {
            "const": "一般人",
            "title": "一般人: この分野にほぼ知見がない一般の方"
          },
          {
            "const": "未成年",
            "title": "未成年: 社会経験のない大学生・高校生"
          },
          {
            "const": "子供",
            "title": "子供: 中学生以下の子供"
          }
        ]
      },
      "format": {
        "type": "string",
        "title": "どのような形式で要約しますか？",
        "oneOf": [
          {
            "const": "平文",
            "title": "平文: リストや構造化などしない普通の文章で要約"
          },
          {
            "const": "リスト",
            "title": "リスト: 要点の箇条書きで要約"
          },
          {
            "const": "構造化",
            "title": "構造化: 見出しや段落分けなどして要約"
          },
          {
            "const": "FAQ",
            "title": "FAQ形式: よくある質問形式で情報を整理し、問いと答えの形で要約"
          }
        ],
        "default": "平文"
      },
      "memo": {
        "type": "string",
        "title": "他にご要望はありますか？"
      }
    }
  }

  const uiSchema : UiSchema = {
    "ui:description": "指定した文章を要約します。要約の対象となる文章を入力してください。",
    "target": {
      "ui:autofocus": true,
      "ui:help": "要約の対象となる文章を入力してください。",
      'ui:placeholder': "要約したい文章",
      "ui:widget": "textarea",
    },
    "section1": {
      'ui:classNames': 'qp-form-section',
    },
    "wordCount": {
      "ui:help": "生成AIは正確な文字数を守るのは苦手です。ある程度の目安とお考えください。",
    },
    "format": {
      "ui:help": "30〜100文字程度の少ない文字数に要約する場合は、「平文」がおすすめです。",
    },
    "memo": {
      "ui:help": "何のために要約するかなどの目的や背景情報などを入力すると要約の質が上がる可能性があります。",
      "ui:widget": "textarea",
    }
  }

  const handleChange = (data: IChangeEvent) => {
    setFormData(data.formData);
  }

  return (
    <div style={{"paddingTop": 100}}>
      <MainArea>
        <FormArea>
          <Form
            schema={formScheme}
            uiSchema={uiSchema}
            validator={validator}
            onChange={handleChange}
          />
        </FormArea>
        <DataArea>
          {formData && (
            <pre>{JSON.stringify(formData, null, 2)}</pre>
          )}
        </DataArea>
      </MainArea>
    </div>
  );
}
