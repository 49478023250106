import { useErrorModal } from "@/components/features/generic/hooks/useErrorModal";

const PartsBox = () => {
  const { setErrorModal } = useErrorModal();

  const showErrorModal = () => {
    setErrorModal(
      'エラータイトル',
      'エラー内容\nエラー内容\nエラー内容'
    );
  };

  return <div style={{"paddingTop": 100}}>
    <button onClick={showErrorModal}>エラーモーダルテスト</button>
  </div>
};

export default PartsBox;
