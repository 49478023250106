import React, { useState } from 'react';
import { DlpInfoType, DlpInfoTypeConfig, BulkSaveDlpInfoTypeConfig, SaveDlpInfoTypeConfigWithOptionalId } from '../dlp.type';
import {
  Button,
  Divider,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Paper,
  TableBody,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DlpPreventType, getDlpCheckSpeedTypeLabel } from '../dlp.constant';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import styled from '@emotion/styled';
import theme from '@/theme';
import { useConfirmModal } from '../../generic/hooks/useConfirmModal';

interface InfoTypeConfigListPresenterProps {
  infoTypes: DlpInfoType[];
  infoTypeConfigs: DlpInfoTypeConfig[];
  onSave: (data: BulkSaveDlpInfoTypeConfig) => void;
}

const ActionFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  width: 100%;
  border-top: solid 1px ${theme.palette.grey[400]};
  background-color: ${theme.palette.background.paper};
  padding: 15px 18px 0 0;
  justify-content: flex-end;
  display: flex;
`;


const PREVENT_TYPE_NONE = -1;
type PreventTypeValue = DlpPreventType | typeof PREVENT_TYPE_NONE;

const InfoTypeConfigListPresenter: React.FC<InfoTypeConfigListPresenterProps> = ({
  infoTypes,
  infoTypeConfigs,
  onSave,
}) => {
  const { setConfirmModal } = useConfirmModal();
  const [selectedConfigs, setSelectedConfigs] = useState<{ [key: string]: PreventTypeValue }>({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getInitialPreventType = (infoTypeId: string) => {
    const config = infoTypeConfigs.find((config) => config.dlpInfoTypeId === infoTypeId);
    return config ? config.preventType : PREVENT_TYPE_NONE;
  };

  const handlePreventTypeChange = (infoTypeId: string, preventType: PreventTypeValue) => {
    setSelectedConfigs((prevState) => ({
      ...prevState,
      [infoTypeId]: preventType,
    }));
  };

  const handleSaveClick = () => {
    const saveConfigs: SaveDlpInfoTypeConfigWithOptionalId[] = [];
    const deleteIds: string[] = [];

    for (const [infoTypeId, preventType] of Object.entries(selectedConfigs)) {
      const existingConfig = infoTypeConfigs.find((config) => config.dlpInfoTypeId === infoTypeId);
      if (preventType !== PREVENT_TYPE_NONE) {
        saveConfigs.push({
          preventType,
          dlpInfoTypeId: infoTypeId,
          ...(existingConfig && { id: existingConfig.id }),
        });
      } else {
        if (existingConfig) {
          deleteIds.push(existingConfig.id);
        }
      }
    }
    setConfirmModal(
      '保存確認',
      'DLP設定を保存します。よろしいですか？',
      () => onSave({ saveConfigs, deleteIds }),
    );
  };

  return (
    <>
      <AdminPageTitle title="DLP（Data Loss Prevention）設定" />
      <Divider />
      <AdminContentsWrapper style={{paddingBottom: 0}}>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
            DLP設定を有効にすることで、指定したタイプの情報をブロックしたり、差し替えたりできます。<br/>
            タイプによっては処理に時間がかかることがありますので、ご留意ください。
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph style={{ marginBottom: '10px' }}>
          ※ 本機能ではGCP（Google Cloud Platform）の<a href="https://cloud.google.com/security/products/dlp?hl=ja" target="_blank">DLPサービス</a>を利用しております。<br/>
          &nbsp;&nbsp;そのため、文章の内容や文脈によっては、意図した情報が検出されない可能性がございます。<br/>
          &nbsp;&nbsp;この点につきまして、あらかじめご了承くださいますようお願い申し上げます。
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph style={{ marginBottom: '25px' }}>
          ※ 保存ボタンはフッターにございます。
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>情報タイプ</TableCell>
                {!isSmallScreen && <TableCell style={{ width: '100%' }}>説明</TableCell>}
                <TableCell sx={{ whiteSpace: 'nowrap' }}>検出速度</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>設定</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoTypes.map((infoType) => (
                <TableRow key={infoType.id}>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {infoType.name}
                  </TableCell>
                  {!isSmallScreen && (
                    <TableCell>
                      <Typography variant="caption" style={{ whiteSpace: 'pre-line' }}>
                        {infoType.description}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    {getDlpCheckSpeedTypeLabel(infoType.checkSpeedType)}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={selectedConfigs[infoType.id] ?? getInitialPreventType(infoType.id)}
                      onChange={(e) => handlePreventTypeChange(infoType.id, e.target.value as PreventTypeValue)}
                    >
                      <MenuItem value={PREVENT_TYPE_NONE}>なし</MenuItem>
                      <MenuItem value={DlpPreventType.PROHIBIT}>
                        <Tooltip title={infoType.name + "が検出された場合、送信できなくなります。"} placement="left">
                          <span>禁止</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value={DlpPreventType.WARN}>
                        <Tooltip title={infoType.name + "が検出された場合、警告を表示します。"} placement="left">
                          <span>警告</span>
                        </Tooltip>
                      </MenuItem>
                      <MenuItem value={DlpPreventType.REPLACE}>
                        <Tooltip title={infoType.name + "が検出された場合、「" + infoType.replaceWord + "」というワードに置換して生成AIに送信します。"} placement="left">
                          <span>置換</span>
                        </Tooltip>
                      </MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ActionFooter>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSaveClick}
          >
            設定を保存する
          </Button>
        </ActionFooter>
      </AdminContentsWrapper>
    </>
  );
};

export default InfoTypeConfigListPresenter;
