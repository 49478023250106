import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpressionConfigListPresenter from '../presenters/ExpressionConfigListPresenter';
import { RootState } from '@/store';
import { DlpExpressionConfig } from '../dlp.type';
import apiAxios from '@/lib/axios';
import { appendExpressionConfigs, setExpressionConfigs } from '../dlp.slice';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';

const ExpressionConfigListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const expressionConfigs = useSelector((state: RootState) => state.dlp.expressionConfigs);
  const [hasMore, setHasMore] = useState(true);
  const { setUnknownErrorModal } = useErrorModal();

  useEffect(() => {
    const fetchExpressionConfigs = async () => {
      if (expressionConfigs.length < 20 && hasMore) {
        try {
          const response = await apiAxios.get<DlpExpressionConfig[]>('/dlp/expression-configs', {
            params: {
              offset: expressionConfigs.length,
              limit: 20,
            },
          });
          if (response.data.length > 0) {
            dispatch(setExpressionConfigs(response.data));
          }
          if (response.data.length < 20) {
            setHasMore(false);
          }
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to fetch expression configs:', error);
        }
      }
    };

    fetchExpressionConfigs();
  }, [expressionConfigs, dispatch, hasMore]);

  const handleScroll = async () => {
    try {
      const response = await apiAxios.get<DlpExpressionConfig[]>('/dlp/expression-configs', {
        params: {
          offset: expressionConfigs.length,
          limit: 20,
        },
      });
      dispatch(appendExpressionConfigs(response.data));
      if (response.data.length < 20) {
        setHasMore(false);
      }
    } catch (error) {
      captureException(error);
      setUnknownErrorModal();
      console.error('Failed to fetch more expression configs:', error);
    }
  };

  return (
    <ExpressionConfigListPresenter
      expressionConfigs={expressionConfigs}
      onScroll={handleScroll}
      hasMore={hasMore}
    />
  );
};

export default ExpressionConfigListContainer;
