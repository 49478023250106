export enum MessageType {
  ROOT = 'root',
  USER = 'user',
  AI = 'ai'
}

export enum AIMessageStatus {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  FINISHED = 'finished',
  FAILED = 'failed'
}

export enum UserMessageType {
    NORMAL = 'normal',
    QUICK_PROMPT = 'quick_prompt',
}

