import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, getCurrentSubscription, isAdmin } from '../../auth/auth.type';
import { PlanType } from '../../subscription/subscription.constant';
import ThreadRightSidebarStarterPresenter from '../presenters/ThreadRightSidebarStarterPresenter';
import ThreadRightSidebarPaidPlanPresenter from '../presenters/ThreadRightSidebarPaidPlanPresenter';

const ThreadRightSidebarContainer: React.FC = () => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const membership = getCurrentMembership(loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  if (!(loginUser && membership && currentSub)) {
    return <></>
  }
  const team = membership.team;
  const isUserAdmin = isAdmin(loginUser);
  const plan = currentSub.plan;

  if (plan.type === PlanType.STARTER) {
    return <ThreadRightSidebarStarterPresenter
      team={team}
      isUserAdmin={isUserAdmin}
    />
  }

  return <ThreadRightSidebarPaidPlanPresenter
    thread={currentThread}
    team={team}
    isUserAdmin={isUserAdmin}
  />;
};

export default ThreadRightSidebarContainer;
