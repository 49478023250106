import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  Box,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
  Typography,
  Grid,
  Paper,
  FormHelperText,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// V3をつけている理由 (ref) https://github.com/date-fns/date-fns/issues/3638#issuecomment-2164467791
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ja } from 'date-fns/locale/ja';
import { NewsInput, newsInputSchema } from '../news.type';
import { format } from 'date-fns';
import CustomMarkdown from '@/components/ui/CustomMarkdown';

interface NewsFormProps {
  onSubmit: (data: NewsInput) => void;
  defaultValues?: NewsInput;
}

const SvNewsForm: React.FC<NewsFormProps> = ({ onSubmit, defaultValues }) => {
  const { register, control, handleSubmit, watch, formState: { errors } } = useForm<NewsInput>({
    resolver: zodResolver(newsInputSchema),
    defaultValues: defaultValues || {},
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>

      {/* デバッグ用 */}
      {/* {Object.keys(errors).length > 0 && (
        <Box mb={3} p={2} bgcolor="error.light" color="error.contrastText" borderRadius={1}>
          <Typography variant="h6" gutterBottom>エラー:</Typography>
          <ul>
            {Object.entries(errors).map(([key, error]) => (
              <li key={key}>
                <Typography>{`${key}: ${error.message}`}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )} */}
      {/* {
      <Box mb={3}>
        <Typography variant="h6">現在の値:</Typography>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          {JSON.stringify(watch(), null, 2)}
        </pre>
      </Box>
      } */}


      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>
            <Box mb={3} mt={2}>
                <Typography variant='h5'>コンテンツ</Typography>
            </Box>

            <Box mb={4}>
              <TextField
                fullWidth
                label="タイトル"
                {...register('title')}
                error={!!errors.title}
                helperText={errors.title?.message}
              />
            </Box>

            <Box mb={4}>
              <FormControl error={!!errors.type}>
                <FormLabel id="type-radio-buttons-group">タイプ</FormLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-labelledby="type-radio-buttons-group"
                      value={field.value || ''}
                    >
                      <FormControlLabel value="internal" control={<Radio />} label="サイト内でコンテンツを表示" />
                      <FormControlLabel value="external" control={<Radio />} label="外部のURLに遷移" />
                    </RadioGroup>
                  )}
                />
                {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
              </FormControl>
            </Box>

            {watch('type') === 'internal' && (
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="本文 (マークダウン)"
                  multiline
                  rows={10}
                  {...register('body')}
                  error={!!errors.body}
                  helperText={errors.body?.message}
                />
              </Box>
            )}

            {watch('type') === 'external' && (
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="外部URL"
                  {...register('externalUrl')}
                  error={!!errors.externalUrl}
                  helperText={errors.externalUrl?.message}
                />
              </Box>
            )}

            <Divider sx={{marginTop:5, marginBottom:5}}/>

            <Box mb={4}>
                <Typography variant='h5'>公開設定</Typography>
            </Box>

            <Box mb={2}>
              <Controller
                name="publishedAt"
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    label="公開日時"
                    value={field.value ? new Date(field.value) : null}
                    onChange={(newValue) => {
                      field.onChange(newValue ? format(newValue, "yyyy-MM-dd'T'HH:mm:ssXXX") : null);
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: (!!errors.publishedAt),
                        helperText: errors.publishedAt?.message,
                      },
                    }}
                  />
                )}
              />
              <Typography variant="caption" display="block" gutterBottom>
                公開日時になったらユーザーの画面に表示されます
              </Typography>
            </Box>

            <Box mb={2}>
              <Controller
                name="isImportant"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="重要なお知らせ"
                  />
                )}
              />
              <Typography variant="caption" display="block" gutterBottom>
                一覧の中で強調表示されます
              </Typography>
            </Box>

            <Box mb={2}>
              <Controller
                name="isHidden"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="非表示"
                  />
                )}
              />
              <Typography variant="caption" display="block" gutterBottom>
                公開日時に関係なく非表示になります。(一時的に非表示にしたい場合などに)
              </Typography>
            </Box>

            <Divider sx={{marginTop:5, marginBottom:5}}/>

            <Button type="submit" variant="contained" color="primary">
              保存
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          {watch('type') === 'internal' && (
            <Paper elevation={3} style={{ padding: '16px', height: '100%', maxWidth: 800 }}>
              <Typography variant='h5' gutterBottom>マークダウン プレビュー</Typography>
              <Divider sx={{marginBottom:2}}/>
              <CustomMarkdown markdown={watch('body', "")}></CustomMarkdown>
            </Paper>
          )}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default SvNewsForm;
