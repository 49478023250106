import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)`
  margin-bottom: 1rem;
`;

type AdminPageTitleProps = {
  title: string;
};

const AdminPageTitle: React.FC<AdminPageTitleProps> = ({ title }) => {
  return (
    <StyledTypography variant="h5" align="left">
      {title}
    </StyledTypography>
  );
};

export default AdminPageTitle;
