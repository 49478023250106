import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import SvLatestUsageStatisticsPresenter from '../presenters/SvLatestUsageStatisticsPresenter';
const SvLatestUsageStatisticsContainer: React.FC = () => {
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  if (!team) {
    return null;
  }

  return <SvLatestUsageStatisticsPresenter
    team={team}
  />
};

export default SvLatestUsageStatisticsContainer;