import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)`
  margin-bottom: 1rem;
`;

type SupervisorPageTitleProps = {
  title: string;
};

const SupervisorPageTitle: React.FC<SupervisorPageTitleProps> = ({ title }) => {
  return (
    <StyledTypography variant="h5" align="left">
      {title}
    </StyledTypography>
  );
};

export default SupervisorPageTitle;
